import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { en, hr, gr, it, tr } from './translations';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      // loadPath: '', // TODO: add backend json
    },
    fallbackLng: ['en', 'hr', 'el', 'it', 'tr'],
    debug: process.env.NODE_ENV === 'development',
    detection: {
      order: ['navigator', 'cookie'],
      caches: ['cookie'],
    },
    nonExplicitSupportedLngs: true,
    // language keys compliant with the BCP 47 standard
    resources: {
      en: {
        translation: en,
      },
      hr: {
        translation: hr,
      },
      el: {
        translation: gr,
      },
      it: {
        translation: it,
      },
      tr: {
        translation: tr,
      },
    },
  });

export default i18n;
