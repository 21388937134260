import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  Modal,
  Text,
  Button,
  Table,
  Checkbox,
} from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { useMutation } from '@tanstack/react-query';
import {
  IChannel,
  setupScan,
  IScanSetup,
} from '../../../services/api/devices';
import { CHANNEL_TYPE } from '../../../constants/channels-data.constants';
import { useDeviceRefetch } from '../../../hooks/useDeviceRefetch';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },

  center: {
    textAlign: 'center',
  },

  checkbox: {
    justifyContent: 'center',
  },

  table: {
    'thead tr th': {
      fontSize: '13px',
      textAlign: 'center',
    },
  },
}));

interface IScanState {
  name: string;
  physicalName: string;
  scanModBus: boolean;
}
interface IScanModbusModalProps {
  opened: boolean;
  onClose: () => void;
  channels: IChannel[];
}

export const ScanModbusModal: React.FC<IScanModbusModalProps> = ({
  opened,
  onClose,
  channels,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const deviceRefetch = useDeviceRefetch();
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const initScanState: IScanState[] = channels
    ?.reduce((acc: IScanState[], val: IChannel) => {
      if (val.type === CHANNEL_TYPE.ELECTRICITY) {
        return [
          ...acc,
          {
            name: val.name,
            physicalName: val.physicalName,
            scanModBus: false,
          },
        ];
      }
      return acc;
    }, [])
    .sort((a, b) => Number(a.name) - Number(b.name));

  const [scanState, setScanState] = useState<IScanState[]>(initScanState);

  const handleScanStateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setScanState(prev =>
        prev.reduce((acc: IScanState[], val: IScanState) => {
          if (val.name === event.target.id) {
            return [
              ...acc,
              {
                ...val,
                scanModBus: !val.scanModBus,
              },
            ];
          }
          return [...acc, val];
        }, []),
      );
    },
    [],
  );

  const rows = useMemo(() => {
    if (!opened) return null;

    return scanState.map((item, index) => {
      return (
        <tr key={item.name}>
          <td className={classes.center}>
            {item.physicalName} ({item.name})
          </td>
          <td>
            <Checkbox
              color="accentBlue"
              checked={item.scanModBus}
              id={scanState[index].name}
              onChange={handleScanStateChange}
              className={classes.checkbox}
            />
          </td>
        </tr>
      );
    });
  }, [
    classes.center,
    opened,
    scanState,
    handleScanStateChange,
    classes.checkbox,
  ]);

  const mutation = useMutation(
    (item: IScanSetup) => {
      return setupScan(devEui, item);
    },
    {
      onSuccess: () => {
        deviceRefetch();
        onClose();
        setScanState(initScanState);
        showNotification({
          title: t('notifications.scanRequested'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      },
    },
  );

  const submitScan = useCallback(() => {
    const scanValues = scanState.map(item => {
      return { channel: item.name, scanModBus: item.scanModBus };
    });
    const item = {
      marinCode,
      channels: scanValues,
    };
    mutation.mutate(item);
  }, [marinCode, scanState, mutation]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('labels.scanModBus')}
      withCloseButton
      classNames={{ close: classes.close }}>
      <Table my="md" className={classes.table}>
        <thead>
          <tr>
            <th>{t('labels.channelNumber')}</th>
            <th>{t('labels.scanRequest')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Button
        variant="light"
        size="sm"
        radius="xl"
        uppercase
        onClick={submitScan}
        color="accentCabaret">
        <Text color="white">{t('buttons.save')}</Text>
      </Button>
    </Modal>
  );
};
