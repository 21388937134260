import { useState } from 'react';
import { useDidUpdate } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { getRegistry } from '../services/api/devices';
import { DeviceTypes, IDeviceTypeData } from '../types/device';

type CheckingState = 'initial' | 'pending' | 'checking' | 'checked';

export const useDeviceType = eui => {
  const { t } = useTranslation();
  const [deviceType, setDeviceType] = useState<IDeviceTypeData | null>(
    null,
  );
  const [isCheckingForType, setIsCheckingForType] =
    useState<CheckingState>('initial');

  const checkEuiType = useMutation(
    (id: string) => {
      return getRegistry(id);
    },
    {
      onSuccess: data => {
        const { formalName: euiType, fw } = data.data.deviceRegistry;
        const isSupportedType =
          euiType === DeviceTypes.MM_E ||
          euiType === DeviceTypes.MM_E_8 ||
          euiType === DeviceTypes.MM_E_PM;
        if (!isSupportedType) {
          setDeviceType(null);
          setIsCheckingForType('checked');
          return showNotification({
            title: t('notifications.typeNotSupported'),
            message: t('notifications.error'),
            color: 'accentRed',
            icon: <IconAlertCircle size={18} />,
          });
        }
        setIsCheckingForType('checked');
        return setDeviceType({ euiType, fw });
      },
      onError: () => {
        setDeviceType(null);
        setIsCheckingForType('checked');
        return showNotification({
          title: t('notifications.deviceNotFound'),
          message: t('notifications.error'),
          color: 'accentRed',
          icon: <IconAlertCircle size={18} />,
        });
      },
    },
  );

  useDidUpdate(() => {
    if (isCheckingForType === 'pending') {
      setIsCheckingForType('checking');
      checkEuiType.mutate(eui);
    }
  }, [eui, isCheckingForType, checkEuiType]);

  return {
    deviceType,
    isCheckingForType,
    setIsCheckingForType,
  };
};
