import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  Container,
  Button,
  Stack,
  Group,
  Text,
} from '@mantine/core';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IconEyeOff, IconAlertTriangle, IconUrgent } from '@tabler/icons';
import { SearchBox } from '../../components/Search';
import { getDevice } from '../../services/api/devices';
import { UserHeader } from '../../components/user-header';
import { BottomNavigation } from '../../components/bottom-navigation';
import { BottomNavIcon } from '../../components/atoms/bottom-nav-icon';
import { checkDeviceSync } from '../../utils';
import { SearchEui } from './components/search-eui';
import { DevicesLegend } from './components/devices-legend';
import { useMarinaName } from '../../hooks/useMarinaName';

const useStyles = createStyles(theme => ({
  icon: {
    color: '#ff3333',
    flexShrink: 0,
  },

  mLeft: {
    marginLeft: '10px',
  },

  deviceButton: {
    borderBottom: `1px solid ${theme.colors.primary[0]}`,
  },

  searchBox: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  grow: {
    flexGrow: 1,
  },
}));

export const Devices: React.FC = () => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');

  const { refetch, data, isLoading, isFetching } = useQuery(
    ['getDevices', window.location.search],
    () => getDevice(window.location.search),
    {
      refetchOnWindowFocus: false,
      select: res => res.data.devices,
      onError: () => {
        navigate('/not-found');
      },
    },
  );
  const { data: marinaName } = useMarinaName(marinCode);
  const loading = isLoading || isFetching;

  const [searchedWord, setSearchedWord] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const navigateToAddPage = useCallback(() => {
    navigate(`/device/add?marinCode=${marinCode}`);
  }, [marinCode, navigate]);

  const handleSearchedWord = useCallback((value: string) => {
    setSearchedWord(value);
  }, []);

  useEffect(() => {
    if (searchedWord === '' && data) {
      setFilteredList(data);
    }
    if (searchedWord !== '' && data) {
      setFilteredList(
        data.filter(el => {
          return el.name
            .toLowerCase()
            .includes(searchedWord.toLowerCase());
        }),
      );
    }
  }, [searchedWord, data]);

  return (
    <>
      <Container>
        <UserHeader
          backTarget="/marinas"
          isLoading={loading}
          title={marinaName}>
          <Group mt={20} px={20} className={classes.searchBox} noWrap>
            <SearchBox
              className={classes.grow}
              onSearchChange={handleSearchedWord}
            />
            <SearchEui />
          </Group>
        </UserHeader>
        {filteredList && (
          <Stack p="md" justify="flex-start">
            {filteredList.map(device => {
              return (
                <Button
                  size="lg"
                  radius="xl"
                  variant="subtle"
                  key={`${device.devEui}`}
                  component={Link}
                  to={`/device${window.location.search}&devEui=${device.devEui}`}
                  fullWidth
                  className={classes.deviceButton}>
                  <Text size={18} weight={500} color="primary">
                    {device.name}
                  </Text>
                  {!device.lastSyncAt && (
                    <IconEyeOff
                      className={cx(classes.icon, classes.mLeft)}
                    />
                  )}
                  {device.lastSyncAt &&
                    !checkDeviceSync(device.lastSyncAt) && (
                      <IconAlertTriangle
                        className={cx(classes.icon, classes.mLeft)}
                      />
                    )}
                  {device.hasErrors && (
                    <IconUrgent
                      className={cx(classes.icon, classes.mLeft)}
                    />
                  )}
                </Button>
              );
            })}
          </Stack>
        )}
      </Container>
      <BottomNavigation>
        <BottomNavIcon
          onClick={handleRefetch}
          iconName="IconRefresh"
          label={t('buttons.refresh')}
        />
        <BottomNavIcon
          onClick={navigateToAddPage}
          iconName="IconPlus"
          label={t('buttons.add')}
        />
        <DevicesLegend />
      </BottomNavigation>
    </>
  );
};
