import React, { useCallback, useState } from 'react';
import {
  createStyles,
  useMantineTheme,
  Text,
  Group,
  Modal,
  Button,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDidUpdate } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconAlertCircle, IconCheck } from '@tabler/icons';
import { ScanInput } from './scan-input';
import { QRScanner } from './qr-scanner';
import { updateEui } from '../../../../services/api/devices';
import { useDeviceType } from '../../../../hooks/useDeviceType';
import { isCorrectEui } from '../../../../utils';
import EuiScanPreview from '../../../../assets/eui-image.png';
import { EUI_MAX_LENGTH } from '../../../../constants/devices-data.constants';
import { useDeviceRefetch } from '../../../../hooks/useDeviceRefetch';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },
}));

type TScanEui =
  | {
      devEui: string;
      error: string;
      onCodeChange: (eui: string) => void;
      isEditModeActive?: never;
      isTestModeActive?: never;
      currentDeviceType?: never;
    }
  | {
      devEui: string;
      onCodeChange?: never;
      error?: never;
      isEditModeActive: boolean;
      isTestModeActive: boolean;
      currentDeviceType: string;
    };

export const ScanEui: React.FC<TScanEui> = ({
  devEui,
  error,
  onCodeChange,
  isEditModeActive,
  isTestModeActive,
  currentDeviceType,
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [editScanEui, setEditScanEui] = useState(devEui);
  const [opened, setOpened] = useState(false);
  const [openedEditScanner, setOpenedEditScanner] = useState(false);
  const marinCode = searchParams.get('marinCode');
  const { deviceType, isCheckingForType, setIsCheckingForType } =
    useDeviceType(editScanEui);
  const [isTypeChanged, setIsTypeChanged] = useState<boolean>(false);

  const deviceRefetch = useDeviceRefetch();

  const validate = useCallback((decodedText: string) => {
    const eui = decodedText.substring(0, EUI_MAX_LENGTH);
    return isCorrectEui(eui);
  }, []);

  const handleUpdateEui = useMutation(
    (item: object) => {
      return updateEui(devEui, item);
    },
    {
      onSuccess: () => {
        deviceRefetch();
        showNotification({
          title: t('notifications.updatedEui'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
        navigate(`/device?marinCode=${marinCode}&devEui=${editScanEui}`, {
          state: 'newDevice',
        });
        setOpened(false);
      },
    },
  );

  const handleEuiEdit = () => {
    const item = {
      marinCode,
      newDevEui: editScanEui,
    };
    handleUpdateEui.mutate(item);
  };

  const handleSetOpened = useCallback(() => {
    if (isEditModeActive) setEditScanEui(devEui);
    setOpened(prev => !prev);
    setOpenedEditScanner(false);
  }, [devEui, isEditModeActive]);

  const handleOpenEditScanner = useCallback(() => {
    setOpenedEditScanner(prev => !prev);
  }, []);

  const handleSetDeviceEui = useCallback(
    (eui: string) => {
      onCodeChange(eui);
    },
    [onCodeChange],
  );

  const handleSetEditScanEui = useCallback(
    (eui: string) => {
      setEditScanEui(eui.toLowerCase());
      if (devEui !== eui && isCorrectEui(eui)) {
        setIsCheckingForType('pending');
      }
    },
    [setIsCheckingForType, devEui],
  );

  useDidUpdate(() => {
    if (isEditModeActive && isCheckingForType === 'checked') {
      if (
        devEui !== editScanEui &&
        currentDeviceType !== deviceType?.euiType
      ) {
        setIsTypeChanged(true);
        showNotification({
          title: t('notifications.preventTypeChange'),
          message: t('notifications.error'),
          color: 'accentRed',
          icon: <IconAlertCircle size={18} />,
        });
      } else {
        setIsTypeChanged(false);
      }
      setIsCheckingForType('initial');
    }
  }, [
    deviceType,
    isEditModeActive,
    setIsTypeChanged,
    currentDeviceType,
    devEui,
    editScanEui,
    isCheckingForType,
  ]);

  if (isEditModeActive) {
    return (
      <>
        <ScanInput
          onClick={handleSetOpened}
          value={devEui}
          label={t('labels.device')}
          replaceButtonLabel={t('buttons.replace_device')}
          isEditModeActive={isEditModeActive}
          isTestModeActive={isTestModeActive}
          maxLength={EUI_MAX_LENGTH}
          type="alphanumeric"
        />
        <Modal
          zIndex={1111}
          opened={opened}
          onClose={handleSetOpened}
          title={t('labels.scanCode')}
          classNames={{ close: classes.close }}>
          {openedEditScanner ? (
            <QRScanner
              handleScannedCode={handleSetEditScanEui}
              setOpen={setOpenedEditScanner}
              validate={validate}
              previewSrc={EuiScanPreview}
              errorMessage={t('validations.wrongEui')}
            />
          ) : (
            <>
              <ScanInput
                onClick={handleOpenEditScanner}
                label={t('labels.device')}
                replaceButtonLabel={t('buttons.replace_device')}
                value={editScanEui}
                error={!isCorrectEui(editScanEui)}
                onChange={handleSetEditScanEui}
                maxLength={EUI_MAX_LENGTH}
                type="alphanumeric"
              />
              {!isCorrectEui(editScanEui) && (
                <Text color={theme.colors.accentRed[0]} size="xs">
                  {t('validations.wrongEui')}
                </Text>
              )}
              <Group position="apart" mt="xl" noWrap>
                <Button
                  variant="outline"
                  size="sm"
                  radius="xl"
                  uppercase
                  onClick={handleSetOpened}
                  color="accentBlue">
                  <Text size={14} weight={500}>
                    {t('buttons.cancel')}
                  </Text>
                </Button>
                <Button
                  variant="light"
                  size="sm"
                  radius="xl"
                  uppercase
                  onClick={handleEuiEdit}
                  disabled={isTypeChanged || !isCorrectEui(editScanEui)}
                  color="accentCabaret">
                  <Text color="white" size={14} weight={500}>
                    {t('buttons.save')}
                  </Text>
                </Button>
              </Group>
            </>
          )}
        </Modal>
      </>
    );
  }

  return (
    <>
      <ScanInput
        onClick={handleSetOpened}
        label={t('labels.device')}
        replaceButtonLabel={t('buttons.replace_device')}
        value={devEui}
        error={!!error}
        onChange={handleSetDeviceEui}
        maxLength={EUI_MAX_LENGTH}
        type="alphanumeric"
      />
      {error && (
        <Text color={theme.colors.accentRed[0]} size="xs">
          {error}
        </Text>
      )}
      <Modal
        zIndex={1111}
        opened={opened}
        onClose={handleSetOpened}
        title={t('labels.scanCode')}
        classNames={{ close: classes.close }}>
        <QRScanner
          handleScannedCode={onCodeChange}
          setOpen={setOpened}
          validate={validate}
          previewSrc={EuiScanPreview}
          errorMessage={t('validations.wrongEui')}
        />
      </Modal>
    </>
  );
};
