import isHexadecimal from 'validator/lib/isHexadecimal';
import isLength from 'validator/lib/isLength';
import { EUI_MAX_LENGTH } from '../constants/devices-data.constants';

export const isCorrectEui = (eui: string) => {
  return (
    isHexadecimal(eui) &&
    isLength(eui, {
      min: EUI_MAX_LENGTH,
      max: EUI_MAX_LENGTH,
    })
  );
};
