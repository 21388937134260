export const it = {
  code: 'it_it',
  labels: {
    email: 'Email',
    password: 'Password',
    channels: 'Canali',
    device: 'Dispositivo EUI',
    pedestalQr: 'QR della colonnina',
    pedestalName: 'Nome colonnina elettricae',
    scanCode: 'Scansiona il codice',
    addChannel: 'Aggiungi canale',
    editChannel: 'Modifica canale',
    startReplacingCounter: 'Start replacing the counter',
    finishReplacingCounter: 'Finish replacing the counter',
    removeChannel: 'Elimina canale',
    removeDevice: 'Elimina dispositivo',
    channelName: 'Nome canale:',
    pulse: 'Impulso per kWh',
    pulseWater: 'Impulsi per [m3]',
    maximumCurrent: 'Corrente massima [A]',
    voltage: 'Voltaggio [VAC]',
    meterStatus: 'Stato contatore [kWh]',
    meterStatusWater: 'Status del contatore [m3]',
    addDevice: 'Aggiungi dispositivo',
    editDevice: 'Modifica dispositivo',
    remove: 'Elimina',
    lastSeen: 'Ultimo accesso:',
    never: 'Mai',
    appVersion: `Versione dell'App`,
    attention: 'Attenzione!',
    testMode: 'Modalità di prova',
    socketNumber: 'Numero di presa',
    tapNumber: 'Numero del rubinetto ',
    channelNumber: 'Nr (canale)',
    output: 'Uscite',
    unplug: 'Scollegare',
    editOutputs: 'Modificare uscite',
    findDevice: 'Codice di scansione per trovare dispositivo',
    modbusAddress: 'Indirizzo MODBUS',
    linkedChannel: 'Canale elettrico collegato',
    scanModBus: 'Scansiona MODBUS',
    updatingOutputs: 'Aggiornamento dei risultati',
    updatingPMTags: 'Aggiornamento dei tag PM',
    scanRequest: 'Richiesta di scansione',
    deviceErrors: 'Errori di dispositivo',
    createdAt: 'Creato a',
    code: 'Codice',
    description: 'Descrizione',
    resolvedAt: 'Risolto a',
    resolvedBy: 'Risolto da',
    autoResolved: 'Risolto automaticamente',
    fixError: `Come risolvere l'errore?`,
    solution: 'Soluzione',
    pmTags: 'Tag PM',
    linkedTap: 'Rubinetto collegato',
    linkedSocket: 'Presa collegata',
    addingChannel: 'Aggiunta nuovo canale',
    updatingCounter: 'Contatore di aggiornamento',
    profile: 'Profilo',
    marinas: 'Marina',
    changePassword: 'Cambia la tua password',
    oldPassword: 'Vecchia password',
    newPassword: 'Nuova password',
    confirmNewPassword: 'Conferma la nuova password',
    socket: 'Socket',
    tap: 'Tap',
    switchingReason: 'Reason for switching on the tap/socket',
    customerReason: 'Connecting the customer',
    repairReason: 'Repair / test',
    technicalReason: 'Technical / service boat',
    boatName: 'Boat name',
    berth: 'Berth',
    transient: 'Transient',
    yes: 'Yes',
    no: 'No',
    comment: 'Comment',
  },
  descriptions: {
    removeChannel: 'Sei sicuro di voler eliminare il canale?',
    removeDevice: 'Sei sicuro di voler eliminare il dispositivo?',
    changesNotSaved: 'Modifiche non salvate.',
    leaveConfirmation: 'Sei sicuro di voler uscire?',
    enableLocation: `La tua posizione è necessaria per determinare la posizione del dispositivo. Abilita l'autorizzazione nelle impostazioni del browser.`,
    typeChangeModal:
      'Sei sicuro di cambiare il tipo di dispositivo? Tutti i dati saranno rimossi',
    changeSocketNumber: 'Siete sicuri di cambiare il numero della presa?',
    confirmChannelEnable: 'Sei sicuro di voler sbloccare questo canale?',
    confirmChannelDisable: 'Sei sicuro di voler bloccare questo canale?',
    confirmPMTagsEnable: 'Are you sure to enable PM Tags?',
    confirmPMTagsDisable: 'Are you sure to disable PM Tags?',
    updating: 'Questo potrebbe richiedere fino a 3 minuti',
    scanModBus: `Voglio scansionare l'indirizzo MODBUS per questo canale`,
    wrongFirmwareForTestMode:
      'Please update the devices firmware before enabling test mode.',
    skippedChannel:
      'Refresh data or check the physical connection of the socket in the pedestal.',
    startReplaceCounter:
      'Are you sure you want to start replacing the counter?',
  },
  placeholders: {
    email: 'email@example.com',
    password: '********',
    search: 'Ricerca',
    pier: 'Pontile',
    pedestal: 'Colonnina elettrica',
    chooseCamera: 'Scegli la fotocamera',
    oldPassword: 'Vecchia password',
    newPassword: 'Nuova password',
    confirmNewPassword: 'Conferma la nuova password',
    enterComment: 'Enter a comment',
  },
  buttons: {
    login: 'Accesso',
    logout: 'Logout',
    back: 'Indietro',
    refresh: 'Aggiorna',
    save: 'Salva',
    remove: 'Elimina',
    addChannel: 'Aggiungi canale',
    enable: 'Abilita',
    lock: 'Serratura',
    electricity: 'Elettricità',
    water: 'Acqua',
    add: 'Aggiungi ',
    replace_device: 'Sostituire MM-E',
    replace_counter: 'Sostituire contatore',
    replace_pedestal_code: 'Sostituire QR',
    savePosition: 'Salvare la nuova posizione',
    cancel: 'Cancella',
    hide: 'Nascondi',
    confirm: 'Conferma',
    getPosition: 'Ottenere la posizione',
    resolve: 'Risolvere',
    startScan: 'Avvia la scansione',
    stopScan: 'Interrompere la scansione',
    scanImage: `Scansiona l'immagine`,
    supportRequest: 'Invia richiesta di assistenza',
    profile: 'Profilo',
    marinas: 'Marina',
    start: 'Start',
    finish: 'Finish',
  },
  legend: {
    title: 'Legenda',
    neverSeen: 'Dispositivo mai visto',
    notSynced: 'Dispositivo non visto negli ultimi 20 minuti',
    hasErrors:
      'Il dispositivo ha errori irrisolti (intervento necessario)',
  },
  notifications: {
    addedDevice: 'Dispositivo aggiunto  👍',
    updatedGeo: 'Posizione aggiornata  👍',
    updatedEui: 'Dispositivo EUI aggiornato 👍',
    updatedPedestalQr: 'QR colonnina aggiornato 👍',
    replacedCounter: 'Contatore sostituito 👍',
    startedReplaceCounter: 'Started replacing counter 👍',
    replacedCounterNewValue:
      'New meter status: <strong>{{meter_status}} {{unit}}</strong>',
    addedChannel: 'Canale aggiunto 👍',
    removedChannel: 'Canale eliminato 👍',
    changedChannelState: 'Stato del canale cambiato 👍',
    removedDevice: 'Dispositivo eliminato 👍',
    invalidValues: 'Valore non valido ⚡',
    updatedOutputs: 'Uscita dispositivo impostata correttamente',
    error: 'Errore 🤥',
    success: 'Successo',
    errorMessage: 'Qualcosa è andato storto',
    tryAgain: 'Per favore riprova',
    notSeenOnSubmit:
      'Il dispositivo non si vede. Assicurati che i dati siano stati salvati correttamente.',
    invalidDeviceName:
      'Alcuni campi non sono validi. Si prega di completare i campi.',
    invalidPedestalQrLength:
      'Il QR della colonnina deve contenere almeno 6 caratteri',
    enableLocation: `E' necessario abilitare il permesso di geolocalizzazione`,
    testModeEnabled: 'La modalità di prova è abilitata',
    testModeDisabled: 'La modalità di prova è disabilitata',
    pmTagsEnabled: 'I tag PM sono ora abilitati',
    pmTagsDisabled: 'I tag PM sono ora disabilitati',
    deviceNotFound: 'Questo dispositivo non è stato trovato',
    typeNotSupported: 'Questo tipo non è supportato',
    preventTypeChange: `Il tipo di dispositivo non può essere cambiato`,
    socketNumbersUnique:
      'I valori del numero della presa devono essere univoci',
    addDeviceNow: `E' possibile aggiungere ora!`,
    uniqueLinks: `I collegamenti tra i canali dell'elettricità e dell'acqua devono essere unici`,
    scanRequested: `E' stata inviata una richiesta di scansione. Scanning may take up to 10 minutes.`,
    errorResolved: 'Errore risolto 👍',
    qrNotFound: 'Nessun codice QR trovato',
    supportRequest: 'La tua richiesta è stata inviata al supporto tecnico',
    passwordChanged: 'Password cambiata con successo',
  },
  errors: {
    devEui: 'EUI non dovrebbe essere vuoto',
    channels: 'I canali devono contenere almeno 1 elemento',
    newDevEui: 'Il nuovo dispositivo EUI non dovrebbe essere vuoto',
    pulse: `L'impulso non dovrebbe essere indefinito`,
    meterStatus: 'Lo status del contatore non dovrebbe essere indefinito',
    userNotFound: `L'utente non è stato trovato`,
    wrongPassword: 'Password non valida',
    physicalName: 'Il valore del nome fisico non è valido',
    pedestalQr: 'QR colonnina non valido',
    modBus: 'Il valore MODBUS non è valido',
  },
  validations: {
    invalid: 'Volore non valido',
    required: 'Campo obbligatorio.',
    email: 'Email non valida.',
    wrongMarina: `Il QR code scansionato è di un'altra marina.`,
    wrongEui: 'Questo EUI non è corretto',
    wrongPedestalQr: 'Questo codice non è corretto',
    wrongPedestalName:
      'Il nome della colonnina scansionato non è corretto',
    passwordLength: 'La password deve contenere almeno 8 caratteri',
    strongPassword:
      'La password deve contenere almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale',
    samePasswords: 'Le password devono essere le stesse',
  },
  notFound: {
    title: 'Avete trovato un luogo segreto',
    description: `Sfortunatamente, questa è una pagina 404. Potresti aver digitato male l'indirizzo o la pagina è stata spostata su un altro URL.`,
    button: 'Riportami alla pagina iniziale',
  },
  deviceErrors: {
    1: {
      description: 'Il firmware caricato non è valido',
      instruction: 'Sostituire il dispositivo',
    },
    2: {
      description: 'Il firmware scaricato non è valido',
      instruction:
        'Configura FUOTA con la versione hardware corretta per il dispositivo',
    },
    3: {
      description: 'MODBUS communication error for channel no. {{arg0}}',
      arg0: 'per il canale {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    4: {
      description: 'Scansione MODBUS fallita {{arg0}} {{arg1}}',
      arg0: 'per il canale n. {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    5: {
      description: 'Reset eseguito dal watchdog {{arg0}}',
      arg0_0: '(IWDG watchdog)',
      arg0_1: '(WWDG watchdog)',
    },
    6: {
      description: 'User credits overwritten {{arg0}} {{arg1}}',
      arg0: '(number of credits: {{arg}}).',
      arg1: 'No. of the channel for which the credits have been overwritten: {{arg}}',
      instruction:
        'Verify if overwritten credits should be returned to the customer. Report the situation to the office for verification.',
    },
    7: {
      description: 'The device has lost communication',
    },
    8: {
      description: 'Replace or update the Plus {{arg0}}}',
      arg0: 'socket no. {{arg}}',
      instruction:
        'Replace the socket with one with firmware 1.29 or update the socket firmware to 1.29.',
    },
    defaultSolution: `Se hai un problema per risolvere l'errore, contatta il supporto tecnico`,
  },
};
