import {
  createStyles,
  Button,
  Card,
  Group,
  Title,
  Modal,
  Text,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconPlus,
  IconCheck,
  IconAlertCircle,
  IconTrash,
} from '@tabler/icons';
import { createPortal } from 'react-dom';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IChannel,
  addNewChannel,
  removeChannel,
} from '../../../../services/api/devices';
import { SingleChannel } from './single-channel';
import { BottomNavigation } from '../../../../components/bottom-navigation';
import { ChannelsListSwitch } from './channels-list-switch';
import {
  MAXIMUM_CURRENT_VALUES,
  VOLTAGE_VALUES,
  CHANNEL_TYPE,
  CHANNELS_MAX_LENGTH,
} from '../../../../constants/channels-data.constants';
import {
  IDeviceType,
  DeviceTypes,
  IDeviceTypeData,
} from '../../../../types/device';
import { useChannels } from '../../../../hooks/useChannels';
import {
  replaceArrayElement,
  checkForDuplicatedValues,
  getLinkedChannel,
  getPMPulseValue,
} from '../../../../utils';
import { TypeChangeModal } from './type-change-modal';
import { OutputsModal } from '../outputs-modal';
import { useDeviceQuery } from '../../../../hooks/useDeviceQuery';
import { UpdateModal } from '../../../../components/update-modal';
import { OverlayLoader } from '../../../../components/atoms/overlay-loader';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },
  channelsLabel: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
}));
interface IChannelsProps {
  value: IChannel[];
  deviceType: IDeviceTypeData;
  onChange?: (channels: IChannel[]) => void;
  isEditModeActive?: boolean;
  isTestModeActive?: boolean;
  handleDeviceChange?: (restore?: boolean) => void;
  prevDevice?: IDeviceType;
  isDeviceSynced?: boolean;
  isPMTagsEnabled?: boolean;
  allowBigSocketControl?: boolean;
}

export const Channels: React.FC<IChannelsProps> = ({
  value,
  onChange,
  isEditModeActive,
  isTestModeActive,
  deviceType,
  handleDeviceChange,
  prevDevice,
  isDeviceSynced,
  isPMTagsEnabled,
  allowBigSocketControl,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [channelsType, setChannelsType] = useState<string>(
    CHANNEL_TYPE.ELECTRICITY,
  );
  const [channels, setChannels] = useState<IChannel[]>(value || []);
  const [openAddChannelModal, setOpenAddChannelModal] = useState(false);
  const {
    activeChannels,
    isMaxAmountReached,
    nextIndex,
    occupiedSockets,
    electricityIndexes,
  } = useChannels(
    channels,
    channelsType,
    deviceType?.euiType,
    isEditModeActive,
  );
  const channelRef = useRef(null);
  const devEui = searchParams.get('devEui');
  const marinCode = searchParams.get('marinCode');
  const isPMDevice = deviceType?.euiType === DeviceTypes.MM_E_PM;
  const isMaxWaterTapsReached =
    isPMDevice &&
    channelsType === CHANNEL_TYPE.WATER &&
    activeChannels.length >= electricityIndexes.length;
  const isOutputsEditable =
    !channels.every(
      channel =>
        channel.type === CHANNEL_TYPE.ELECTRICITY &&
        channel.currentMax >= 250,
    ) || allowBigSocketControl;

  const singleInitValues = useMemo(() => {
    return {
      type: channelsType,
      name: String(nextIndex),
      physicalName: String(nextIndex),
      isActive: true,
      isSkipped: false,
      pulse:
        isPMDevice &&
        channelsType === CHANNEL_TYPE.ELECTRICITY &&
        deviceType?.fw
          ? getPMPulseValue(deviceType.fw)
          : 1000,
      meterStatus: 0,
      impulses: 0,
      currentMax:
        channelsType === CHANNEL_TYPE.ELECTRICITY
          ? MAXIMUM_CURRENT_VALUES[0].value
          : 0,
      currentVoltage:
        channelsType === CHANNEL_TYPE.ELECTRICITY
          ? VOLTAGE_VALUES[0].value
          : 0,
      modBus: isPMDevice ? 1 : undefined,
      scanModBus: false,
      replacingMeterInProgress: false,
      disconnectDetected: false,
      disconnectMonitoringActive: false,
      disconnectMonitoringControlActive: true,
      output: false,
      createdAt: '',
      updatedAt: '',
    };
  }, [channelsType, deviceType?.fw, isPMDevice, nextIndex]);

  const [channelInModalData, setChannelInModalData] =
    useState<IChannel>(singleInitValues);

  const {
    query: { data, refetch },
    deviceQueryState,
    startSubmitting,
    clearSubmitting,
  } = useDeviceQuery(res => {
    if (
      deviceQueryState === 'submitting' &&
      res.lastSyncAt !== data.lastSyncAt
    ) {
      const resChannel = res.channels.find(
        i => i.name === channelInModalData.name,
      );
      clearSubmitting();
      if (channelInModalData.meterStatus <= resChannel.meterStatus) {
        showNotification({
          title: t('notifications.addedChannel'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      } else {
        showNotification({
          title: t('notifications.error'),
          message: `${t('notifications.errorMessage')}. ${t(
            'notifications.tryAgain',
          )}`,
          color: 'accentRed',
          icon: <IconAlertCircle size={18} />,
        });
      }
      setChannelInModalData(singleInitValues);
    }
  });

  const addChannel = () => {
    setChannels(prev => [
      ...prev,
      {
        ...singleInitValues,
        createdAt: new Date().toISOString(),
      },
    ]);
  };

  const handleChangeInModalData = (channel: IChannel) => {
    setChannelInModalData(channel);
  };

  const handleAddNewChannel = useMutation((item: object) => {
    return addNewChannel(devEui, item);
  });

  const handleRemoveChannel = useMutation(
    (channelName: string) => {
      const linkedChannel = getLinkedChannel(
        isPMDevice && channelsType === CHANNEL_TYPE.ELECTRICITY,
        channels,
        Number(channelName) + CHANNELS_MAX_LENGTH.MM_E_8,
      );
      if (linkedChannel)
        removeChannel(marinCode, devEui, linkedChannel.name);
      return removeChannel(marinCode, devEui, channelName);
    },
    {
      onSuccess: () => {
        refetch();
        showNotification({
          title: t('notifications.removedChannel'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconTrash size={18} />,
        });
      },
      onError: () => {
        showNotification({
          title: t('notifications.error'),
          message: t('notifications.errorMessage'),
          color: 'accentRed',
          icon: <IconAlertCircle size={18} />,
        });
      },
    },
  );

  const addChannelInModal = () => {
    const isWaterChannel = channelInModalData.type === CHANNEL_TYPE.WATER;
    const linkedChannel = getLinkedChannel(
      isPMDevice && isWaterChannel,
      channels,
      channelInModalData.modBus,
    );

    if (isPMDevice && isWaterChannel) {
      const electricityWaterLinksIndexes = channels.reduce((acc, val) => {
        if (val.type === CHANNEL_TYPE.WATER) {
          return [...acc, Number(val.name) - CHANNELS_MAX_LENGTH.MM_E_8];
        }
        return acc;
      }, []);

      const linkIsOccupied = electricityWaterLinksIndexes.find(
        el => el === channelInModalData.modBus,
      );

      if (linkIsOccupied) {
        return showNotification({
          title: t('notifications.uniqueLinks'),
          message: t('notifications.error'),
          color: 'accentRed',
        });
      }
    }

    const item = {
      ...channelInModalData,
      meterStatus:
        Number(channelInModalData.meterStatus.toFixed(2)) *
        Math.round(channelInModalData.pulse),
      pulse: Math.round(channelInModalData.pulse),
      physicalName: String(channelInModalData.physicalName),
      createdAt: new Date().toISOString(),
      marinCode,
      modBus: linkedChannel
        ? linkedChannel.modBus
        : channelInModalData.modBus,
      name: linkedChannel
        ? String(Number(linkedChannel.name) + CHANNELS_MAX_LENGTH.MM_E_8)
        : String(nextIndex),
    };
    const newNameIsOccupied = occupiedSockets.find(
      el => String(el) === String(item.physicalName),
    );

    if (newNameIsOccupied) {
      return showNotification({
        title: t('notifications.socketNumbersUnique'),
        message: t('notifications.error'),
        color: 'accentRed',
      });
    }

    return handleAddNewChannel.mutate(item, {
      onSuccess: () => {
        setOpenAddChannelModal(false);
        if (isPMDevice || !isDeviceSynced) {
          refetch();
          setChannels(prev => [...prev, item]);
          showNotification({
            title: isDeviceSynced
              ? t('notifications.addedChannel')
              : t('notifications.notSeenOnSubmit'),
            message: t('notifications.success'),
            color: isDeviceSynced ? 'teal.8' : 'accentBlue',
            icon: <IconCheck size={18} />,
          });
          setChannelInModalData(singleInitValues);
        } else {
          startSubmitting();
        }
      },
    });
  };

  const showAddChannelModal = () => {
    setOpenAddChannelModal(prev => !prev);
  };

  const deleteChannel = (channelName: string) => {
    if (isEditModeActive) handleRemoveChannel.mutate(channelName);
    setChannels(prev => prev.filter(i => i.name !== channelName));
  };

  const saveData = (channel: IChannel) => {
    setChannels(prev => {
      return replaceArrayElement<IChannel>(prev, channel, 'name');
    });
  };

  const [formErrors, setFormErrors] = useState({});

  const saveAll = () => {
    if (Object.keys(formErrors).length) {
      return showNotification({
        title: t('notifications.invalidValues'),
        message: t('notifications.error'),
        color: 'accentRed',
      });
    }
    if (checkForDuplicatedValues<string>(occupiedSockets)) {
      return showNotification({
        title: t('notifications.socketNumbersUnique'),
        message: t('notifications.error'),
        color: 'accentRed',
      });
    }

    if (isPMDevice) {
      const electricityWaterLinksIndexes = channels.reduce((acc, val) => {
        if (val.type === CHANNEL_TYPE.WATER) {
          return [...acc, val.modBus];
        }
        return acc;
      }, []);
      const electricityChannels = channels.filter(
        channel => channel.type === CHANNEL_TYPE.ELECTRICITY,
      );
      if (
        electricityWaterLinksIndexes.length > electricityChannels.length ||
        checkForDuplicatedValues<string>(electricityWaterLinksIndexes)
      ) {
        return showNotification({
          title: t('notifications.uniqueLinks'),
          message: t('notifications.error'),
          color: 'accentRed',
        });
      }
    }

    return onChange(channels);
  };

  useEffect(() => {
    if (channelRef.current) {
      channelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [channels]);

  useEffect(() => {
    if (isEditModeActive) {
      setChannelInModalData(prev => {
        return {
          ...prev,
          type: channelsType,
          physicalName: String(nextIndex),
        };
      });
    }
  }, [channelsType, isEditModeActive, openAddChannelModal, nextIndex]);

  useEffect(() => {
    setChannels(value);
  }, [value]);

  useEffect(() => {
    setChannelInModalData(singleInitValues);
  }, [singleInitValues]);

  return (
    <div>
      {channels.length > 0 && (
        <Card radius={0}>
          <Group className={classes.channelsLabel} noWrap>
            <Title order={4}>{t('labels.channels')}</Title>
            {isEditModeActive && (
              <OutputsModal
                channels={channels}
                disabled={
                  isTestModeActive ||
                  !isDeviceSynced ||
                  !isOutputsEditable ||
                  isPMTagsEnabled
                }
                allowBigSocketControl={allowBigSocketControl}
              />
            )}
          </Group>
        </Card>
      )}
      <ChannelsListSwitch
        isTestModeActive={isTestModeActive}
        channelsType={channelsType}
        setChannelsType={setChannelsType}
      />
      {activeChannels.length > 0 &&
        activeChannels.map((channel, index) => {
          const isLast = index === activeChannels.length - 1;
          const linkedChannel = getLinkedChannel(
            isPMDevice,
            channels,
            channelsType === CHANNEL_TYPE.ELECTRICITY
              ? Number(channel.name) + CHANNELS_MAX_LENGTH.MM_E_8
              : Number(channel.name) - CHANNELS_MAX_LENGTH.MM_E_8,
          );

          return (
            <Card
              ref={isLast && !isEditModeActive ? channelRef : undefined}
              radius={0}
              key={index}>
              <SingleChannel
                channel={channel}
                onDelete={deleteChannel}
                onChange={saveData}
                setFormErrors={setFormErrors}
                isEditModeActive={isEditModeActive}
                isTestModeActive={isTestModeActive}
                channelsType={channelsType}
                deviceType={deviceType?.euiType}
                occupiedSockets={occupiedSockets}
                isLastChannel={isLast}
                electricityIndexes={electricityIndexes}
                linkedChannel={linkedChannel}
                isDeviceSynced={isDeviceSynced}
              />
            </Card>
          );
        })}
      <Group position="center" p="lg">
        <Button
          disabled={
            !deviceType ||
            isMaxAmountReached ||
            isTestModeActive ||
            isMaxWaterTapsReached
          }
          onClick={isEditModeActive ? showAddChannelModal : addChannel}
          variant="filled"
          size="md"
          radius="xl"
          color={
            channelsType === CHANNEL_TYPE.WATER
              ? 'accentBlue'
              : 'accentYellow'
          }
          leftIcon={<IconPlus size={18} />}>
          <Text transform="uppercase">{t('buttons.addChannel')}</Text>
        </Button>
        {openAddChannelModal && (
          <Modal
            zIndex={1111}
            opened={openAddChannelModal}
            onClose={showAddChannelModal}
            title={t('labels.addChannel')}
            classNames={{ close: classes.close }}>
            <SingleChannel
              channel={singleInitValues}
              onChange={handleChangeInModalData}
              setFormErrors={setFormErrors}
              isAddingChannelInModal
              deviceType={deviceType?.euiType}
              electricityIndexes={electricityIndexes}
              isDeviceSynced={isDeviceSynced}
            />
            <Group position="apart" mt="xs">
              <Button
                onClick={addChannelInModal}
                variant="filled"
                size="md"
                radius="xl"
                leftIcon={<IconPlus size={18} />}
                color={
                  channelsType === CHANNEL_TYPE.WATER
                    ? 'accentBlue'
                    : 'accentYellow'
                }>
                <Text transform="uppercase">
                  {t('buttons.addChannel')}
                </Text>
              </Button>
            </Group>
          </Modal>
        )}
      </Group>
      <TypeChangeModal
        isEditModeActive={isEditModeActive}
        prevDevice={prevDevice}
        hasChannels={!!channels.length}
        setChannels={setChannels}
        handleDeviceChange={handleDeviceChange}
        deviceType={deviceType?.euiType}
      />
      <UpdateModal
        opened={deviceQueryState === 'submitting'}
        reset={clearSubmitting}
        title={t('labels.addingChannel')}
      />
      {deviceQueryState === 'submitting' && <OverlayLoader />}
      {!isEditModeActive &&
        createPortal(
          <BottomNavigation>
            <Button
              type="submit"
              radius="xl"
              size="md"
              variant="filled"
              fullWidth
              color="accentCabaret"
              onClick={saveAll}>
              <Text size={14} weight={500} color="white">
                {t('buttons.save')}
              </Text>
            </Button>
          </BottomNavigation>,
          document.body,
        )}
    </div>
  );
};
