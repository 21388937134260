import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, Group, Text, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

const useStyles = createStyles(theme => ({
  textInput: {
    flexGrow: 1,
  },

  preview: {
    background: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 10,
  },

  inputGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
  },

  close: {
    color: theme.colors.primary[0],
  },
}));

interface IPedestalNameInputsProps {
  marinCode: string;
  value: string;
  onCompleted?: (name: string) => void;
  isEditModeActive?: boolean;
}

export const PedestalNameInputs: React.FC<IPedestalNameInputsProps> = ({
  marinCode,
  value,
  onCompleted,
  isEditModeActive,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [deviceName, setDeviceName] = useState(value);
  const [code, setCode] = useState(marinCode);
  const [pierId, setPierId] = useState('');
  const [pedestalId, setPedestalId] = useState('');

  const splitDeviceName = (name: string) => {
    const splittedName = name.split('-');
    setCode(splittedName[0]);
    setPierId(splittedName[1]);
    setPedestalId(splittedName[2]);
  };

  const handleSetPierId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !!event.target.value.length &&
        (event.target.value.length > event.target.maxLength ||
          !isAlphanumeric(event.target.value))
      ) {
        return;
      }
      setPierId(event.target.value);
      const pedestalArray = [marinCode, event.target.value, pedestalId];
      setDeviceName(pedestalArray.join('-'));
    },
    [marinCode, pedestalId],
  );

  const handleSetPedestalId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !!event.target.value.length &&
        (event.target.value.length > event.target.maxLength ||
          !isAlphanumeric(event.target.value))
      ) {
        return;
      }
      setPedestalId(event.target.value);
      const pedestalArray = [marinCode, pierId, event.target.value];
      setDeviceName(pedestalArray.join('-'));
    },
    [marinCode, pierId],
  );

  const handleComplete = useCallback(() => {
    onCompleted(deviceName);
  }, [deviceName, onCompleted]);

  useEffect(() => {
    if (isEditModeActive) {
      splitDeviceName(deviceName);
    }
  }, [deviceName, isEditModeActive]);

  return (
    <>
      <Group position="apart" mt="md">
        <Text size={14}>{t('labels.pedestalName')}</Text>
      </Group>
      <Group position="apart" mt="xs" className={classes.inputGroup}>
        <TextInput className={classes.textInput} value={code} readOnly />
        <TextInput
          className={classes.textInput}
          value={pierId}
          maxLength={3}
          onChange={handleSetPierId}
          onBlur={!isEditModeActive ? handleComplete : null}
          readOnly={isEditModeActive}
          placeholder={t('placeholders.pier')}
        />
        <TextInput
          className={classes.textInput}
          value={pedestalId}
          maxLength={3}
          onChange={handleSetPedestalId}
          onBlur={!isEditModeActive ? handleComplete : null}
          readOnly={isEditModeActive}
          placeholder={t('placeholders.pedestal')}
        />
      </Group>
    </>
  );
};
