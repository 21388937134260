import React, { useCallback } from 'react';
import {
  useMantineTheme,
  createStyles,
  Button,
  Text,
  Stack,
} from '@mantine/core';
import { IconLogout, IconUser, IconSailboat } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/auth-store';
import { SwitchLanguageMenu } from './switch-language-menu';
import packages from '../../package.json';
import { Sidebar } from './atoms/sidebar';

const useStyles = createStyles(() => ({
  sidebarItemsWrapper: {
    gap: 0,
    flex: 1,
  },

  sidebarItem: { paddingLeft: 0 },

  spacer: {
    background:
      'linear-gradient(270deg, #0A326F 0%, rgba(69, 67, 160, 0) 100%)',
    height: '1px',
    width: '100%',
    margin: '20px 0',
  },
}));

interface ISidebarMenuProps {
  opened: boolean;
  onClose: () => void;
}

interface ISidebarItemProps {
  icon: React.ReactNode;
  onClick: () => void;
  label: string;
  withSpacer?: boolean;
}

const Spacer: React.FC = () => {
  const { classes } = useStyles();
  return <div className={classes.spacer} />;
};

const AppVersion: React.FC = () => {
  const { t } = useTranslation();
  const { version } = packages;

  return (
    <>
      <Spacer />
      <Text size={14} weight={400} color="primary">
        {t('labels.appVersion')} {version}
      </Text>
    </>
  );
};

const SidebarItem: React.FC<ISidebarItemProps> = ({
  icon,
  onClick,
  label,
  withSpacer = false,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {withSpacer && <Spacer />}
      <Button
        leftIcon={icon}
        variant="subtle"
        size="sm"
        mb={15}
        onClick={onClick}
        classNames={{
          root: classes.sidebarItem,
        }}>
        <Text size={16} weight={400} color="primary" mx={6}>
          {label}
        </Text>
      </Button>
    </>
  );
};

export const SidebarMenu: React.FC<ISidebarMenuProps> = ({
  opened,
  onClose,
}) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { dispatch, state } = useAuth();

  const handleLogout = useCallback(() => {
    dispatch({
      type: 'logout',
    });
  }, [dispatch]);

  const handleNavigate = useCallback(
    (target: string) => () => {
      onClose();
      navigate(target);
    },
    [navigate, onClose],
  );

  return (
    <Sidebar
      opened={opened}
      onClose={onClose}
      title={state.auth.name}
      size="70%">
      <SwitchLanguageMenu />
      <Stack
        align="flex-start"
        justify="flex-start"
        className={classes.sidebarItemsWrapper}>
        <SidebarItem
          icon={<IconSailboat size={20} color={theme.colors.primary[0]} />}
          onClick={handleNavigate('/marinas')}
          label={t('buttons.marinas')}
          withSpacer
        />
        <SidebarItem
          icon={<IconUser size={20} color={theme.colors.primary[0]} />}
          onClick={handleNavigate('/profile')}
          label={t('buttons.profile')}
        />
        <SidebarItem
          icon={<IconLogout size={20} color={theme.colors.primary[0]} />}
          onClick={handleLogout}
          label={t('buttons.logout')}
        />
      </Stack>
      <AppVersion />
    </Sidebar>
  );
};
