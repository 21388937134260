export const getActiveElements = <T>(
  elements: T[],
  activeType: string,
  sorted: boolean,
  filterKey: string,
  sortKey: string,
) => {
  const actives = elements.filter(el => el[filterKey] === activeType);
  return sorted
    ? actives.sort((a, b) => Number(a[sortKey]) - Number(b[sortKey]))
    : actives;
};
