import React, { useCallback } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconSearch } from '@tabler/icons';

interface ISearchBoxProps extends Omit<TextInputProps, 'onChange'> {
  onSearchChange: (value: string) => void;
}

export const SearchBox: React.FC<ISearchBoxProps> = ({
  onSearchChange,
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onSearchChange === 'function') {
        onSearchChange(event.target.value);
      }
    },
    [onSearchChange],
  );

  return (
    <TextInput
      icon={<IconSearch size={18} />}
      radius="xl"
      placeholder={t('placeholders.search')}
      {...props}
      onChange={handleChange}
    />
  );
};
