import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute } from './components/atoms/protected-route';
import { HomePage } from './pages/home';
import { NotFoundTitle } from './pages/not-found';
import { Devices } from './pages/device/list';
import { EditDevice } from './pages/device/edit';
import { AddDevice } from './pages/device/add';
import { api } from './services/api';
import { ENDPOINTS } from './services/api/endpoints';
import { useAuth } from './store/auth-store';
import { Marinas } from './pages/marinas';
import { Profile } from './pages/profile';

export const App: React.FC = () => {
  const {
    state: { auth },
    dispatch,
  } = useAuth();
  const isAuthenticated = !!auth?.access_token;
  const { pathname } = useLocation();

  useEffect(() => {
    // ? logout interceptor logic is handled here, because we need access to dispatch
    const interceptor = api.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalConfig = error.config;
        if (
          error.response.status === 401 &&
          [ENDPOINTS.AUTH.USER.LOGIN].includes(originalConfig.url)
        ) {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          return dispatch({ type: 'logout' });
        }

        return Promise.reject(error);
      },
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/* PROTECTED ROUTES */}
      <Route element={<ProtectedRoute isAllowed={isAuthenticated} />}>
        <Route path="/marinas" element={<Marinas />} />
        <Route path="/devices" element={<Devices />} />
        <Route path="/device" element={<EditDevice />} />
        <Route path="/device/add" element={<AddDevice />} />
        <Route path="/profile" element={<Profile />} />
      </Route>

      <Route path="*" element={<NotFoundTitle />} />
    </Routes>
  );
};
