import React, { useState, useCallback, useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  useMantineTheme,
  Container,
  Card,
  Group,
  Title,
  Loader,
  Text,
  Button,
} from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconCheck, IconAlertCircle } from '@tabler/icons';
import { showNotification } from '@mantine/notifications';
import isURL from 'validator/lib/isURL';
import { addDevice, IChannel } from '../../services/api/devices';
import { UserHeader } from '../../components/user-header';
import { IDeviceType, DeviceTypes } from '../../types/device';
import { Channels } from './components/Channels/channels';
import { MapSection } from './components/map-section';
import {
  ScanEui,
  PedestalNameInputs,
  ScanPedestalQr,
} from './components/DeviceInputs';
import { useDeviceType } from '../../hooks/useDeviceType';
import {
  isCorrectEui,
  getPedestalQr,
  getLinkedChannel,
} from '../../utils';
import {
  EUI_MAX_LENGTH,
  PEDESTAL_QR_MAX_LENGTH,
  PEDESTAL_QR_SPLIT_SEPARATOR,
} from '../../constants/devices-data.constants';
import {
  CHANNELS_MAX_LENGTH,
  CHANNEL_TYPE,
} from '../../constants/channels-data.constants';
import { useMarinaName } from '../../hooks/useMarinaName';

const useStyles = createStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    minHeight: 200,
  },

  loader: {
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

const options = {
  enableHighAccuracy: true,
  maximumAge: Infinity,
};

const INIT_DATA = {
  marinCode: '',
  name: '',
  devEui: '',
  pedestalQr: '',
  lat: 0,
  lng: 0,
  channels: [],
};

const DEFAULT_POSITION = {
  latitude: 35,
  longitude: 20,
};

export const AddDevice: React.FC = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [singleDevice, setSingleDevice] = useState(INIT_DATA);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [gettingPosition, setGettingPosition] = useState(false);
  const [prevDevice, setPrevDevice] = useState<IDeviceType | null>(null);
  const { deviceType, setIsCheckingForType } = useDeviceType(
    singleDevice.devEui,
  );
  const isPMDevice = deviceType?.euiType === DeviceTypes.MM_E_PM;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { coords, isGeolocationEnabled, getPosition, positionError } =
    useGeolocated({
      positionOptions: options,
      onSuccess: () => {
        setGettingPosition(false);
      },
      onError: () => {
        if (!isGeolocationEnabled) {
          showNotification({
            title: t('notifications.error'),
            message: t('notifications.enableLocation'),
            color: 'accentRed',
            icon: <IconAlertCircle size={18} />,
          });
        }
        setGettingPosition(false);
      },
    });

  const marinCode = searchParams.get('marinCode');
  const { data: marinaName } = useMarinaName(marinCode);

  const handleAddDevice = useMutation(
    (value: object) => {
      return addDevice(value);
    },
    {
      onSuccess: () => {
        showNotification({
          title: t('notifications.addedDevice'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
        navigate(
          `/device?marinCode=${marinCode}&devEui=${singleDevice.devEui}`,
          { state: 'newDevice' },
        );
      },
    },
  );

  const handleDeviceChange = useCallback(
    (restore = false) => {
      if (restore) {
        setSingleDevice(prev => ({ ...prev, devEui: prevDevice.eui }));
        if (isCorrectEui(prevDevice.eui)) {
          setIsCheckingForType('pending');
        }
      } else {
        setPrevDevice(null);
      }
    },
    [prevDevice, setIsCheckingForType],
  );

  const onEuiScanned = useCallback(
    (eui: string) => {
      const code = eui.substring(0, EUI_MAX_LENGTH).toLowerCase();
      setErrorMessage(null);
      if (isCorrectEui(singleDevice.devEui)) {
        setPrevDevice({
          eui: singleDevice.devEui,
          type: deviceType?.euiType,
        });
      }
      setSingleDevice(prev => ({ ...prev, devEui: code }));
      if (isCorrectEui(code)) {
        setIsCheckingForType('pending');
      } else {
        setErrorMessage(t('validations.wrongEui'));
      }
    },
    [singleDevice, setIsCheckingForType, deviceType, t],
  );

  const onPedestalQrScanned = useCallback((pedestalQr: string) => {
    if (isURL(pedestalQr)) {
      const code = getPedestalQr(pedestalQr, PEDESTAL_QR_SPLIT_SEPARATOR);
      setSingleDevice(prev => ({ ...prev, pedestalQr: code }));
    } else {
      setSingleDevice(prev => ({ ...prev, pedestalQr }));
    }
  }, []);

  const onPedestalNameChange = useCallback((name: string) => {
    setSingleDevice(prev => ({ ...prev, name }));
  }, []);

  const handlePositionChange = useCallback((lat, lng) => {
    setPosition({
      latitude: lat,
      longitude: lng,
    });
  }, []);

  const handleGetPosition = useCallback(() => {
    setGettingPosition(true);
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    if (coords) {
      handlePositionChange(coords.latitude, coords.longitude);
    }
  }, [coords, handlePositionChange]);

  useEffect(() => {
    if (sessionStorage.getItem('searchedEui')) {
      onEuiScanned(sessionStorage.getItem('searchedEui'));
      sessionStorage.removeItem('searchedEui');
      showNotification({
        title: t('notifications.deviceNotFound'),
        message: t('notifications.addDeviceNow'),
        color: 'teal.8',
        icon: <IconCheck size={18} />,
      });
    }
  }, [onEuiScanned, t]);

  if (!singleDevice) {
    return null;
  }

  const loading = handleAddDevice.isLoading;

  const onSave = channels => {
    if (!/^\w+-\w+-\w+$/.test(singleDevice.name)) {
      showNotification({
        title: t('notifications.error'),
        message: t('notifications.invalidDeviceName'),
        color: 'accentRed',
        icon: <IconAlertCircle size={18} />,
      });
      return;
    }
    if (
      singleDevice.pedestalQr.length &&
      singleDevice.pedestalQr.length !== PEDESTAL_QR_MAX_LENGTH
    ) {
      showNotification({
        title: t('notifications.error'),
        message: t('notifications.invalidPedestalQrLength'),
        color: 'accentRed',
        icon: <IconAlertCircle size={18} />,
      });
      return;
    }
    const channelValues = channels.map((channel: IChannel) => {
      const linkedChannel = getLinkedChannel(
        isPMDevice && channel.type === CHANNEL_TYPE.WATER,
        channels,
        channel.modBus,
      );
      return {
        ...channel,
        physicalName: String(channel.physicalName),
        meterStatus:
          Number(channel.meterStatus.toFixed(2)) *
          Math.round(channel.pulse),
        pulse: Math.round(channel.pulse),
        modBus: linkedChannel ? linkedChannel.modBus : channel.modBus,
        name: linkedChannel
          ? String(Number(linkedChannel.name) + CHANNELS_MAX_LENGTH.MM_E_8)
          : channel.name,
      };
    });

    const item = {
      ...singleDevice,
      lat: position.latitude ? position.latitude : 0,
      lng: position.longitude ? position.longitude : 0,
      marinCode,
      // This conditional operator is temporary due to the fact pedestal qr is not fully implemented yet in the system,
      // so in some cases it will be random generated number, will be removed later
      pedestalQr: singleDevice.pedestalQr
        ? singleDevice.pedestalQr
        : String(Math.random()).substring(2, 9),
      channels: channelValues,
    };

    handleAddDevice.mutate(item);
  };

  return (
    <>
      <Container>
        <UserHeader
          backTarget={`/devices/?marinCode=${marinCode}`}
          isLoading={loading}
          backModal
          title={marinaName}
        />
        <Card m="xs">
          <Group position="apart" pb="lg">
            <Title order={4}>{t('labels.addDevice')}</Title>
          </Group>
          <Card.Section className={classes.card}>
            {positionError && (
              <Group mx="sm" mb="xs" spacing="xs">
                <Text size={14} weight={500}>
                  {t('descriptions.enableLocation')}
                </Text>
                <Button
                  variant="filled"
                  size="xs"
                  radius="xl"
                  uppercase
                  color="accentBlue"
                  onClick={handleGetPosition}>
                  {t('buttons.getPosition')}
                </Button>
              </Group>
            )}
            {(!coords && isGeolocationEnabled) || gettingPosition ? (
              <Group className={classes.loader}>
                <Loader color={theme.colors.primary[0]} variant="dots" />
              </Group>
            ) : (
              <MapSection
                coords={coords ?? position}
                handleMarkerMove={handlePositionChange}
                zoom={!isGeolocationEnabled ? 4 : 16}
              />
            )}
          </Card.Section>
          <PedestalNameInputs
            marinCode={marinCode}
            onCompleted={onPedestalNameChange}
            value={singleDevice.name}
          />
          <ScanPedestalQr
            pedestalQr={singleDevice.pedestalQr}
            onCodeChange={onPedestalQrScanned}
          />
          <ScanEui
            devEui={singleDevice.devEui}
            onCodeChange={onEuiScanned}
            error={errorMessage}
          />
        </Card>
        {singleDevice?.channels && (
          <Channels
            deviceType={deviceType}
            value={singleDevice?.channels}
            onChange={onSave}
            handleDeviceChange={handleDeviceChange}
            prevDevice={prevDevice}
          />
        )}
      </Container>
    </>
  );
};
