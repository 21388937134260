import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { createStyles, Center } from '@mantine/core';
import { QRCodeScanner } from '../../../../components/Html5QrcodePlugin';

const useStyles = createStyles(() => ({
  preview: {
    background: 'white',
    borderRadius: 10,

    img: {
      maxHeight: '150px',
      objectFit: 'contain',
    },
  },
}));

interface IScannerProp {
  handleScannedCode: (code: string) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  validate: (decodedText: string) => boolean;
  previewSrc: string;
  errorMessage: string;
}

export const QRScanner: React.FC<IScannerProp> = ({
  handleScannedCode,
  setOpen,
  validate,
  previewSrc,
  errorMessage,
}) => {
  const { classes } = useStyles();

  const handleScan = useCallback(
    (decodedText: string) => {
      handleScannedCode(decodedText);
      setOpen(false);
    },
    [handleScannedCode, setOpen],
  );

  return (
    <>
      <Center className={classes.preview}>
        <img src={previewSrc} alt="Scan code" width="100%" />
      </Center>
      <QRCodeScanner
        onScanSuccess={handleScan}
        errorMessage={errorMessage}
        validate={validate}
        setOpen={setOpen}
      />
    </>
  );
};
