import React, { SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCheck } from '@tabler/icons';
import { useMutation } from '@tanstack/react-query';
import { createStyles, Text, Modal, Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useSearchParams } from 'react-router-dom';
import { IDeviceError } from '../../../../services/api/errors';
import {
  requestSupport,
  ISupportRequest,
} from '../../../../services/api/support';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },

  modalBody: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
}));

interface IErrorInstructionProps {
  currentError: IDeviceError;
  setCurrentError: React.Dispatch<SetStateAction<IDeviceError>>;
}

export const ErrorInstruction: React.FC<IErrorInstructionProps> = ({
  currentError,
  setCurrentError,
}) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');
  const [isSupportRequested, setIsSupportRequested] =
    useState<boolean>(false);

  const resetCurrentError = useCallback(() => {
    setCurrentError(null);
    setIsSupportRequested(false);
  }, [setCurrentError]);

  const mutation = useMutation(
    (item: ISupportRequest) => {
      return requestSupport(item);
    },
    {
      onSuccess: () => {
        showNotification({
          title: t('notifications.supportRequest'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
        setIsSupportRequested(true);
      },
    },
  );

  const handleRequestSupport = useCallback(() => {
    const item = {
      marinCode,
      errorId: currentError.id,
    };
    mutation.mutate(item);
  }, [marinCode, currentError, mutation]);

  return (
    <Modal
      opened={!!currentError}
      onClose={resetCurrentError}
      title={t('labels.fixError')}
      withCloseButton
      centered
      classNames={{
        close: classes.close,
        body: classes.modalBody,
      }}>
      <Text size={12} color="black">
        {t('labels.code')}: {currentError?.code}
      </Text>
      <Text size={12} color="black">
        {t('labels.solution')}:{' '}
        {i18n.exists(`deviceErrors.${currentError?.code}.instruction`)
          ? t(`deviceErrors.${currentError?.code}.instruction`)
          : t(`deviceErrors.defaultSolution`)}
      </Text>
      <Button
        variant="light"
        size="xs"
        mt="sm"
        radius="xl"
        uppercase
        color="accentCabaret"
        disabled={isSupportRequested}
        onClick={handleRequestSupport}>
        <Text color="white" size={12} weight={500}>
          {t(`buttons.supportRequest`)}
        </Text>
      </Button>
    </Modal>
  );
};
