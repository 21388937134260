export const en = {
  code: 'en_gb',
  labels: {
    email: 'Email',
    password: 'Password',
    channels: 'Channels',
    device: 'Device EUI',
    pedestalQr: 'Pedestal QR',
    pedestalName: 'Pedestal name',
    scanCode: 'Scan code',
    addChannel: 'Add channel',
    editChannel: 'Edit channel',
    startReplacingCounter: 'Start replacing the counter',
    finishReplacingCounter: 'Finish replacing the counter',
    removeChannel: 'Remove channel',
    removeDevice: 'Remove device',
    channelName: 'Channel name:',
    pulse: 'pulse per kWh',
    pulseWater: 'pulses per [m3]',
    maximumCurrent: 'Maximum current [A]',
    voltage: 'Voltage [VAC]',
    meterStatus: 'Meter status [kWh]',
    meterStatusWater: 'Meter status [m3]',
    addDevice: 'Add device',
    editDevice: 'Edit device',
    remove: 'Remove',
    lastSeen: 'Last seen:',
    never: 'Never',
    appVersion: 'App Version',
    attention: 'Attention!',
    testMode: 'Test mode',
    socketNumber: 'Socket number',
    tapNumber: 'Tap number',
    channelNumber: 'No. (channel)',
    output: 'Output',
    unplug: 'Unplug',
    editOutputs: 'Edit outputs',
    findDevice: 'Scan code to find device',
    modbusAddress: 'MODBUS Address',
    linkedChannel: 'Linked electricity channel',
    scanModBus: 'Scan MODBUS',
    updatingOutputs: 'Updating outputs',
    updatingPMTags: 'Updating PM Tags',
    scanRequest: 'Request for scan',
    deviceErrors: 'Device errors',
    createdAt: 'Created at',
    code: 'Code',
    description: 'Description',
    resolvedAt: 'Resolved at',
    resolvedBy: 'Resolved by',
    autoResolved: 'Resolved automatically',
    fixError: 'How to fix error?',
    solution: 'Solution',
    pmTags: 'PM Tags',
    linkedTap: 'Linked tap',
    linkedSocket: 'Linked socket',
    addingChannel: 'Adding new channel',
    updatingCounter: 'Updating counter',
    profile: 'Profile',
    marinas: 'Marinas',
    changePassword: 'Change your password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    socket: 'Socket',
    tap: 'Tap',
    switchingReason: 'Reason for switching on the tap/socket',
    customerReason: 'Connecting the customer',
    repairReason: 'Repair / test',
    technicalReason: 'Technical / service boat',
    boatName: 'Boat name',
    berth: 'Berth',
    transient: 'Transient',
    yes: 'Yes',
    no: 'No',
    comment: 'Comment',
  },
  descriptions: {
    removeChannel: 'Are you sure you want to remove the channel?',
    removeDevice: 'Are you sure you want to remove the device?',
    changesNotSaved: 'Changes not saved',
    leaveConfirmation: 'Are you sure to leave?',
    enableLocation:
      'Your location is necessary to determine device position. Please enable permission in your browser settings.',
    typeChangeModal:
      'Are you sure to change the device type? All the channel data will be removed',
    changeSocketNumber: 'Are you sure to change the socket number?',
    confirmChannelEnable: 'Are you sure to unlock this channel?',
    confirmChannelDisable: 'Are you sure to lock this channel?',
    confirmPMTagsEnable: 'Are you sure to enable PM Tags?',
    confirmPMTagsDisable: 'Are you sure to disable PM Tags?',
    updating: 'This may take up to 3 minutes',
    scanModBus: 'I want to scan MODBUS Address for this channel',
    wrongFirmwareForTestMode:
      'Please update the devices firmware before enabling test mode.',
    skippedChannel:
      'Refresh data or check the physical connection of the socket in the pedestal.',
    startReplaceCounter:
      'Are you sure you want to start replacing the counter?',
  },
  placeholders: {
    email: 'email@example.com',
    password: '********',
    search: 'Search',
    pier: 'Pier',
    pedestal: 'Pedestal',
    chooseCamera: 'Choose camera',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    enterComment: 'Enter a comment',
  },
  buttons: {
    login: 'Login',
    logout: 'Log out',
    back: 'Back',
    refresh: 'Refresh',
    save: 'Save',
    remove: 'Remove',
    addChannel: 'Add channel',
    enable: 'Enable',
    lock: 'Lock',
    electricity: 'Electricity',
    water: 'Water',
    add: 'Add',
    replace_device: 'Replace MM-E',
    replace_counter: 'Replace counter',
    replace_pedestal_code: 'Replace QR',
    savePosition: 'Save new position',
    cancel: 'Cancel',
    hide: 'Hide',
    confirm: 'Confirm',
    getPosition: 'Get Position',
    resolve: 'Resolve',
    startScan: 'Start scanning',
    stopScan: 'Stop scanning',
    scanImage: 'Scan image',
    supportRequest: 'Send support request',
    profile: 'Profile',
    marinas: 'Marinas',
    start: 'Start',
    finish: 'Finish',
  },
  legend: {
    title: 'Legend',
    neverSeen: 'device never seen',
    notSynced: 'device not seen in the last 20 minutes',
    hasErrors: 'device has unresolved errors (intervention needed)',
  },
  notifications: {
    addedDevice: 'Device added  👍',
    updatedGeo: 'Position updated  👍',
    updatedEui: 'Device EUI updated 👍',
    updatedPedestalQr: 'Pedestal QR updated 👍',
    replacedCounter: 'Counter replaced 👍',
    startedReplaceCounter: 'Started replacing counter 👍',
    replacedCounterNewValue:
      'New meter status: <strong>{{meter_status}} {{unit}}</strong>',
    addedChannel: 'Channel added 👍',
    removedChannel: 'Channel removed 👍',
    changedChannelState: 'Channel state changed 👍',
    removedDevice: 'Device removed 👍',
    invalidValues: 'Invalid values ⚡',
    updatedOutputs: 'Device outputs set correctly',
    error: 'Error 🤥',
    success: 'Success',
    errorMessage: 'Something went wrong',
    tryAgain: 'Please try again',
    notSeenOnSubmit:
      'The device is not seen. Make sure the data has been saved correctly.',
    invalidDeviceName:
      'Device name fields are invalid. Please complete the fields',
    invalidPedestalQrLength:
      'Pedestal QR should be at least 6 characters long',
    enableLocation: 'You need to enable your geolocation permission',
    testModeEnabled: 'Test mode is now enabled',
    testModeDisabled: 'Test mode is now disabled',
    pmTagsEnabled: 'PM Tags is now enabled',
    pmTagsDisabled: 'PM Tags is now disabled',
    deviceNotFound: 'This device is not found',
    typeNotSupported: 'This type is not supported',
    preventTypeChange: `You can't change device type`,
    socketNumbersUnique: 'Socket number values need to be unique',
    addDeviceNow: 'You can add it now!',
    uniqueLinks:
      'Links between electricity and water channels need to be unique',
    scanRequested:
      'A scan request has been sent. Scanning may take up to 10 minutes.',
    errorResolved: 'Error resolved 👍',
    qrNotFound: 'No QR code found',
    supportRequest: 'Your request has been sent to technical support',
    passwordChanged: 'Password changed successfully',
  },
  errors: {
    devEui: 'EUI should not be empty',
    channels: 'Channels must contain at least 1 elements',
    newDevEui: 'New Device EUI should not be empty',
    pulse: 'Pulse should not be undefined',
    meterStatus: 'Meter status should not be undefined',
    userNotFound: 'The user was not found',
    wrongPassword: 'Password is invalid',
    physicalName: 'Physical name value is invalid',
    pedestalQr: 'Pedestal QR value is invalid',
    modBus: 'MODBUS value is invalid',
  },
  validations: {
    invalid: 'Value is invalid.',
    required: 'Field is required.',
    email: 'Email is invalid.',
    wrongMarina: 'The scanned QR code is from another marina.',
    wrongEui: 'This EUI is not correct',
    wrongPedestalQr: 'This code is not correct',
    wrongPedestalName: 'Scanned pedestal name is not correct',
    passwordLength: 'Password must have at least 8 characters',
    strongPassword:
      'The password must contain at least one capital letter, lowercase letter, a number and a special character',
    samePasswords: 'The passwords must be the same',
  },
  notFound: {
    title: 'Oh, no!',
    description:
      'Unfortunately, the page was not found. Please use the button below to return to the home page and try again.',
    button: 'Take me back to home page',
  },
  deviceErrors: {
    1: {
      description: 'The uploaded firmware is invalid',
      instruction: 'Replace the device',
    },
    2: {
      description: 'The downloaded firmware is invalid',
      instruction:
        'Configure FUOTA with the correct hardware version for the device',
    },
    3: {
      description: 'MODBUS communication error {{arg0}}',
      arg0: 'for channel no. {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    4: {
      description: 'Failed MODBUS scan {{arg0}} {{arg1}}',
      arg0: 'for channel no. {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    5: {
      description: 'Reset executed by watchdog {{arg0}}',
      arg0_0: '(IWDG watchdog)',
      arg0_1: '(WWDG watchdog)',
    },
    6: {
      description: 'User credits overwritten {{arg0}} {{arg1}}',
      arg0: '(number of credits: {{arg}}).',
      arg1: 'No. of the channel for which the credits have been overwritten: {{arg}}',
      instruction:
        'Verify if overwritten credits should be returned to the customer. Report the situation to the office for verification.',
    },
    7: {
      description: 'The device has lost communication',
    },
    8: {
      description: 'Replace or update the Plus {{arg0}}}',
      arg0: 'socket no. {{arg}}',
      instruction:
        'Replace the socket with one with firmware 1.29 or update the socket firmware to 1.29.',
    },
    defaultSolution:
      'If you have a problem to solve the error, please contact technical support',
  },
};
