import React, { useMemo } from 'react';
import { IAuthData, TokenService } from '../services/token';

type Action =
  | { type: 'setAuthData'; payload: IAuthData }
  | { type: 'logout' };

type Dispatch = (action: Action) => void;
type State = { auth: null | IAuthData };
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function authReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setAuthData': {
      TokenService.setAuthData(action.payload);
      return { auth: action.payload };
    }
    case 'logout': {
      TokenService.removeAuthData();
      return { auth: null };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

const tokenOnInit = TokenService.getAuthData() || null;
tokenOnInit && TokenService.setAuthData(tokenOnInit);

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(authReducer, {
    auth: tokenOnInit,
  });
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <AuthStateContext.Provider value={value}>
      {children}
    </AuthStateContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
