import { api } from './index';
import { IAuthData } from '../token';
import { ENDPOINTS } from './endpoints';

export interface ILoginData {
  email: string;
  password: string;
}

export const login = (data: ILoginData) => {
  return api.post<IAuthData>(ENDPOINTS.AUTH.USER.LOGIN, data);
};
