import React, { useCallback, useState } from 'react';
import { IconUrgent } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  createStyles,
  ActionIcon,
  Loader,
  Center,
  Group,
} from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { getErrors, IDeviceError } from '../../../../services/api/errors';
import { Sidebar } from '../../../../components/atoms/sidebar';
import { ErrorCard } from './error-card';
import { ErrorInstruction } from './error-instruction';

const useStyles = createStyles(() => ({
  errorsList: {
    width: 'calc(100% + 12px)',
    paddingRight: '12px',
    overflowY: 'auto',
    gap: 0,
  },
}));

interface IErrorsListProps {
  hasErrors: boolean;
}

export const ErrorsList: React.FC<IErrorsListProps> = ({ hasErrors }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const [opened, setOpened] = useState<boolean>(false);
  const [currentError, setCurrentError] = useState<IDeviceError>(null);

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['getErrors', marinCode, devEui],
    () => getErrors(marinCode, devEui),
    {
      refetchOnWindowFocus: false,
      select: res => (res?.data?.errors?.length ? res.data.errors : null),
    },
  );

  const loading = isLoading || isFetching;

  const handleSetOpened = useCallback(() => {
    if (!opened) refetch();
    setOpened(prev => !prev);
  }, [refetch, opened]);

  return (
    <>
      <ActionIcon
        variant="filled"
        color={hasErrors ? 'accentRed' : 'accentBlue'}
        onClick={handleSetOpened}
        disabled={!data}>
        <IconUrgent color="white" />
      </ActionIcon>
      <Sidebar
        opened={opened}
        onClose={handleSetOpened}
        title={t('labels.deviceErrors')}
        position="right"
        size="90%">
        {loading ? (
          <Center>
            <Loader my="md" color="accentBlue" />
          </Center>
        ) : (
          <Group className={classes.errorsList}>
            {data?.map(err => (
              <ErrorCard
                key={err.id}
                error={err}
                setCurrentError={setCurrentError}
              />
            ))}
          </Group>
        )}
      </Sidebar>
      <ErrorInstruction
        currentError={currentError}
        setCurrentError={setCurrentError}
      />
    </>
  );
};
