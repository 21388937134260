import { useQuery } from '@tanstack/react-query';
import { getMarinas } from '../services/api/marinas';

export const useMarinaName = (code: string) => {
  return useQuery(['getMarinas'], getMarinas, {
    refetchOnWindowFocus: false,
    select: marines => {
      const filteredMarina = marines?.data?.items?.filter(
        marina => marina.code === code,
      );
      return filteredMarina[0]?.name;
    },
  });
};
