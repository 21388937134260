import React, { useCallback, useState } from 'react';
import {
  createStyles,
  useMantineTheme,
  Text,
  Group,
  Modal,
  Button,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { useSearchParams } from 'react-router-dom';
import { IconCheck } from '@tabler/icons';
import isURL from 'validator/lib/isURL';
import { updatePedestalQr } from '../../../../services/api/devices';
import { ScanInput } from './scan-input';
import { QRScanner } from './qr-scanner';
import {
  PEDESTAL_QR_MAX_LENGTH,
  PEDESTAL_QR_SPLIT_SEPARATOR,
} from '../../../../constants/devices-data.constants';
import { getPedestalQr, isCorrectPedestalQr } from '../../../../utils';
import PREVIEWS from '../../../../assets/qr-previews';
import { useDeviceRefetch } from '../../../../hooks/useDeviceRefetch';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },
}));

type TScanPedestalQr =
  | {
      pedestalQr: string;
      onCodeChange: (eui: string) => void;
      isEditModeActive?: never;
      isTestModeActive?: never;
    }
  | {
      pedestalQr: string;
      onCodeChange?: never;
      isEditModeActive: boolean;
      isTestModeActive: boolean;
    };

export const ScanPedestalQr: React.FC<TScanPedestalQr> = ({
  pedestalQr,
  onCodeChange,
  isEditModeActive,
  isTestModeActive,
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [editScanPedestalQr, setEditScanPedestalQr] = useState(pedestalQr);
  const [opened, setOpened] = useState(false);
  const [openedEditScanner, setOpenedEditScanner] = useState(false);
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const [lng] = t('code').split('_');

  const deviceRefetch = useDeviceRefetch();

  const handleUpdatePedestalQr = useMutation(
    (item: object) => {
      return updatePedestalQr(devEui, item);
    },
    {
      onSuccess: () => {
        deviceRefetch();
        setOpened(prev => !prev);
        showNotification({
          title: t('notifications.updatedPedestalQr'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      },
    },
  );

  const validate = useCallback((decodedText: string) => {
    const code = getPedestalQr(decodedText, PEDESTAL_QR_SPLIT_SEPARATOR);
    return isCorrectPedestalQr(code);
  }, []);

  const handlePedestalQrEdit = () => {
    const item = {
      marinCode,
      pedestalQr: editScanPedestalQr,
    };
    handleUpdatePedestalQr.mutate(item);
  };

  const handleSetOpened = useCallback(() => {
    if (isEditModeActive) setEditScanPedestalQr(pedestalQr);
    setOpened(prev => !prev);
    setOpenedEditScanner(false);
  }, [pedestalQr, isEditModeActive]);

  const handleOpenEditScanner = useCallback(() => {
    setOpenedEditScanner(prev => !prev);
  }, []);

  const handleSetPedestalQr = useCallback(
    (code: string) => {
      onCodeChange(code);
    },
    [onCodeChange],
  );

  const handleSetEditScanPedestalQr = useCallback((code: string) => {
    if (isURL(code)) {
      const qrCode = getPedestalQr(code, PEDESTAL_QR_SPLIT_SEPARATOR);
      setEditScanPedestalQr(qrCode);
    } else {
      setEditScanPedestalQr(code);
    }
  }, []);

  if (isEditModeActive) {
    return (
      <>
        <ScanInput
          onClick={handleSetOpened}
          value={pedestalQr}
          label={t('labels.pedestalQr')}
          replaceButtonLabel={t('buttons.replace_pedestal_code')}
          isEditModeActive={isEditModeActive}
          isTestModeActive={isTestModeActive}
          maxLength={PEDESTAL_QR_MAX_LENGTH}
          type="numeric"
        />
        <Modal
          zIndex={1111}
          opened={opened}
          onClose={handleSetOpened}
          title={t('labels.scanCode')}
          classNames={{ close: classes.close }}>
          {openedEditScanner ? (
            <QRScanner
              handleScannedCode={handleSetEditScanPedestalQr}
              setOpen={setOpenedEditScanner}
              validate={validate}
              previewSrc={PREVIEWS[lng] ?? PREVIEWS.hr}
              errorMessage={t('validations.wrongPedestalQr')}
            />
          ) : (
            <>
              <ScanInput
                onClick={handleOpenEditScanner}
                value={editScanPedestalQr}
                label={t('labels.pedestalQr')}
                replaceButtonLabel={t('buttons.replace_pedestal_code')}
                error={
                  editScanPedestalQr &&
                  !isCorrectPedestalQr(editScanPedestalQr)
                }
                onChange={handleSetEditScanPedestalQr}
                maxLength={PEDESTAL_QR_MAX_LENGTH}
                type="numeric"
              />
              {editScanPedestalQr &&
                !isCorrectPedestalQr(editScanPedestalQr) && (
                  <Text color={theme.colors.accentRed[0]} size="xs">
                    {t('validations.wrongPedestalQr')}
                  </Text>
                )}
              <Group position="apart" mt="xl" noWrap>
                <Button
                  variant="outline"
                  size="sm"
                  radius="xl"
                  uppercase
                  onClick={handleSetOpened}
                  color="accentBlue">
                  <Text size={14} weight={500}>
                    {t('buttons.cancel')}
                  </Text>
                </Button>
                <Button
                  variant="light"
                  size="sm"
                  radius="xl"
                  uppercase
                  onClick={handlePedestalQrEdit}
                  disabled={!isCorrectPedestalQr(editScanPedestalQr)}
                  color="accentCabaret">
                  <Text color="white" size={14} weight={500}>
                    {t('buttons.save')}
                  </Text>
                </Button>
              </Group>
            </>
          )}
        </Modal>
      </>
    );
  }

  return (
    <>
      <ScanInput
        onClick={handleSetOpened}
        label={t('labels.pedestalQr')}
        replaceButtonLabel={t('buttons.replace_pedestal_code')}
        value={pedestalQr}
        onChange={handleSetPedestalQr}
        maxLength={PEDESTAL_QR_MAX_LENGTH}
        type="numeric"
      />
      <Modal
        zIndex={1111}
        opened={opened}
        onClose={handleSetOpened}
        title={t('labels.scanCode')}
        classNames={{ close: classes.close }}>
        <QRScanner
          handleScannedCode={onCodeChange}
          setOpen={setOpened}
          validate={validate}
          previewSrc={PREVIEWS[lng] ?? PREVIEWS.hr}
          errorMessage={t('validations.wrongPedestalQr')}
        />
      </Modal>
    </>
  );
};
