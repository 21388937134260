import React from 'react';
import {
  createStyles,
  useMantineTheme,
  LoadingOverlay,
} from '@mantine/core';

const useStyles = createStyles(() => ({
  loader: {
    position: 'fixed',
    top: 0,
    bottom: 0,
  },
}));

export const OverlayLoader: React.FC<{ withLoader?: boolean }> = ({
  withLoader = true,
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <LoadingOverlay
      visible
      overlayOpacity={0.2}
      transitionDuration={1000}
      loader={!withLoader ? <></> : undefined}
      overlayColor={theme.colors.secondary[0]}
      className={classes.loader}
    />
  );
};
