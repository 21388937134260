import { MantineThemeOverride } from '@mantine/core';

export const PRIMARY_THEME: MantineThemeOverride = {
  primaryShade: 0,
  colors: {
    primary: [
      '#051d40',
      '#051a3a',
      '#041733',
      '#04142d',
      '#031126',
      '#030f20',
      '#020c1a',
      '#010913',
      '#01060d',
      '#000306',
    ],
    secondary: [
      '#0A326F',
      '#092d64',
      '#082859',
      '#07234e',
      '#061e43',
      '#051938',
      '#04142c',
      '#030f21',
      '#020a16',
      '#01050b',
    ],
    accentCabaret: [
      '#CE7762',
      '#b96b58',
      '#a55f4e',
      '#905345',
      '#7c473b',
      '#673c31',
      '#523027',
      '#3e241d',
      '#291814',
      '#150c0a',
    ],
    accentBlue: [
      '#1870C2',
      '#1665af',
      '#135a9b',
      '#114e88',
      '#0e4374',
      '#0c3861',
      '#0a2d4e',
      '#07223a',
      '#051627',
      '#020b13',
    ],
    accentRed: [
      '#eb5757',
      '#d44e4e',
      '#bc4646',
      '#a53d3d',
      '#8d3434',
      '#762c2c',
      '#5e2323',
      '#461a1a',
      '#2f1111',
      '#170909',
    ],
    accentYellow: [
      '#fcc419',
      '#e3b017',
      '#ca9d14',
      '#b08912',
      '#97760f',
      '#7e620d',
      '#654e0a',
      '#4c3b07',
      '#322705',
      '#191402',
    ],
  },
  colorScheme: 'light',
};
