import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconScan } from '@tabler/icons';
import {
  createStyles,
  Stack,
  Button,
  Text,
  NativeSelect,
  FileButton,
  Group,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import QrScanner from 'qr-scanner';

const useStyles = createStyles(() => ({
  reader: {
    objectFit: 'cover',
    marginTop: '10px',
  },

  readerWrapper: {
    height: '250px',
  },

  icon: {
    height: '100px',
    width: '100%',
  },
}));

type TScanState = 'pending' | 'scanning';

interface ICamera {
  value: string;
  label: string;
}

interface IQRScannerProps {
  onScanSuccess: (decodedText: string) => void;
  errorMessage: string;
  validate: (decodedText: string) => boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QRCodeScanner: React.FC<IQRScannerProps> = ({
  onScanSuccess,
  errorMessage,
  validate,
  setOpen,
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const reader = useRef<HTMLVideoElement>(null);
  const scanner = useRef<QrScanner>(null);
  const [scanState, setScanState] = useState<TScanState>('pending');
  const [cameras, setCameras] = useState<ICamera[]>(null);

  const startScan = useCallback(() => {
    if (scanner.current) {
      setScanState('scanning');
      scanner.current.start();
    }
  }, []);

  const stopScan = useCallback(() => {
    if (scanner.current) {
      setScanState('pending');
      scanner.current.stop();
    }
  }, []);

  const onScan = useCallback(
    (decodedText: string) => {
      if (validate(decodedText)) {
        onScanSuccess(decodedText);
        stopScan();
      } else {
        showNotification({
          title: t('notifications.error'),
          message: errorMessage,
          color: 'accentRed',
        });
        stopScan();
        setOpen(false);
      }
    },
    [errorMessage, onScanSuccess, setOpen, stopScan, t, validate],
  );

  const getCameras = async () => {
    const camerasList = await QrScanner.listCameras();
    const camerasLabels = camerasList.map(camera => {
      return { value: camera.id, label: camera.label };
    });
    setCameras(camerasLabels);
  };

  const handleCameraChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      scanner.current.setCamera(event.target.value);
    },
    [],
  );

  const scanFile = useCallback(
    (payload: File) => {
      QrScanner.scanImage(payload, { returnDetailedScanResult: true })
        .then(result => onScan(result.data))
        .catch(() =>
          showNotification({
            title: t('notifications.error'),
            message: t('notifications.qrNotFound'),
            color: 'accentRed',
          }),
        );
    },
    [onScan, t],
  );

  useEffect(() => {
    if (reader.current && !scanner.current) {
      scanner.current = new QrScanner(
        reader.current,
        result => onScan(result.data),
        {
          highlightScanRegion: true,
          maxScansPerSecond: 1,
        },
      );
      scanner.current.setInversionMode('both');
      getCameras();
    }
  }, [
    onScanSuccess,
    errorMessage,
    validate,
    t,
    setOpen,
    stopScan,
    onScan,
  ]);

  return (
    <Stack align="center">
      <video
        muted
        playsInline
        autoPlay
        height="0"
        width="100%"
        ref={reader}
        className={cx(
          classes.reader,
          scanState === 'scanning' && classes.readerWrapper,
        )}
      />
      {scanState === 'pending' && (
        <Group className={classes.readerWrapper}>
          <IconScan className={classes.icon} />
        </Group>
      )}
      <Button
        variant="light"
        size="sm"
        radius="xl"
        uppercase
        onClick={scanState === 'pending' ? startScan : stopScan}
        color="accentBlue">
        <Text size={14} weight={500} color="white">
          {scanState === 'pending'
            ? t('buttons.startScan')
            : t('buttons.stopScan')}
        </Text>
      </Button>
      {scanState === 'scanning' ? (
        <NativeSelect
          placeholder={t('placeholders.chooseCamera')}
          defaultValue=""
          data={cameras ?? []}
          onChange={handleCameraChange}
        />
      ) : (
        <FileButton onChange={scanFile} accept="image/png,image/jpeg">
          {props => (
            <Button
              variant="subtle"
              size="sm"
              radius="xl"
              uppercase
              color="white"
              {...props}>
              <Text size={14} weight={500} color="accentBlue">
                {t('buttons.scanImage')}
              </Text>
            </Button>
          )}
        </FileButton>
      )}
    </Stack>
  );
};
