import { api } from '../api';

export interface IAuthData {
  access_token: string;
  name: string;
}

const getLocalStorageItem = <T>(key: string): T | undefined => {
  const localStorageData = localStorage.getItem(key);
  return localStorageData ? JSON.parse(localStorageData) : undefined;
};

const getLocalToken = (): string | undefined => {
  const authData = getLocalStorageItem<IAuthData>('authData');
  return authData?.access_token;
};

const updateLocalToken = (accessToken: string) => {
  const authData = getLocalStorageItem<IAuthData>('authData');
  if (authData) {
    authData.access_token = accessToken;
  } else {
    throw new Error('No auth data found!');
  }
  localStorage.setItem('authData', JSON.stringify(authData));
};

const getAuthData = () => {
  return getLocalStorageItem<IAuthData>('authData');
};

const setAuthData = (authData: IAuthData) => {
  api.defaults.headers.common.Authorization = `Bearer ${authData.access_token}`;
  localStorage.setItem('authData', JSON.stringify(authData));
};

const removeAuthData = () => {
  localStorage.removeItem('authData');
};

export const TokenService = {
  getLocalToken,
  updateLocalToken,
  getAuthData,
  setAuthData,
  removeAuthData,
};
