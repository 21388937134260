import { api } from '.';
import { ENDPOINTS } from './endpoints';

export interface IUserPasswords {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const changePassword = (data: IUserPasswords) => {
  return api.put<IUserPasswords>(
    `${ENDPOINTS.USERS}/change-password`,
    data,
  );
};
