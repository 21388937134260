export const LAST_SYNC_DIFF = 21;
export const EUI_MAX_LENGTH = 16;
export const PEDESTAL_QR_MAX_LENGTH = 6;
export const MODBUS_MAX_VALUE = 255;
export const PEDESTAL_QR_SPLIT_SEPARATOR = '/';
export const SUBMITTING_TIMEOUT = 180000;
export const SUBMITTING_INTERVAL = 1000;
export const TESTMODE_INTERVAL = 30000;

export const indexArgs = Object.freeze({
  // determines which device error arguments contains channel index values,
  // these values should be incremented to display proper data in the app
  3: 'arg0',
  4: 'arg0',
  6: 'arg1',
});
