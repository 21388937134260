import { IChannel } from '../services/api/devices';

export const getLinkedChannel = (
  condition: boolean,
  channels: IChannel[],
  valueToLinked: number,
) => {
  if (condition) {
    return channels.find(
      (item: IChannel) => Number(item.name) === valueToLinked,
    );
  }
  return null;
};
