import React, { useCallback } from 'react';
import {
  createStyles,
  Button,
  Container,
  Space,
  Image,
  Box,
  TextInput,
  Group,
  Text,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../store/auth-store';
import { login } from '../../services/api/auth';

const useStyles = createStyles(() => ({
  submitButton: {
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
}));

export const HomePage: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatch } = useAuth();

  const form = useForm({
    initialValues: {
      email:
        process.env.NODE_ENV === 'development' ? 'ele+a@netizens.pl' : '',
      password: process.env.NODE_ENV === 'development' ? 'eleA' : '',
    },

    validate: {
      email: value =>
        /^\S+@\S+$/.test(value) ? null : t('validations.email'),
    },
  });

  const handleLogin = useCallback(async () => {
    try {
      const { data } = await login({
        email: form.values.email,
        password: form.values.password,
      });
      navigate('/marinas');

      dispatch({
        type: 'setAuthData',
        payload: {
          access_token: data.access_token,
          name: data.name,
        },
      });
    } catch (error) {
      // TODO: show error notification
      // console.log(error);
    }
  }, [navigate, form.values.email, form.values.password, dispatch]);

  return (
    <Container>
      <Stack px={20}>
        <Image
          p={20}
          mt={34}
          src="./dmarina-white.svg"
          alt="Dmarine - logo"
          width="auto"
        />
        <Space h="xl" />
        <Box p={20}>
          <form onSubmit={form.onSubmit(handleLogin)}>
            <TextInput
              size="md"
              required
              type="email"
              label={t('labels.email')}
              placeholder={t('placeholders.email')}
              {...form.getInputProps('email')}
            />
            <TextInput
              size="md"
              required
              type="password"
              label={t('labels.password')}
              placeholder={t('placeholders.password')}
              {...form.getInputProps('password')}
            />
            <Group position="center" mt="md">
              <Button
                type="submit"
                radius="xl"
                fullWidth
                size="md"
                mt={40}
                variant="filled"
                className={classes.submitButton}
                color="accentCabaret">
                <Text size={14} weight={500}>
                  {t('buttons.login')}
                </Text>
              </Button>
            </Group>
          </form>
        </Box>
      </Stack>
    </Container>
  );
};
