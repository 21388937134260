import React, { useMemo, useState, useCallback } from 'react';
import {
  createStyles,
  useMantineTheme,
  Button,
  Modal,
  Text,
  Title,
  Table,
  Select,
  Switch,
  Loader,
  Center,
  Tooltip,
  ActionIcon,
  Radio,
  TextInput,
  Stack,
  Space,
  ScrollArea,
  ScrollAreaProps,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconAlertCircle,
  IconChevronDown,
} from '@tabler/icons';
import { IChannel, setupOutputs } from '../../../services/api/devices';
import {
  IDeviceOutputs,
  IOutputsReport,
  Outputs,
  ConsumptionReportType,
} from '../../../types/device';
import { getChannelsOutputs } from '../../../utils';
import { OverlayLoader } from '../../../components/atoms/overlay-loader';
import { UpdateModal } from '../../../components/update-modal';
import { useDeviceQuery } from '../../../hooks/useDeviceQuery';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },

  center: {
    textAlign: 'center',
  },

  error: {
    borderColor: theme.colors.accentRed[0],
    color: theme.colors.accentRed[0],

    '&::placeholder': {
      color: theme.colors.accentRed[0],
    },
  },

  item: {
    '&[data-selected="true"]': {
      backgroundColor: theme.colors.accentBlue[0],
    },
    '&[data-selected="true"]:hover': {
      backgroundColor: theme.colors.accentBlue[0],
    },
  },

  radio: {
    '&:checked': {
      background: theme.colors.accentBlue[0],
    },
  },

  // need to set minWidth including paddings for container to prevent vertical overflowing of select items
  // container doesn't have any classes, that's why div selector is used
  scrollArea: {
    '& > div': {
      minWidth: 'calc(100% - 8px) !important',
    },
  },

  required: {
    color: theme.colors.accentRed[0],
  },

  table: {
    'thead tr th': {
      fontSize: '12px',
    },
  },

  radioGroup: {
    '& > .mantine-Group-root': {
      paddingBottom: '10px',
    },
  },

  skipped: {
    backgroundColor: theme.colors.gray[2],
  },
}));

interface IOutputsModalProps {
  channels: IChannel[];
  disabled: boolean;
  allowBigSocketControl: boolean;
}

const CustomDropdown = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  ({ children, ...rest }, ref) => {
    const { classes } = useStyles();

    return (
      <ScrollArea {...rest} classNames={{ viewport: classes.scrollArea }}>
        <div ref={ref}>{children}</div>
      </ScrollArea>
    );
  },
);

CustomDropdown.displayName = 'CustomDropdown';

const initialValues: IOutputsReport = {
  reason: ConsumptionReportType.CONNECTING_THE_CUSTOMER,
  boatName: '',
  berth: '',
  transient: '',
  comment: '',
};

export const OutputsModal: React.FC<IOutputsModalProps> = ({
  channels,
  disabled,
  allowBigSocketControl,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState<boolean>(false);
  const [isReportingModalActive, setIsReportingModalActive] =
    useState(false);
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const [lastSyncAt, setLastSyncAt] = useState<string>('');

  const outputsInitState = useMemo(
    () => getChannelsOutputs(channels),
    [channels],
  );
  const [outputs, setOutputs] =
    useState<IDeviceOutputs[]>(outputsInitState);

  const validateOutputsReportField = (
    value: string,
    values: IOutputsReport,
  ) =>
    !value.length &&
    values.reason === ConsumptionReportType.CONNECTING_THE_CUSTOMER
      ? t('validations.required')
      : null;

  const outputsReportForm = useForm<IOutputsReport>({
    initialValues,
    validate: {
      boatName: validateOutputsReportField,
      berth: validateOutputsReportField,
      transient: validateOutputsReportField,
    },
    validateInputOnChange: true,
  });

  const isCustomerReason =
    outputsReportForm.values.reason ===
    ConsumptionReportType.CONNECTING_THE_CUSTOMER;

  const {
    query: { refetch, isRefetching },
    deviceQueryState,
    startSubmitting,
    clearSubmitting,
  } = useDeviceQuery(res => {
    const deviceOutputs = getChannelsOutputs(res?.channels);
    if (deviceQueryState === 'submitting') {
      const isOutputsSynced = deviceOutputs.every(
        (item: IDeviceOutputs) => {
          const parallelOutput = outputs.find(
            output => output.channel === item.channel,
          );
          return (
            parallelOutput?.output === item.output &&
            parallelOutput?.unplugDetection === item.unplugDetection
          );
        },
      );
      if (isOutputsSynced && res.lastSyncAt !== lastSyncAt) {
        clearSubmitting();
        setOutputs(deviceOutputs);
        setLastSyncAt(res.lastSyncAt);
        showNotification({
          title: t('notifications.updatedOutputs'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      }
    } else {
      setOutputs(deviceOutputs);
      setLastSyncAt(res.lastSyncAt);
    }
  });

  const handleOutputSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOutputs(prev =>
        prev.reduce((acc, val) => {
          if (val.channel === event.target.id) {
            return [
              ...acc,
              {
                ...val,
                [event.target.dataset.key]: !val[event.target.dataset.key],
              },
            ];
          }
          return [...acc, val];
        }, []),
      );
    },
    [],
  );

  const handleSetOpened = useCallback(async () => {
    if (!opened) refetch();
    setOpened(prev => !prev);
  }, [refetch, opened]);

  const closeReportingModal = () => {
    setIsReportingModalActive(false);
    outputsReportForm.setValues(initialValues);
  };

  const handleSelectChange = (value: ConsumptionReportType) => {
    outputsReportForm.setFieldValue('reason', value);
    if (value !== ConsumptionReportType.CONNECTING_THE_CUSTOMER) {
      outputsReportForm.setErrors({});
    }
  };

  const mutation = useMutation(
    (item: object) => {
      return setupOutputs(devEui, item);
    },
    {
      onSuccess: () => {
        startSubmitting();
        handleSetOpened();
        outputsReportForm.setValues(initialValues);
        setIsReportingModalActive(false);
      },
    },
  );

  const submitOutputs = useCallback(() => {
    const item = {
      marinCode,
      channels: outputs,
    };
    const isOutputEnabled = !!outputs.find(
      (output, index) =>
        output.output === true &&
        output.output !== outputsInitState[index].output,
    );
    if (isOutputEnabled) {
      setIsReportingModalActive(true);
    } else {
      mutation.mutate(item);
    }
  }, [outputs, marinCode, mutation, outputsInitState]);

  const submitOutputsWithReport = useCallback(
    (values: IOutputsReport, event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const item = {
        marinCode,
        channels: outputs,
        ...values,
        transient: values.transient ? values.transient === 'yes' : null,
      };

      mutation.mutate(item);
    },
    [outputs, marinCode, mutation],
  );

  const rows = useMemo(() => {
    if (!opened) return null;

    return channels
      .sort((a, b) => Number(a.name) - Number(b.name))
      .map((channel, index) => {
        const { isSkipped, disconnectMonitoringControlActive } = channel;
        const isOutputSwitchEnabled =
          channel.currentMax < 250 || allowBigSocketControl;
        const isUnplugSwitchEnabled =
          channel.type === 'current' &&
          (channel.currentMax < 250 || allowBigSocketControl) &&
          disconnectMonitoringControlActive;

        return (
          <tr
            key={channel.name}
            className={isSkipped ? classes.skipped : undefined}>
            <td>
              <Text align="left">
                {channel.type === 'current'
                  ? t('labels.socket')
                  : t('labels.tap')}{' '}
                {channel.physicalName} ({channel.name})
              </Text>
            </td>
            <td>
              {isOutputSwitchEnabled && (
                <Switch
                  size="md"
                  checked={!!outputs[index].output}
                  id={outputs[index].channel}
                  data-key={Outputs.OUTPUT}
                  onChange={handleOutputSwitchChange}
                  color="accentBlue"
                  disabled={isSkipped}
                />
              )}
            </td>
            <td>
              {isUnplugSwitchEnabled && (
                <Switch
                  size="md"
                  checked={!!outputs[index].unplugDetection}
                  id={outputs[index].channel}
                  data-key={Outputs.UNPLUG}
                  onChange={handleOutputSwitchChange}
                  color="accentBlue"
                  disabled={isSkipped}
                />
              )}
            </td>
            <td>
              {isSkipped && (
                <Tooltip
                  width={220}
                  multiline
                  label={t('descriptions.skippedChannel')}
                  color="accentRed"
                  position="bottom"
                  events={{ hover: true, focus: true, touch: true }}
                  classNames={{ tooltip: classes.center }}>
                  <ActionIcon variant="transparent">
                    <IconAlertCircle
                      size={24}
                      color={theme.colors.accentRed[0]}
                    />
                  </ActionIcon>
                </Tooltip>
              )}
            </td>
          </tr>
        );
      });
  }, [
    t,
    channels,
    classes.center,
    classes.skipped,
    handleOutputSwitchChange,
    outputs,
    opened,
    theme.colors.accentRed,
    allowBigSocketControl,
  ]);

  return (
    <>
      <Button
        disabled={disabled}
        variant="outline"
        size="xs"
        uppercase
        onClick={handleSetOpened}
        color="accentCabaret">
        {t('labels.editOutputs')}
      </Button>
      <UpdateModal
        opened={deviceQueryState === 'submitting'}
        reset={clearSubmitting}
        title={t('labels.updatingOutputs')}
      />
      {deviceQueryState === 'submitting' && <OverlayLoader />}
      <Modal
        zIndex={1111}
        opened={opened}
        onClose={handleSetOpened}
        title={t('labels.editOutputs')}
        classNames={{ close: classes.close }}>
        <Title order={4}>{t('labels.channels')}</Title>
        {isRefetching || mutation.isLoading ? (
          <Center>
            <Loader my="md" color="accentBlue" />
          </Center>
        ) : (
          <>
            <Table my="md" className={classes.table}>
              <thead>
                <tr>
                  <th>{t('labels.channelNumber')}</th>
                  <th>{t('labels.output')}</th>
                  <th>{t('labels.unplug')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            <Button
              variant="light"
              size="sm"
              radius="xl"
              uppercase
              onClick={submitOutputs}
              color="accentCabaret">
              <Text color="white">{t('buttons.save')}</Text>
            </Button>
          </>
        )}
      </Modal>

      <Modal
        zIndex={1112}
        opened={isReportingModalActive}
        onClose={closeReportingModal}
        title={t('labels.switchingReason')}
        classNames={{ close: classes.close }}>
        {isRefetching || mutation.isLoading ? (
          <Center>
            <Loader my="md" color="accentBlue" />
          </Center>
        ) : (
          <>
            <form
              onSubmit={outputsReportForm.onSubmit(
                submitOutputsWithReport,
              )}
              noValidate>
              <Stack>
                <Select
                  defaultValue="customer"
                  data={[
                    {
                      value: ConsumptionReportType.CONNECTING_THE_CUSTOMER,
                      label: t('labels.customerReason'),
                    },
                    {
                      value: ConsumptionReportType.REPAIR_OR_TEST,
                      label: t('labels.repairReason'),
                    },
                    {
                      value: ConsumptionReportType.TECHNICAL_OR_SERVICE,
                      label: t('labels.technicalReason'),
                    },
                  ]}
                  classNames={{
                    error: classes.error,
                    invalid: classes.error,
                    item: classes.item,
                  }}
                  {...outputsReportForm.getInputProps('reason')}
                  onChange={handleSelectChange}
                  dropdownComponent={CustomDropdown}
                  // remove right section icon
                  rightSection={<></>}
                  icon={<IconChevronDown />}
                />
                <TextInput
                  label={t('labels.boatName')}
                  placeholder={t('labels.boatName')}
                  classNames={{
                    error: classes.error,
                    invalid: classes.error,
                    required: classes.required,
                  }}
                  required={isCustomerReason}
                  withasterisk={String(isCustomerReason)}
                  {...outputsReportForm.getInputProps('boatName')}
                />
                <TextInput
                  label={t('labels.berth')}
                  placeholder={t('labels.berth')}
                  classNames={{
                    error: classes.error,
                    invalid: classes.error,
                    required: classes.required,
                  }}
                  required={isCustomerReason}
                  withasterisk="true"
                  {...outputsReportForm.getInputProps('berth')}
                />
                <Radio.Group
                  label={t('labels.transient')}
                  classNames={{
                    error: classes.error,
                    invalid: classes.error,
                    required: classes.required,
                    root: classes.radioGroup,
                  }}
                  required={isCustomerReason}
                  withasterisk="true"
                  {...outputsReportForm.getInputProps('transient')}>
                  <Radio
                    value="yes"
                    label={t('labels.yes')}
                    classNames={{ radio: classes.radio }}
                  />
                  <Radio
                    value="no"
                    label={t('labels.no')}
                    classNames={{ radio: classes.radio }}
                  />
                </Radio.Group>
                <Textarea
                  label={t('labels.comment')}
                  placeholder={t('placeholders.enterComment')}
                  minRows={3}
                  {...outputsReportForm.getInputProps('comment')}
                />
              </Stack>
              <Space h="md" />
              <Button
                variant="light"
                size="sm"
                radius="xl"
                uppercase
                type="submit"
                color="accentCabaret">
                <Text color="white">{t('buttons.save')}</Text>
              </Button>
            </form>
          </>
        )}
      </Modal>
    </>
  );
};
