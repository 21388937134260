import React from 'react';
import { useMantineTheme, Title, Card, Switch, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface IDeviceSwitchProps {
  isActive: boolean;
  handleSwitch: () => void;
  label: string;
  disabled: boolean;
  errorMessage?: string;
}

export const DeviceSwitch: React.FC<IDeviceSwitchProps> = ({
  isActive,
  handleSwitch,
  label,
  disabled,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Card>
      <Title order={4}>{label}</Title>
      {errorMessage && (
        <Text color={theme.colors.accentRed[0]} size="xs">
          {errorMessage}
        </Text>
      )}
      <Switch
        mt="sm"
        checked={isActive}
        onChange={handleSwitch}
        label={t('buttons.enable')}
        size="lg"
        color="accentBlue"
        disabled={disabled}
      />
    </Card>
  );
};
