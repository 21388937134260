import React from 'react';
import { createStyles, Drawer, DrawerPosition } from '@mantine/core';

const useStyles = createStyles(theme => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    boxShadow: '3px 0px 8px rgba(10, 50, 111, 0.2)',
  },

  drawerTitle: {
    fontSize: '22px',
    fontWeight: 500,
    color: theme.colors.primary[0],
  },

  closeButton: {
    svg: {
      width: '24px',
      height: '24px',
    },
    color: theme.colors.primary[0],
  },
}));

interface ISidebarProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  size: string | number;
  position?: DrawerPosition;
  children: React.ReactNode;
}

export const Sidebar: React.FC<ISidebarProps> = ({
  opened,
  onClose,
  title,
  size,
  position = 'left',
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={title}
      padding="xl"
      size={size}
      position={position}
      classNames={{
        drawer: classes.drawer,
        title: classes.drawerTitle,
        closeButton: classes.closeButton,
      }}>
      {children}
    </Drawer>
  );
};
