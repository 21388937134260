import { IErrorArguments } from '../services/api/errors';
import { indexArgs } from '../constants/devices-data.constants';
import i18n from '../i18n';

export const getErrorDescription = (
  errCode: number,
  args: IErrorArguments<number>,
) => {
  const initArgs = { arg0: '', arg1: '', arg2: '' };
  const argsObj = args
    ? Object.keys(args).reduce(
        (
          acc: IErrorArguments<string>,
          val: keyof IErrorArguments<string>,
        ) => {
          if (args[val] === null) return acc;
          const shouldIncrement = indexArgs[errCode] === val;
          const argValue = shouldIncrement ? args[val] + 1 : args[val];
          return {
            ...acc,
            [val]: i18n.exists(`deviceErrors.${errCode}.${val}`)
              ? i18n.t(`deviceErrors.${errCode}.${val}`, {
                  arg: argValue,
                })
              : '',
          };
        },
        initArgs,
      )
    : initArgs;

  switch (errCode) {
    case 5: {
      return i18n.t(`deviceErrors.${errCode}.description`, {
        arg0:
          args &&
          args.arg0 !== null &&
          i18n.exists(`deviceErrors.${errCode}.arg0_${args.arg0}`)
            ? i18n.t(`deviceErrors.${errCode}.arg0_${args.arg0}`)
            : '',
      });
    }
    default:
      return i18n.t(`deviceErrors.${errCode}.description`, argsObj);
  }
};
