import React from 'react';
import {
  createStyles,
  ButtonVariant,
  Text,
  Group,
  Button,
  Modal,
} from '@mantine/core';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },

  label: {
    whiteSpace: 'pre-line',
  },

  centeredHeader: {
    justifyContent: 'center',
  },
}));

interface IModalButtonProps {
  variant: ButtonVariant;
  color: string;
  label: string;
  action: () => void;
}

interface IActionModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  label: string;
  leftButton: IModalButtonProps;
  rightButton: IModalButtonProps;
  withCloseButton?: boolean;
  centered?: boolean;
}

export const ActionModal: React.FC<IActionModalProps> = ({
  opened,
  onClose,
  title,
  label,
  leftButton,
  rightButton,
  withCloseButton = false,
  centered = false,
}) => {
  const { classes } = useStyles();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
      withCloseButton={withCloseButton}
      centered={centered}
      classNames={{
        close: classes.close,
        header: centered ? classes.centeredHeader : '',
      }}>
      <Text
        size={14}
        weight={400}
        className={classes.label}
        align={centered ? 'center' : 'initial'}>
        {label}
      </Text>
      <Group position="apart" mt="xl" noWrap>
        <Button
          variant={leftButton.variant}
          size="sm"
          radius="xl"
          uppercase
          onClick={leftButton.action}
          color={leftButton.color}>
          <Text size={14} weight={500} color="white">
            {leftButton.label}
          </Text>
        </Button>
        <Button
          variant={rightButton.variant}
          size="sm"
          radius="xl"
          uppercase
          onClick={rightButton.action}
          color={rightButton.color}>
          <Text size={14} weight={500} color="white">
            {rightButton.label}
          </Text>
        </Button>
      </Group>
    </Modal>
  );
};
