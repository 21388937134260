import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

export const useDeviceRefetch = () => {
  const queryClient = useQueryClient();

  const refetchDevice = useCallback(async () => {
    await queryClient.refetchQueries({ queryKey: ['getDevice'] });
  }, [queryClient]);

  return refetchDevice;
};
