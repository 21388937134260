import React, { useMemo } from 'react';
import {
  useMantineTheme,
  createStyles,
  Button,
  Text,
  SimpleGrid,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconWorld } from '@tabler/icons';
import { getFlag } from '../utils';

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    [`@media (min-width: 375px)`]: {
      gap: '18px',
    },
  },

  borderBottom: {
    borderBottom: `1px solid ${theme.colors.primary[0]}`,
  },

  button: {
    flexGrow: 1,
  },

  inner: {
    justifyContent: 'flex-start',
    fontFamily: 'Twemoji Country Flags',
  },

  label: {
    gap: '8px',
    fontSize: '18px',
  },

  icon: {
    alignSelf: 'flex-start',
    marginTop: '10px',
    marginRight: '8px',
  },
}));

export const SwitchLanguageMenu: React.FC = () => {
  const theme = useMantineTheme();
  const { i18n, t } = useTranslation();
  const { classes, cx } = useStyles();

  const languages = useMemo(
    () => Object.keys(i18n.options.resources).sort(),
    [i18n.options.resources],
  );

  const handleLanguageChange = (lng: string) => i18n.changeLanguage(lng);

  return (
    <div className={classes.container}>
      <IconWorld
        size={20}
        stroke={1.5}
        className={classes.icon}
        color={theme.colors.primary[0]}
      />
      <SimpleGrid cols={2} spacing={4}>
        {languages.map(lng => {
          const [text, code] = t('code', { lng }).split('_');
          return (
            <Button
              key={lng}
              onClick={() => handleLanguageChange(lng)}
              variant="subtle"
              classNames={{
                label: classes.label,
                inner: classes.inner,
              }}
              className={cx(
                classes.button,
                i18n.language === lng && classes.borderBottom,
              )}
              px={8}
              radius={0}>
              {getFlag(code)}
              <Text
                transform="uppercase"
                weight={400}
                color="primary"
                size={16}>
                {text}
              </Text>
            </Button>
          );
        })}
      </SimpleGrid>
    </div>
  );
};
