import { api } from '.';
import { DeviceTypes, IDeviceOutputs } from '../../types/device';
import { ENDPOINTS } from './endpoints';

export interface IChannel {
  type: string;
  name: string;
  physicalName: string;
  isActive: boolean;
  isSkipped: boolean;
  pulse: number;
  impulses: number;
  currentMax: number;
  currentVoltage: number;
  modBus: number;
  scanModBus: boolean;
  replacingMeterInProgress: boolean;
  disconnectDetected: boolean;
  disconnectMonitoringActive: boolean;
  disconnectMonitoringControlActive: boolean;
  output: boolean;
  meterStatus: number;
  createdAt: string;
  updatedAt: string;
}

export interface IGeo {
  marinCode: string;
  lat: number;
  lng: number;
}

export interface IDeviceStatus {
  marinCode: string;
  status: boolean;
}

export interface IPedestalQr {
  marinCode: string;
  pedestalQr: string;
}

export interface IEui {
  marinCode: string;
  newDevEui: string;
}

export interface ICounterData {
  marinCode: string;
  pulse: number;
  meterStatus: number;
  currentMax: number;
  currentVoltage: number;
  isActive: boolean;
}

export interface IChannelState {
  marinCode: string;
  status: boolean;
}

export interface IOutputs {
  marinCode: string;
  channels: IDeviceOutputs[];
}

export interface IScanSetup {
  marinCode: string;
  channels: {
    channel: string;
    scanModBus: boolean;
  }[];
}

export interface IStartReplacingCounterData {
  marinCode: string;
  channel: string;
}

export interface IFinishReplacingCounterData {
  marinCode: string;
  channel: string;
  currentMax: number;
  currentVoltage: number;
}

export interface IPhysicalName {
  marinCode: string;
  physicalName: string;
}

export interface IDevice {
  name: string;
  devEui: string;
  pedestalQr: string;
  lat: number;
  lng: number;
  dr: number;
  sf: number;
  rssi: number;
  hw: string;
  tmp: number;
  fw: string;
  testMode: boolean;
  pmTags: boolean;
  hasErrors: boolean;
  type: DeviceTypes;
  createdAt: string;
  updatedAt: string;
  lastSyncAt: string;
  allowBigSocketControl: boolean;
  channels: IChannel[];
}
export interface IDevices {
  devices: IDevice[];
}

interface IRegistry {
  deviceRegistry: {
    id: number;
    testerId: number;
    lotNumber: string;
    serialNumber: string;
    model: string;
    devEui: string;
    rev: string;
    frequency: string;
    interface: string;
    formalName: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    fw: string;
    lot: {
      number: string;
      productionAt: string;
      vol: string;
      faultyPieces: string;
      fail: string;
      testerRev: string;
      pcbSupplier: string;
      staff: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    };
  };
}

export const addDevice = (data: object) => {
  return api.post<IDevices>(`${ENDPOINTS.DEVICES}`, data);
};

export const getDevice = (mar: string) => {
  return api.get<IDevices>(`${ENDPOINTS.DEVICES}/list${mar}`);
};

export const updatePedestalQr = (devEui: string, data: object) => {
  return api.put<IPedestalQr>(
    `${ENDPOINTS.DEVICES}/${devEui}/set-pedestal-qr`,
    data,
  );
};

export const updateEui = (devEui: string, data: object) => {
  return api.put<IEui>(
    `${ENDPOINTS.DEVICES}/${devEui}/change-dev-eui`,
    data,
  );
};

export const replaceCounter = (
  devEui: string,
  channelName: string,
  data: object,
) => {
  return api.put<ICounterData>(
    `${ENDPOINTS.DEVICES}/${devEui}/channel/${channelName}`,
    data,
  );
};

export const changeChannelState = (
  devEui: string,
  channelName: string,
  data: object,
) => {
  return api.put<IChannelState>(
    `${ENDPOINTS.DEVICES}/${devEui}/channel/${channelName}/set-active`,
    data,
  );
};

export const changePhysicalName = (
  devEui: string,
  channelName: string,
  data: object,
) => {
  return api.put<IChannelState>(
    `${ENDPOINTS.DEVICES}/${devEui}/channel/${channelName}/set-physical-name`,
    data,
  );
};

export const setupOutputs = (devEui: string, data: object) => {
  return api.put<IOutputs>(
    `${ENDPOINTS.DEVICES}/${devEui}/setup-outputs`,
    data,
  );
};

export const setupScan = (devEui: string, data: IScanSetup) => {
  return api.put<IScanSetup>(
    `${ENDPOINTS.DEVICES}/${devEui}/scan-mod-bus`,
    data,
  );
};

export const startReplacingCounter = (
  devEui: string,
  data: IStartReplacingCounterData,
) => {
  return api.put<IStartReplacingCounterData>(
    `${ENDPOINTS.DEVICES}/${devEui}/start-replacing-counter`,
    data,
  );
};

export const finishReplacingCounter = (
  devEui: string,
  data: IFinishReplacingCounterData,
) => {
  return api.put<IFinishReplacingCounterData>(
    `${ENDPOINTS.DEVICES}/${devEui}/finish-replacing-counter`,
    data,
  );
};

export const updateGeo = (devEui: string, data: object) => {
  return api.put<IGeo>(`${ENDPOINTS.DEVICES}/${devEui}/geo`, data);
};

export const switchPMTags = (devEui: string, data: IDeviceStatus) => {
  return api.put<IDeviceStatus>(
    `${ENDPOINTS.DEVICES}/${devEui}/switch-pm-tags`,
    data,
  );
};

export const switchTestMode = (devEui: string, data: IDeviceStatus) => {
  return api.put<IDeviceStatus>(
    `${ENDPOINTS.DEVICES}/${devEui}/test-mode`,
    data,
  );
};

export const addNewChannel = (devEui: string, data: object) => {
  return api.post<IChannel>(
    `${ENDPOINTS.DEVICES}/${devEui}/channel`,
    data,
  );
};

export const removeDevice = (marine: string, devEui: string) => {
  return api.delete(`${ENDPOINTS.DEVICES}/${marine}/${devEui}`);
};

export const removeChannel = (
  marine: string,
  devEui: string,
  channelName: string,
) => {
  return api.delete(
    `${ENDPOINTS.DEVICES}/${marine}/${devEui}/${channelName}`,
  );
};

export const getRegistry = (devEui: string) => {
  return api.get<IRegistry>(
    `${ENDPOINTS.DEVICES}/registry?devEui=${devEui}`,
  );
};
