import { useEffect, useState } from 'react';
import {
  CHANNEL_TYPE,
  CHANNELS_MAX_LENGTH,
} from '../constants/channels-data.constants';
import { DeviceTypes } from '../types/device';
import { IChannel } from '../services/api/devices';
import {
  getAvailableIndex,
  checkIsMaxAmountReached,
  getActiveElements,
} from '../utils';

export const useChannels = (
  channels: IChannel[],
  channelsType: string,
  deviceType: DeviceTypes,
  isEditModeActive = false,
) => {
  const activeChannels = getActiveElements(
    channels,
    channelsType,
    isEditModeActive,
    'type',
    'name',
  );
  const isMaxAmountReached = checkIsMaxAmountReached(
    deviceType,
    channels.length,
    activeChannels.length,
  );
  const [nextIndex, setNextIndex] = useState<number>(1);
  const occupiedIndexes = channels.map(channel => channel.name);
  const occupiedSockets = channels.map(channel => channel.physicalName);
  const electricityIndexes = channels
    .reduce((acc, val) => {
      if (val.type === CHANNEL_TYPE.ELECTRICITY) {
        return [
          ...acc,
          {
            value: Number(val.name),
            label: val.name,
          },
        ];
      }
      return acc;
    }, [])
    .sort((a, b) => a.value - b.value);

  useEffect(() => {
    if (deviceType === DeviceTypes.MM_E) {
      setNextIndex(
        getAvailableIndex(occupiedIndexes, CHANNELS_MAX_LENGTH.MM_E),
      );
    }
    if (
      deviceType === DeviceTypes.MM_E_8 ||
      deviceType === DeviceTypes.MM_E_PM
    ) {
      const nextNumber = activeChannels.length + 1;
      if (channelsType === CHANNEL_TYPE.ELECTRICITY) {
        setNextIndex(nextNumber);
      }
      if (channelsType === CHANNEL_TYPE.WATER) {
        setNextIndex(CHANNELS_MAX_LENGTH.MM_E_8 + nextNumber);
      }
    }
  }, [deviceType, occupiedIndexes, channelsType, activeChannels.length]);

  return {
    activeChannels,
    isMaxAmountReached,
    nextIndex,
    occupiedSockets,
    electricityIndexes,
  };
};
