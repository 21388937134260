import { api } from '.';
import { ENDPOINTS } from './endpoints';

export interface IMarinas {
  items: [
    {
      code: 'MD02';
      name: 'Dalmacijy';
    },
  ];
}
export const getMarinas = () => api.get<IMarinas>(ENDPOINTS.MARINAS.LIST);
