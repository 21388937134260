export const tr = {
  code: 'tr_tr',
  labels: {
    email: 'E-Mail Adresi',
    password: 'Şifre',
    channels: 'Kanallar',
    device: 'Cihaz EUI',
    pedestalQr: 'Servis Kutusu QR Kodu',
    pedestalName: 'Servis Kutusu Adı',
    scanCode: 'Tarama Kodu',
    addChannel: 'Kanal Ekle',
    editChannel: 'Kanalı Düzenle',
    startReplacingCounter: 'Start replacing the counter',
    finishReplacingCounter: 'Finish replacing the counter',
    removeChannel: 'Kanalı Kaldır',
    removeDevice: 'Cihazı Kaldır',
    channelName: 'Kanal Adı',
    pulse: 'Darbe sayısı / kWh',
    pulseWater: 'Darbe sayısı / [m3]',
    maximumCurrent: 'Maksimum Akım [A]',
    voltage: 'Gerilim [VAC]',
    meterStatus: 'Sayaç Durumu [kWh]',
    meterStatusWater: 'Sayaç Durumu [m3]',
    addDevice: 'Cihaz Ekle',
    editDevice: 'Cihaz Düzenle',
    remove: 'Kaldır',
    lastSeen: 'Son Okunan',
    never: 'Hiçbir Zaman',
    appVersion: 'Uygulama Versiyonu',
    attention: 'Dikkat!',
    testMode: 'Test Modu',
    socketNumber: 'Priz Numarası',
    tapNumber: 'Musluk numarası',
    channelNumber: 'Numara (kanal)',
    output: 'Çıkış gücü',
    unplug: 'Prizi çıkar',
    editOutputs: 'Çıkış gücünü düzenle',
    findDevice: 'Cihazı bulmak için kodu taratınız',
    modbusAddress: 'MODBUS Adresi',
    linkedChannel: 'Bağlantılı elektrik kanalı',
    scanModBus: `MODBUS'u tarat`,
    updatingOutputs: 'Çıkış gücü güncelleniyor',
    updatingPMTags: 'PM etiketi güncelleniyor',
    scanRequest: 'Tarama iste',
    deviceErrors: 'Cihaz hatası',
    createdAt: 'Oluşturuldu',
    code: 'Kod',
    description: 'Tanımlama',
    resolvedAt: 'Çözümlendi',
    resolvedBy: 'Tarafından çözümlendi',
    autoResolved: 'Otomatik olarak çözüldü',
    fixError: 'Hatayı nasıl gideririm?',
    solution: 'Çözüm',
    pmTags: 'PM Etkieti',
    linkedTap: 'Bağlı musluk',
    linkedSocket: 'Bağlı priz',
    addingChannel: 'Yeni kanal ekleniyor',
    updatingCounter: 'Sayaç güncelleniyor',
    profile: 'Profil',
    marinas: 'Marinalar',
    changePassword: 'Şifreni değiştir',
    oldPassword: 'Eski şifre',
    newPassword: 'Yeni şifre',
    confirmNewPassword: 'Yeni şifreyi onayla',
    socket: 'Socket',
    tap: 'Tap',
    switchingReason: 'Reason for switching on the tap/socket',
    customerReason: 'Connecting the customer',
    repairReason: 'Repair / test',
    technicalReason: 'Technical / service boat',
    boatName: 'Boat name',
    berth: 'Berth',
    transient: 'Transient',
    yes: 'Yes',
    no: 'No',
    comment: 'Comment',
  },
  descriptions: {
    removeChannel: 'Kanalı kaldırmak istediğinizden emin misiniz?',
    removeDevice: 'Cihazı kaldırmak istediğinizden emin misiniz? ',
    changesNotSaved: 'Değişiklik kaydedilmedi.',
    leaveConfirmation: 'Çıkmak istediğinizden emin misiniz?',
    enableLocation:
      'Cihazın yerini belirlemek için konumunuza erişim gerekli. Lütfen tarayıcı ayarlarınızdan izni etkinleştirin.',
    typeChangeModal:
      'Cihaz tipini değiştirmek istediğinizden emin misiniz? Bütün kanal bilgileri silinecek',
    changeSocketNumber:
      'Priz numarasını değiştirmek istediğinizden emin misiniz?',
    confirmChannelEnable: 'Bu kanalı açmak istediğinizden emin misiniz?',
    confirmChannelDisable:
      'Bu kanalı kiltlemek istediğinizden emin misiniz?',
    confirmPMTagsEnable: 'Are you sure to enable PM Tags?',
    confirmPMTagsDisable: 'Are you sure to disable PM Tags?',
    updating: 'Bu işlem 3 dakikaya kadar sürebilir',
    scanModBus: 'Bu kanal için MODBUS adresi taratmak istiyorum',
    wrongFirmwareForTestMode:
      'Please update the devices firmware before enabling test mode.',
    skippedChannel:
      'Refresh data or check the physical connection of the socket in the pedestal.',
    startReplaceCounter:
      'Are you sure you want to start replacing the counter?',
  },
  placeholders: {
    email: 'email@example.com',
    password: '********',
    search: 'Ara',
    pier: 'İskele',
    pedestal: 'Servis Kutusu',
    chooseCamera: 'Kamerayı seç',
    oldPassword: 'Eski şifre',
    newPassword: 'Yeni şifre',
    confirmNewPassword: 'Yeni şifreyi onayla',
    enterComment: 'Enter a comment',
  },
  buttons: {
    login: 'Giriş',
    logout: 'Çıkış',
    back: 'Geri',
    refresh: 'Yenile',
    save: 'Kaydet',
    remove: 'Kaldır',
    addChannel: 'Kanal Ekle',
    enable: 'Aktif et',
    lock: 'Kilitle',
    electricity: 'Elektrik',
    water: 'Su',
    add: 'Ekle',
    replace_device: 'MM-E Değiştir',
    replace_counter: 'Sayaç Değiştir',
    replace_pedestal_code: 'QR Kodu değiştir',
    savePosition: 'Yeni Konumu Kaydet',
    cancel: 'İptal',
    hide: 'Gizle',
    confirm: 'Onayla',
    getPosition: 'Konum bul',
    resolve: 'Çözümlendi',
    startScan: 'Taramayı başlat',
    stopScan: 'Taramayı durdur',
    scanImage: 'Görüntüyü tara',
    supportRequest: 'Destek talebi gönder',
    profile: 'Profil',
    marinas: 'Marinalar',
    start: 'Start',
    finish: 'Finish',
  },
  legend: {
    title: 'Açıklama Yazısı',
    neverSeen: 'Cihaz Görülmedi',
    notSynced: 'Cihaz son 20 dakikadır görülmedi',
    hasErrors: 'Cihazda çözülmemiş hatalar var (müdahale gerekli)',
  },
  notifications: {
    addedDevice: 'Cihaz Eklendi  👍',
    updatedGeo: 'Konum Güncellendi  👍',
    updatedEui: 'EUI Cihaz Güncellendi 👍',
    updatedPedestalQr: 'Servis Kutusu QR Kodu güncellendi 👍',
    replacedCounter: 'Sayaç Değiştirildi 👍',
    startedReplaceCounter: 'Started replacing counter 👍',
    replacedCounterNewValue:
      'New meter status: <strong>{{meter_status}} {{unit}}</strong>',
    addedChannel: 'Kanal Eklendi 👍',
    removedChannel: 'Kanal Kaldırıldı 👍',
    changedChannelState: 'Kanal durumu değişti 👍',
    removedDevice: 'Cihaz Kaldırıldı 👍',
    invalidValues: 'Geçersiz Değer ⚡',
    updatedOutputs: 'Cihaz çıkış gücü doğru şekilde ayarlandı',
    error: 'Hata 🤥',
    success: 'Başarılı',
    errorMessage: 'Yanlış İşlem Yürütüldü',
    tryAgain: 'Lütfen yeniden deneyin',
    notSeenOnSubmit:
      'Cihaz görünmüyor. Verinin düzgün olarak kaydedildiğinden emin olun.',
    invalidDeviceName: 'Cihaz Adı Alanı Geçersiz. Lütfen Alanı Doldurunuz',
    invalidPedestalQrLength:
      'Servis Kutusu QR kodu en az 6 karakterden oluşmalı',
    enableLocation:
      'Coğrafi konumunuza erişim iznini etkinleştirmelisiniz',
    testModeEnabled: 'Test Modu etkinleştirildi',
    testModeDisabled: 'Test Modu devre dışı',
    pmTagsEnabled: 'PM etiketi şimdi etkinleştirildi',
    pmTagsDisabled: 'PM etiketi şimdi devre dışı',
    deviceNotFound: 'Cihaz bulunamadı',
    typeNotSupported: 'Bu tip desteklenmiyor',
    preventTypeChange: `Cihaz tipini değiştiremezsiniz`,
    socketNumbersUnique: 'Priz numarası farklı olmalı',
    addDeviceNow: 'Şimdi ekleyebilirsiniz!',
    uniqueLinks:
      'Elektrik ve su kanalları arasındaki bağlantı farklı olmalı',
    scanRequested:
      'Tarama isteği gönderildi. Scanning may take up to 10 minutes.',
    errorResolved: 'Hata giderildi 👍',
    qrNotFound: 'QR kod bulunamadı',
    supportRequest: 'Talebiniz teknik destek birimine iletildi',
    passwordChanged: 'Şifre başarıyla değiştirildi',
  },
  errors: {
    devEui: 'EUI boş olmamalı',
    channels: 'Kanal en azından 1 öğe içermeli',
    newDevEui: 'Yeni Cihaz EUI boş olmamalı',
    pulse: 'Darbe sayısı tanımsız olmamalı',
    meterStatus: 'Sayaç durumu tanımsız olmamalı',
    userNotFound: 'Kullanıcı bulunamadı',
    wrongPassword: 'Şifre Geçersiz',
    physicalName: 'Fiziksel isim değeri geçersiz',
    pedestalQr: 'Servis Kutusu QR kodu geçersiz',
    modBus: 'MODBUS değeri geçersiz',
  },
  validations: {
    invalid: 'Geçersiz Değer',
    required: 'Alanı Doldurmanız Gerekli',
    email: 'Geçersiz E-mail Adresi',
    wrongMarina: 'Taranan QR kodu başka bir marinaya ait',
    wrongEui: 'EUI doğru değil',
    wrongPedestalQr: 'Bu kod doğru değil',
    wrongPedestalName: 'Taranmış servis kutusu ismi doğru değil',
    passwordLength: 'Şifre en az 8 karakter içermeli',
    strongPassword:
      'Şifre en az 1 büyük harf, 1 rakam ve 1 özel karakter içermeli',
    samePasswords: 'Şifre aynı olmalı',
  },
  notFound: {
    title: 'Yetkiniz dışında bir yer buldunuz ',
    description: `Adres Bulunamadı. Yanlış adres girmiş olabilirsiniz veya sayfa başka bir URL'ye taşınmış olabilir`,
    button: 'Ana Sayfaya Dön',
  },
  deviceErrors: {
    1: {
      description: 'Yüklenen cihaz yazılımı geçersiz',
      instruction: 'Cihazı değiştir',
    },
    2: {
      description: 'İndirilen cihaz yazılımı geçersiz',
      instruction: `FUOTA'yı cihaz için doğru donanım sürümüyle yapılandır`,
    },
    3: {
      description: 'MODBUS communication error for channel no. {{arg0}}',
      arg0: 'Kanal numarası {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    4: {
      description: '{{arg0}} için MODBUS tarama hatası {{arg1}}',
      arg0: 'Kanal numarası {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    5: {
      description: 'Güvenlik gözetimi tarafından sıfırlandı {{arg0}}',
      arg0_0: '(IWDG güvenlik gözetimi)',
      arg0_1: '(WWDG güvenlik gözetimi)',
    },
    6: {
      description: 'User credits overwritten {{arg0}} {{arg1}}',
      arg0: '(number of credits: {{arg}}).',
      arg1: 'No. of the channel for which the credits have been overwritten: {{arg}}',
      instruction:
        'Verify if overwritten credits should be returned to the customer. Report the situation to the office for verification.',
    },
    7: {
      description: 'The device has lost communication',
    },
    8: {
      description: 'Replace or update the Plus {{arg0}}}',
      arg0: 'socket no. {{arg}}',
      instruction:
        'Replace the socket with one with firmware 1.29 or update the socket firmware to 1.29.',
    },
    defaultSolution:
      'Sorunu çözmekte sorun yaşıyorsanız lütfen teknik destek birimiyle iletişim kurunuz',
  },
};
