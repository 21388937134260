import i18n from 'i18next';

const singleCharacterRegex = /./g;
const LetterAUnicodePosition = 65;
const LetterARegionUnicodePosition = 127462;
const FlagEmojiEntryPosition =
  LetterARegionUnicodePosition - LetterAUnicodePosition;

export const getFlag = (countryCode: string) => {
  const upperCasedCountryCode = countryCode?.toUpperCase();
  const countryCodes = Object.keys(i18n.options.resources).map(lng => {
    const [, code] = i18n.t('code', { lng }).split('_');
    return code.toUpperCase();
  });
  const isValid = countryCodes.includes(upperCasedCountryCode);

  if (!isValid) return null;

  const flagEmoji = upperCasedCountryCode.replace(
    singleCharacterRegex,
    char =>
      String.fromCodePoint(FlagEmojiEntryPosition + char.charCodeAt(0)),
  );

  return flagEmoji;
};
