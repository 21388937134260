import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  createStyles,
  useMantineTheme,
  ActionIcon,
  Text,
  Divider,
  Group,
  Switch,
  Button,
  Modal,
  SelectItem,
  Badge,
  Menu,
} from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { IconTrashX, IconCheck, IconAlertCircle } from '@tabler/icons';
import {
  IChannel,
  replaceCounter,
  startReplacingCounter,
  IStartReplacingCounterData,
  finishReplacingCounter,
  IFinishReplacingCounterData,
} from '../../../../services/api/devices';
import { ChannelInputs } from './channel-inputs';
import { ActionModal } from '../../../../components/action-modal';
import { CHANNEL_TYPE } from '../../../../constants/channels-data.constants';
import { ChangeChannelStateModal } from './change-channel-state-modal';
import { DeviceTypes } from '../../../../types/device';
import { useDeviceQuery } from '../../../../hooks/useDeviceQuery';
import { UpdateModal } from '../../../../components/update-modal';
import { OverlayLoader } from '../../../../components/atoms/overlay-loader';

const useStyles = createStyles(theme => ({
  grow: {
    flexGrow: 1,
  },

  close: {
    color: theme.colors.primary[0],
  },
}));

interface ISingleChannelProps {
  channel: IChannel;
  onChange: (channel: IChannel, index?: number) => void;
  onDelete?: (index: string) => void;
  setFormErrors: Dispatch<SetStateAction<object>>;
  deviceType: DeviceTypes;
  isDeviceSynced: boolean;
  isLastChannel?: boolean;
  isEditModeActive?: boolean;
  isTestModeActive?: boolean;
  isAddingChannelInModal?: boolean;
  channelsType?: string;
  occupiedSockets?: string[];
  electricityIndexes: SelectItem[];
  linkedChannel?: IChannel;
}

export const SingleChannel: React.FC<ISingleChannelProps> = ({
  channel,
  onChange,
  onDelete,
  setFormErrors,
  isLastChannel,
  isEditModeActive,
  isTestModeActive,
  isAddingChannelInModal,
  channelsType,
  deviceType,
  occupiedSockets,
  electricityIndexes,
  linkedChannel,
  isDeviceSynced,
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [opened, setOpened] = useState(false);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const formInitialValues = {
    name: channel.name,
    type: channel.type,
    isActive: channel.isActive,
    pulse: channel.pulse,
    currentMax: channel.currentMax,
    currentVoltage: channel.currentVoltage,
    meterStatus: channel.meterStatus,
    physicalName: channel.physicalName,
    modBus: channel.modBus,
    scanModBus: channel.scanModBus,
  };
  const devEui = searchParams.get('devEui');
  const marinCode = searchParams.get('marinCode');
  const [isChannelsChanged, setIsChannelsChanged] =
    useState<boolean>(false);
  const [isSwitchClicked, setIsSwitchClicked] = useState<boolean>(false);
  const isPMWaterChannel =
    deviceType === DeviceTypes.MM_E_PM &&
    channel.type === CHANNEL_TYPE.WATER;
  const [lastMeterStatus, setLastMeterStatus] = useState<number>();
  const isPMDevice = deviceType === DeviceTypes.MM_E_PM;

  const valuesValidation = {
    pulse: value =>
      Number.isNaN(value) ? t('validations.invalid') : null,
    meterStatus: value =>
      Number.isNaN(value) ? t('validations.invalid') : null,
  };

  const form = useForm({
    validateInputOnChange: true,
    initialValues: formInitialValues,
    validate: valuesValidation,
  });

  const editForm = useForm({
    validateInputOnChange: true,
    initialValues: form.values,
    validate: valuesValidation,
  });

  const compareMeterStatuses = (resStatus: number) => {
    // check if meterStatus changed in replace counter request
    if (editForm.values.meterStatus !== lastMeterStatus) {
      return (
        // check if previous meterStatus is different than value from response
        // also check if response value is equal or greater as value provided in replace counter form
        // these two conditions are confirmation if counter was replaced correctly
        lastMeterStatus !== resStatus &&
        editForm.values.meterStatus <= resStatus
      );
    }
    return editForm.values.meterStatus <= resStatus;
  };
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [wasLastUpdateSuccessful, setWasLastUpdateSuccessful] =
    useState(false);

  const {
    query: { refetch, data },
    deviceQueryState,
    startSubmitting,
    clearSubmitting,
  } = useDeviceQuery(res => {
    if (deviceQueryState === 'submitting') {
      const resChannel = res.channels.find(i => i.name === channel.name);
      const tolerance = 99;
      const resStatus = resChannel.impulses / resChannel.pulse;
      const resStatusWithTolerance =
        (resChannel.impulses + tolerance) / resChannel.pulse;

      if (
        res.lastSyncAt !== data.lastSyncAt &&
        editForm.values.meterStatus >= resStatus &&
        editForm.values.meterStatus <= resStatusWithTolerance
      ) {
        clearSubmitting();
        if (compareMeterStatuses(resStatusWithTolerance)) {
          setWasLastUpdateSuccessful(true);
          showNotification({
            title: t('notifications.replacedCounter'),
            message: t('notifications.success'),
            color: 'teal.8',
            icon: <IconCheck size={18} />,
          });
        } else {
          setWasLastUpdateSuccessful(false);
          showNotification({
            title: t('notifications.error'),
            message: `${t('notifications.errorMessage')}. ${t(
              'notifications.tryAgain',
            )}`,
            color: 'accentRed',
            icon: <IconAlertCircle size={18} />,
          });
        }
      }
    }
  });

  const closeUpdateModal = useCallback(() => {
    clearSubmitting();
    refetch();
    setUpdateModalVisible(false);
  }, [clearSubmitting, refetch]);

  useEffect(() => {
    if (deviceQueryState === 'submitting') {
      setUpdateModalVisible(true);
    }
  }, [deviceQueryState]);

  const saveData = form.onSubmit(values => {
    if (isEditModeActive) return;
    onChange(values as IChannel);
  });

  const handleSetOpened = () => {
    setOpened(prev => !prev);
    editForm.setValues(form.values);
  };

  const handleReplaceCounter = useMutation(
    (item: object) => {
      if (linkedChannel) {
        const {
          pulse,
          meterStatus,
          currentMax,
          currentVoltage,
          isActive,
        } = linkedChannel;
        replaceCounter(devEui, linkedChannel.name, {
          pulse,
          meterStatus,
          currentMax,
          currentVoltage,
          isActive,
          marinCode,
          modBus: editForm.values.modBus,
        });
      }

      return replaceCounter(devEui, channel.name, item);
    },
    {
      onSuccess: () => {
        setOpened(false);
        if (!isDeviceSynced) {
          refetch();
          showNotification({
            title: isDeviceSynced
              ? t('notifications.replacedCounter')
              : t('notifications.notSeenOnSubmit'),
            message: t('notifications.success'),
            color: isDeviceSynced ? 'teal.8' : 'accentBlue',
            icon: <IconCheck size={18} />,
          });
          form.setValues(editForm.values);
        } else {
          startSubmitting();
        }
      },
    },
  );

  const handleSaveEdit = () => {
    const {
      pulse,
      meterStatus,
      currentMax,
      currentVoltage,
      isActive,
      modBus,
    } = editForm.values;
    const meterStatusPulses =
      Number(meterStatus.toFixed(2)) * Math.round(pulse);
    const item = {
      marinCode,
      pulse: Math.round(pulse),
      meterStatus: meterStatusPulses,
      currentMax,
      currentVoltage,
      isActive,
      modBus,
    };
    setLastMeterStatus(channel.meterStatus);
    handleReplaceCounter.mutate(item);
  };

  const handleStartReplacingCounter = useMutation(
    (item: IStartReplacingCounterData) => {
      return startReplacingCounter(devEui, item);
    },
    {
      onSuccess: () => {
        setOpened(false);
        refetch();
        showNotification({
          title: t('notifications.startedReplaceCounter'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      },
    },
  );

  const handleFinishReplacingCounter = useMutation(
    (item: IFinishReplacingCounterData) => {
      return finishReplacingCounter(devEui, item);
    },
    {
      onSuccess: () => {
        setOpened(false);
        refetch();
        showNotification({
          title: t('notifications.replacedCounter'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      },
    },
  );

  const confirmStartReplacingCounter = () => {
    const item = {
      marinCode,
      channel: channel.name,
    };
    handleStartReplacingCounter.mutate(item);
  };

  const confirmFinishReplacingCounter = () => {
    const { currentMax, currentVoltage } = editForm.values;
    const item = {
      marinCode,
      channel: channel.name,
      currentMax,
      currentVoltage,
    };
    handleFinishReplacingCounter.mutate(item);
  };

  const handleSetRemoveModalOpened = useCallback(() => {
    if (isTestModeActive) return;
    setRemoveModalOpened(prev => !prev);
  }, [isTestModeActive]);

  const handleRemoveChannel = useCallback(() => {
    setRemoveModalOpened(false);
    onDelete(channel.name);
  }, [onDelete, channel.name]);

  const handleSetFormErrors = useCallback(() => {
    setFormErrors(form.errors);
  }, [form.errors, setFormErrors]);

  const handleChangeSwitchState = useCallback(
    (changeSwitchState = true) => {
      setIsSwitchClicked(prev => !prev);
      if (changeSwitchState) {
        form.setFieldValue('isActive', !form.values.isActive);
      }
    },
    [form],
  );

  const onSwitchClick = useCallback(() => {
    handleChangeSwitchState();
  }, [handleChangeSwitchState]);

  // changes form values if current channels type has changed
  useDidUpdate(() => {
    if (!isAddingChannelInModal && isChannelsChanged) {
      form.setValues(formInitialValues);
      setIsChannelsChanged(false);
    }
  }, [
    isAddingChannelInModal,
    isChannelsChanged,
    form,
    formInitialValues,
    setIsChannelsChanged,
  ]);

  useEffect(() => {
    setIsChannelsChanged(true);
  }, [channelsType, channel]);

  const getTextAfterUpdate = useMemo(() => {
    const title = wasLastUpdateSuccessful
      ? t('notifications.replacedCounter')
      : t('notifications.error');
    const description = wasLastUpdateSuccessful ? (
      <Trans
        t={t}
        values={{
          unit: channel.type === CHANNEL_TYPE.WATER ? 'm3' : 'kWh',
          meter_status: Number(channel.impulses / channel.pulse)
            .toFixed(2)
            .replace(/\./g, ','),
        }}
        i18nKey="notifications.replacedCounterNewValue"
        components={{
          strong: <strong />,
        }}
      />
    ) : (
      t('notifications.errorMessage')
    );

    return {
      title,
      description,
    };
  }, [
    channel.impulses,
    channel.pulse,
    channel.type,
    t,
    wasLastUpdateSuccessful,
  ]);

  const getReplaceCounterModalTitle = () => {
    if (isPMDevice)
      return channel.replacingMeterInProgress
        ? t('labels.finishReplacingCounter')
        : t('labels.startReplacingCounter');
    return t('labels.editChannel');
  };

  const renderReplaceCounterButton = () => {
    if (isPMDevice) {
      return !isPMWaterChannel ? (
        <Menu width={200}>
          <Menu.Target>
            <Button
              disabled={isTestModeActive}
              variant="outline"
              size="xs"
              uppercase
              color="accentCabaret">
              {t('buttons.replace_counter')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={handleSetOpened}
              disabled={channel.replacingMeterInProgress}>
              {t('buttons.start')}
            </Menu.Item>
            <Menu.Item
              onClick={handleSetOpened}
              disabled={!channel.replacingMeterInProgress}>
              {t('buttons.finish')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : null;
    }

    return (
      <Button
        disabled={isTestModeActive}
        variant="outline"
        size="xs"
        uppercase
        onClick={handleSetOpened}
        color="accentCabaret">
        {t('buttons.replace_counter')}
      </Button>
    );
  };

  return (
    <>
      <form
        onSubmit={e => e.preventDefault()}
        onBlur={saveData}
        onChange={handleSetFormErrors}>
        <Group position="apart" pb="lg">
          <Text size="sm">
            {t('labels.channelName')} {channel.name}
          </Text>
          <Divider my="sm" className={classes.grow} />
        </Group>
        <Group position="apart" pb="md">
          <Switch
            label={t('buttons.lock')}
            size="lg"
            color={
              channel.type === CHANNEL_TYPE.WATER
                ? 'accentBlue'
                : 'accentYellow'
            }
            disabled={isTestModeActive}
            onChange={onSwitchClick}
            checked={!form.values.isActive}
          />
          <Group
            position={
              isEditModeActive && isLastChannel && !isPMWaterChannel
                ? 'apart'
                : 'right'
            }
            className={classes.grow}>
            {isEditModeActive && renderReplaceCounterButton()}
            {isLastChannel && (
              <>
                <ActionIcon onClick={handleSetRemoveModalOpened}>
                  <IconTrashX
                    size="20"
                    color={
                      isTestModeActive
                        ? theme.colors.gray[2]
                        : theme.colors.primary[0]
                    }
                  />
                </ActionIcon>
                <ActionModal
                  opened={removeModalOpened}
                  onClose={handleSetRemoveModalOpened}
                  title={t('labels.removeChannel')}
                  label={t('descriptions.removeChannel')}
                  withCloseButton
                  leftButton={{
                    variant: 'filled',
                    color: 'accentRed',
                    label: t('buttons.remove'),
                    action: handleRemoveChannel,
                  }}
                  rightButton={{
                    variant: 'light',
                    color: 'accentBlue',
                    label: t('buttons.back'),
                    action: handleSetRemoveModalOpened,
                  }}
                />
              </>
            )}
          </Group>
        </Group>
        {linkedChannel && (
          <Badge mb="xs">
            <Text size="xs" color={theme.colors.accentBlue[0]}>
              {channel.type === CHANNEL_TYPE.ELECTRICITY
                ? t('labels.linkedTap')
                : t('labels.linkedSocket')}
              : {linkedChannel.physicalName}
            </Text>
          </Badge>
        )}
        <ChannelInputs
          form={form}
          isEditModeActive={isEditModeActive}
          isAddingChannelInModal={isAddingChannelInModal}
          channelType={channel.type}
          deviceType={deviceType}
          occupiedSockets={occupiedSockets}
          electricityIndexes={electricityIndexes}
        />
      </form>
      {isEditModeActive && (
        <>
          <ChangeChannelStateModal
            isActive={form.values.isActive}
            isSwitchClicked={isSwitchClicked}
            channelName={channel.name}
            handleChangeSwitchState={handleChangeSwitchState}
          />
          <Modal
            zIndex={1111}
            opened={opened}
            onClose={handleSetOpened}
            title={getReplaceCounterModalTitle()}
            classNames={{ close: classes.close }}>
            {isPMDevice && !channel.replacingMeterInProgress ? (
              <>
                <Text size={14} weight={400}>
                  {t('descriptions.startReplaceCounter')}
                </Text>
                <Group position="apart" mt="xl" noWrap>
                  <Button
                    variant="outline"
                    size="sm"
                    radius="xl"
                    uppercase
                    onClick={handleSetOpened}
                    color="accentBlue">
                    <Text size={14} weight={500}>
                      {t('buttons.cancel')}
                    </Text>
                  </Button>
                  <Button
                    variant="light"
                    size="sm"
                    radius="xl"
                    uppercase
                    onClick={confirmStartReplacingCounter}
                    color="accentCabaret">
                    <Text color="white">{t('buttons.confirm')}</Text>
                  </Button>
                </Group>
              </>
            ) : (
              <>
                <ChannelInputs
                  isEditModal
                  form={editForm}
                  channelType={channel.type}
                  deviceType={deviceType}
                  electricityIndexes={electricityIndexes}
                />
                <Group position="apart" mt="xl" noWrap>
                  <Button
                    variant="outline"
                    size="sm"
                    radius="xl"
                    uppercase
                    onClick={handleSetOpened}
                    color="accentBlue">
                    <Text size={14} weight={500}>
                      {t('buttons.cancel')}
                    </Text>
                  </Button>
                  <Button
                    variant="light"
                    size="sm"
                    radius="xl"
                    uppercase
                    onClick={
                      isPMDevice
                        ? confirmFinishReplacingCounter
                        : handleSaveEdit
                    }
                    color="accentCabaret">
                    <Text color="white">{t('buttons.save')}</Text>
                  </Button>
                </Group>
              </>
            )}
          </Modal>
          <UpdateModal
            opened={updateModalVisible}
            reset={closeUpdateModal}
            title={
              deviceQueryState === 'pending'
                ? getTextAfterUpdate.title
                : t('labels.updatingCounter')
            }
            textNode={
              deviceQueryState === 'pending'
                ? getTextAfterUpdate.description
                : undefined
            }
          />
          {updateModalVisible && (
            <OverlayLoader
              withLoader={deviceQueryState === 'submitting'}
            />
          )}
        </>
      )}
    </>
  );
};
