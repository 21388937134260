export const hr = {
  code: 'hr_hr',
  labels: {
    email: 'E-mail',
    password: 'Zaporka',
    channels: 'Kanali',
    device: 'Uređaj EUI',
    pedestalQr: 'QR elektro ormarića',
    pedestalName: 'Naziv elektro ormarića',
    scanCode: 'Skerniranje koda',
    addChannel: 'Dodajte kanal',
    editChannel: 'Uredi kanal',
    startReplacingCounter: 'Start replacing the counter',
    finishReplacingCounter: 'Finish replacing the counter',
    removeChannel: 'Uklonite kanal',
    removeDevice: 'Uklonite uređaj',
    channelName: 'Naziv kanala',
    pulse: 'Puls po kWh',
    pulseWater: 'puls po m3',
    maximumCurrent: 'Maksimalna struja [A]',
    voltage: 'Napon [V]',
    meterStatus: 'Status brojila [kWh]',
    meterStatusWater: 'Status brojila [m3]',
    addDevice: 'Dodaj uređaj',
    editDevice: 'Uredi uređaj',
    remove: 'Ukloniti',
    lastSeen: 'Zadnje viđeno:',
    never: 'Nikada',
    appVersion: 'Verzija aplikacije',
    attention: 'Pozor!',
    testMode: 'Test mode',
    socketNumber: 'Broj utičnice',
    tapNumber: 'Broj slavine',
    channelNumber: 'Br (kanala)',
    output: 'Izlaz',
    unplug: 'Odspoji',
    editOutputs: 'Uredi izlaze',
    findDevice: 'Skeniraj kod za pronaći uređaj',
    modbusAddress: 'MODBUS adresa',
    linkedChannel: 'Povezani kanal električne energije',
    scanModBus: 'Skeniraj MODBUS',
    updatingOutputs: 'Ažuriranje izlaza',
    updatingPMTags: 'Ažuriranje PM oznaka',
    scanRequest: 'Zahtjev za skeniranje',
    deviceErrors: 'Greške uređaja',
    createdAt: 'Kreirano',
    code: 'Kod',
    description: 'Opis',
    resolvedAt: 'Riješeno u',
    resolvedBy: 'Riješeno od',
    autoResolved: 'Riješeno automatski',
    fixError: 'Kako riješiti grešku?',
    solution: 'Riješenje',
    pmTags: 'PM oznake',
    linkedTap: 'Slavina povezana',
    linkedSocket: 'Utičnica povezana',
    addingChannel: 'Dodavanje novog kanala',
    updatingCounter: 'Ažuriranje brojača',
    profile: 'Profil',
    marinas: 'Marina',
    changePassword: 'Promjeni svoju lozinku',
    oldPassword: 'Stara lozinka',
    newPassword: 'Nova lozinka',
    confirmNewPassword: 'Potvrdi novu lozinku',
    socket: 'Socket',
    tap: 'Tap',
    switchingReason: 'Reason for switching on the tap/socket',
    customerReason: 'Connecting the customer',
    repairReason: 'Repair / test',
    technicalReason: 'Technical / service boat',
    boatName: 'Boat name',
    berth: 'Berth',
    transient: 'Transient',
    yes: 'Yes',
    no: 'No',
    comment: 'Comment',
  },
  descriptions: {
    removeChannel: 'Jeste li sigurni da želite ukloniti kanal?',
    removeDevice: 'Jeste li sigurni da želite ukloniti uređaj?',
    changesNotSaved: 'Promjene nisu spremljene',
    leaveConfirmation: 'Jeste li sigurni da želite izaći?',
    enableLocation:
      'Vaša lokacija je neophodna za određivanje položaja uređaja. Omogućite dozvolu u postavkama preglednika.',
    typeChangeModal:
      'Jeste li sigurni da želite promjeniti tip uređaja? Svi podatci kanala će biti uklonjeni.',
    changeSocketNumber:
      'Jeste li sigurni da želite promjeniti broj utičnice?',
    confirmChannelEnable:
      'Jeste li sigurni da želite otključati ovaj kanal?',
    confirmChannelDisabled:
      'Jeste li sigurni da želite zaključati ovaj kanal?',
    confirmPMTagsEnable: 'Are you sure to enable PM Tags?',
    confirmPMTagsDisable: 'Are you sure to disable PM Tags?',
    updating: 'Ovo može potrajati i do tri minute',
    scanModBus: 'Želim skenirati MODBUS adresu za ovaj kanal',
    wrongFirmwareForTestMode:
      'Please update the devices firmware before enabling test mode.',
    skippedChannel:
      'Refresh data or check the physical connection of the socket in the pedestal.',
    startReplaceCounter:
      'Are you sure you want to start replacing the counter?',
  },
  placeholders: {
    email: 'email@example.com',
    password: '********',
    search: 'Traži',
    pier: 'Gat',
    pedestal: 'Elektro ormarić',
    chooseCamera: 'Odaberite kameru',
    oldPassword: 'Stara lozinka',
    newPassword: 'Nova lozinka',
    confirmNewPassword: 'Potvrdi novu lozinku',
    enterComment: 'Enter a comment',
  },
  buttons: {
    login: 'Prijava',
    logout: 'Odjava',
    back: 'Nazad',
    refresh: 'Osvježiti',
    save: 'Spremiti',
    remove: 'Ukloniti',
    addChannel: 'Dodajte kanal',
    enable: 'Omogućiti',
    lock: 'Zaključaj',
    electricity: 'Struja',
    water: 'Voda',
    add: 'Dodati',
    replace_device: 'Zamijeniti MM-E',
    replace_counter: 'Zamijeniti brojač',
    replace_pedestal_code: 'Zamijeni QR',
    savePosition: 'Spremite novu poziciju',
    cancel: 'Otkazati',
    hide: 'Sakrij',
    confirm: 'Potvrdi',
    getPosition: 'Pronađi poziciju',
    editOutputs: 'Uredi izlaze',
    resolve: 'Riješeno',
    startScan: 'Započni skeniranje',
    stopScan: 'Zaustavi skeniranje',
    scanImage: 'Skeniraj sliku',
    supportRequest: 'Pošalji zahtjev za podršku',
    profile: 'Profil',
    marinas: 'Marina',
    start: 'Start',
    finish: 'Finish',
  },
  legend: {
    title: 'Legenda',
    neverSeen: 'Uređaj nije viđen',
    notSynced: 'Uređaj nije viđen u posljednjih 20 minuta',
    hasErrors: 'Uređaj ima greške-potrebna intervencija',
  },
  notifications: {
    addedDevice: 'Uređaj dodan 👍',
    updatedGeo: 'Ažurirano mjesto 👍',
    updatedEui: 'Uređaj EUI ažurirano 👍',
    updatedPedestalQr: 'QR elektro ormarića ažuriran 👍',
    replacedCounter: 'Brojilo zamijenjeno 👍',
    startedReplaceCounter: 'Started replacing counter 👍',
    replacedCounterNewValue:
      'New meter status: <strong>{{meter_status}} {{unit}}</strong>',
    addedChannel: 'Kanal dodan 👍',
    removedChannel: 'Kanal uklonjen 👍',
    changedChannelState: 'Stanje kanala promijenjeno 👍',
    removedDevice: 'Uređaj uklonjen 👍',
    invalidValues: 'Nevažeće vrijednosti ⚡',
    updatedOutputs: 'Izlazi uređaja ispravno postavljeni',
    error: 'Pogreška 🤥',
    success: 'Uspješno',
    errorMessage: 'Nešto je pogrešno',
    tryAgain: 'Molim pokušajte ponovno',
    notSeenOnSubmit:
      'Uređaj se ne vidi. Provjerite jesu li podaci ispravno spremljeni.',
    invalidDeviceName:
      'Polja naziva uređaja su kriva. Molimo dovršite polja',
    invalidPedestalQrLength:
      'QR elektro ormarića mora biti najmanje 6 znamenki dug',
    enableLocation: 'Trebate omogućiti dozvolu lokacije',
    testModeEnabled: 'Test mode je omogućen',
    testModeDisabled: 'Test mode je onemogućen',
    pmTagsEnabled: 'PM oznake su sada omogućene',
    pmTagsDisabled: 'PM oznake sada su onemogućene',
    deviceNotFound: 'Ovaj uređaj nije pronađen',
    typeNotSupported: 'Ovaj tip mije podržan.',
    preventTypeChange: `Ne možete promjeniti tip uređaja.`,
    socketNumbersUnique: 'Brojevi utičnica moraju biti jedinstveni',
    addDeviceNow: 'Možeš ga dodati sada!',
    uniqueLinks:
      'Veze između kanala za struju i vodu moraju biti jedinstvene',
    scanRequested:
      'Zahtjev za skeniranje je poslan. Scanning may take up to 10 minutes.',
    errorResolved: 'Greška riješena 👍',
    qrNotFound: 'QR kod nije nađen',
    supportRequest: 'Vaš zahtjev je poslan tehničkoj podršci',
    passwordChanged: 'Lozinka je uspješno promijenjena',
  },
  errors: {
    devEui: 'EUI ne smije biti prazan',
    channels: 'Kanal mora sadržavati najmanje 1 element',
    newDevEui: 'Novi EUI uređaj ne smije biti prazan',
    pulse: 'Puls ne smije biti nedefiniran',
    meterStatus: 'Status brojila ne smije biti nedefiniran',
    userNotFound: 'Korisnik nije nađen',
    wrongPassword: 'Lozinka nevaljana',
    physicalName: 'Vrijednost fizičkog imena nije važeća',
    pedestalQr: 'Vrijednost QR elektro ormarića je kriva',
    modBus: 'MODUS vrijednost je nevažeća',
  },
  validations: {
    invalid: 'Nevažeća vrijednost',
    required: 'Obvezno polje',
    email: 'E-mail je pogrešan',
    wrongMarina: 'Skenirani QR kod je iz druge marine',
    wrongEui: 'Ovaj EUI nije ispravan',
    wrongPedestalQr: 'Kod nije ispravan',
    wrongPedestalName: 'Skenirano ime elektro ormarića nije ispravno',
    passwordLength: 'Lozinka mora imati najmanje 8 znakova',
    strongPassword:
      'Lozinka mora sadržavati najmanje jedno veliko slovo, malo slovo, broj i poseban znak',
    samePasswords: 'Lozinke moraju biti iste',
  },
  notFound: {
    title: 'Pronašli ste tajno mjesto',
    description:
      'Nažalost, ovo je samo 404 stranica. Možda ste promijenili adresu ili je stranica premještena na drugi URL.',
    button: 'Vrati me na početnu stranicu',
  },
  deviceErrors: {
    1: {
      description: 'Učitani Fimware nije valjan',
      instruction: 'Zamijenite uređaj',
    },
    2: {
      description: 'Preuzeti Fimware nije valjan',
      instruction:
        'Konfigurirajte FUOTA s ispravnom verzijom hardvera za uređaj',
    },
    3: {
      description: 'MODBUS communication error for channel no. {{arg0}}',
      arg0: 'za kanal br. {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    4: {
      description: 'Neuspjelo MODBUS skeniranje {{arg0}} {{arg1}}',
      arg0: 'za kanal br. {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    5: {
      description: 'Reset je izvršio watchdog {{arg0}}',
      arg0_0: '(IWDG watchdog)',
      arg0_1: '(WWDG watchdog)',
    },
    6: {
      description: 'User credits overwritten {{arg0}} {{arg1}}',
      arg0: '(number of credits: {{arg}}).',
      arg1: 'No. of the channel for which the credits have been overwritten: {{arg}}',
      instruction:
        'Verify if overwritten credits should be returned to the customer. Report the situation to the office for verification.',
    },
    7: {
      description: 'The device has lost communication',
    },
    8: {
      description: 'Replace or update the Plus {{arg0}}}',
      arg0: 'socket no. {{arg}}',
      instruction:
        'Replace the socket with one with firmware 1.29 or update the socket firmware to 1.29.',
    },
    defaultSolution:
      'Ako imate problema s rješavanjem pogreške, obratite se tehničkoj podršci',
  },
};
