export const getAvailableIndex = (
  indexes: string[],
  maxLength: number,
) => {
  let availableIndex = null;
  for (let i = 1; i <= maxLength; i += 1) {
    const index = indexes.find(el => Number(el) === i);
    if (!index) {
      availableIndex = i;
      break;
    }
  }
  return availableIndex;
};
