import isNumeric from 'validator/lib/isNumeric';
import isLength from 'validator/lib/isLength';
import { PEDESTAL_QR_MAX_LENGTH } from '../constants/devices-data.constants';

export const isCorrectPedestalQr = (pedestalQr: string) => {
  return (
    isNumeric(pedestalQr) &&
    isLength(pedestalQr, {
      min: PEDESTAL_QR_MAX_LENGTH,
      max: PEDESTAL_QR_MAX_LENGTH,
    })
  );
};
