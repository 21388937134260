import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Modal } from '@mantine/core';

interface IUpdateModalProps {
  opened: boolean;
  reset: () => void;
  title: string;
  textNode?: React.ReactNode;
}

export const UpdateModal: React.FC<IUpdateModalProps> = ({
  opened,
  reset,
  title,
  textNode,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      zIndex={1111}
      opened={opened}
      onClose={reset}
      overlayOpacity={0}
      title={title}
      closeOnClickOutside={false}>
      <Text size={14} weight={400}>
        {textNode || t('descriptions.updating')}
      </Text>
      <Button
        mt="md"
        radius="xl"
        size="sm"
        color="accentBlue"
        uppercase
        onClick={reset}>
        <Text size={14} weight={500} color="white">
          {t('buttons.hide')}
        </Text>
      </Button>
    </Modal>
  );
};
