import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconScan, IconAlertCircle } from '@tabler/icons';
import { createStyles, ActionIcon, Modal } from '@mantine/core';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { isCorrectEui } from '../../../utils';
import { QRScanner } from './DeviceInputs/qr-scanner';
import { getDevice, getRegistry } from '../../../services/api/devices';
import EuiScanPreview from '../../../assets/eui-image.png';

const useStyles = createStyles(theme => ({
  close: {
    color: theme.colors.primary[0],
  },
}));

export const SearchEui: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [opened, setOpened] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');

  const validate = useCallback((decodedText: string) => {
    const eui = decodedText.substring(0, 16);
    return isCorrectEui(eui);
  }, []);

  const handleSetOpened = useCallback(() => {
    setOpened(prev => !prev);
  }, []);

  const handleScannedCode = useCallback(
    async (code: string) => {
      try {
        const eui = code.toLowerCase();
        const devices = await getDevice(`?marinCode=${marinCode}`);
        const scannedDevice = devices.data.devices.find(
          device => device.devEui === eui,
        );
        if (scannedDevice) {
          navigate(
            `/device${window.location.search}&devEui=${scannedDevice.devEui}`,
          );
        } else {
          const isCodeInRegistry = await getRegistry(eui);
          if (isCodeInRegistry) {
            sessionStorage.setItem('searchedEui', eui);
            navigate(`/device/add${window.location.search}`);
          } else {
            showNotification({
              title: t('notifications.error'),
              message: t('notifications.deviceNotFound'),
              color: 'accentRed',
              icon: <IconAlertCircle size={18} />,
            });
          }
        }
      } finally {
        setOpened(false);
      }
    },
    [marinCode, navigate, t],
  );

  return (
    <>
      <ActionIcon
        variant="filled"
        color="accentBlue"
        onClick={handleSetOpened}>
        <IconScan />
      </ActionIcon>
      <Modal
        zIndex={1111}
        opened={opened}
        onClose={handleSetOpened}
        title={t('labels.findDevice')}
        classNames={{ close: classes.close }}>
        <QRScanner
          handleScannedCode={handleScannedCode}
          setOpen={setOpened}
          validate={validate}
          previewSrc={EuiScanPreview}
          errorMessage={t('validations.wrongEui')}
        />
      </Modal>
    </>
  );
};
