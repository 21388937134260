import React, { SetStateAction, useCallback, useState } from 'react';
import { useDidUpdate } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { IChannel } from '../../../../services/api/devices';
import { IDeviceType } from '../../../../types/device';
import { ActionModal } from '../../../../components/action-modal';

interface ITypeChangeModal {
  isEditModeActive: boolean;
  prevDevice: IDeviceType;
  hasChannels: boolean;
  setChannels: React.Dispatch<SetStateAction<IChannel[]>>;
  handleDeviceChange: (restore?: boolean) => void;
  deviceType: string;
}

export const TypeChangeModal: React.FC<ITypeChangeModal> = ({
  isEditModeActive,
  prevDevice,
  hasChannels,
  setChannels,
  handleDeviceChange,
  deviceType,
}) => {
  const { t } = useTranslation();
  const [isTypeChangeModalOpened, setIsTypeChangeModalOpened] =
    useState<boolean>(false);

  const handleIsTypeChangeModalOpened = useCallback(() => {
    setIsTypeChangeModalOpened(prev => !prev);
  }, []);

  const clearChannels = useCallback(() => {
    setChannels([]);
    handleDeviceChange();
    setIsTypeChangeModalOpened(false);
  }, [setChannels, handleDeviceChange]);

  const handleBackType = useCallback(() => {
    handleDeviceChange(true);
    handleIsTypeChangeModalOpened();
  }, [handleIsTypeChangeModalOpened, handleDeviceChange]);

  useDidUpdate(() => {
    if (
      !isEditModeActive &&
      prevDevice &&
      prevDevice.type &&
      hasChannels &&
      prevDevice.type !== deviceType
    ) {
      handleIsTypeChangeModalOpened();
    }
  }, [
    deviceType,
    isEditModeActive,
    prevDevice,
    hasChannels,
    handleIsTypeChangeModalOpened,
  ]);

  return (
    <ActionModal
      opened={isTypeChangeModalOpened}
      onClose={clearChannels}
      title={t('labels.attention')}
      label={t('descriptions.typeChangeModal')}
      leftButton={{
        variant: 'light',
        color: 'accentBlue',
        label: t('buttons.cancel'),
        action: handleBackType,
      }}
      rightButton={{
        variant: 'light',
        color: 'accentCabaret',
        label: t('buttons.confirm'),
        action: clearChannels,
      }}
    />
  );
};
