import { DeviceTypes } from '../types/device';

import {
  CHANNEL_TYPE,
  CHANNELS_MAX_LENGTH,
} from '../constants/channels-data.constants';

export const getSocketInputData = (
  deviceType: DeviceTypes,
  channelsType: string,
) => {
  if (deviceType === DeviceTypes.MM_E) {
    return Array.from({ length: CHANNELS_MAX_LENGTH.MM_E }, (v, i) =>
      String(i + 1),
    );
  }
  if (
    deviceType === DeviceTypes.MM_E_8 ||
    deviceType === DeviceTypes.MM_E_PM
  ) {
    if (channelsType === CHANNEL_TYPE.ELECTRICITY) {
      return Array.from({ length: CHANNELS_MAX_LENGTH.MM_E_8 }, (v, i) =>
        String(i + 1),
      );
    }
    if (channelsType === CHANNEL_TYPE.WATER) {
      return Array.from({ length: CHANNELS_MAX_LENGTH.MM_E_8 }, (v, i) =>
        String(i + (CHANNELS_MAX_LENGTH.MM_E_8 + 1)),
      );
    }
  }
  return null;
};
