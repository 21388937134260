import { useState, useEffect, useCallback } from 'react';
import {
  createStyles,
  Container,
  Button,
  Stack,
  Text,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { UserHeader } from '../../components/user-header';
import { SearchBox } from '../../components/Search';
import { getMarinas } from '../../services/api/marinas';
import { BottomNavigation } from '../../components/bottom-navigation';
import { BottomNavIcon } from '../../components/atoms/bottom-nav-icon';

const useStyles = createStyles(() => ({
  button: {
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.2);',
  },
}));

export const Marinas = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { refetch, data, isLoading, isFetching } = useQuery(
    ['getMarinas'],
    getMarinas,
    {
      refetchOnWindowFocus: false,
    },
  );

  const loading = isLoading || isFetching;

  const [searchedWord, setSearchedWord] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleSearchedWord = useCallback((value: string) => {
    setSearchedWord(value);
  }, []);

  useEffect(() => {
    if (searchedWord === '' && data) {
      setFilteredList(data.data.items);
    }
    if (searchedWord !== '' && data) {
      setFilteredList(
        data.data.items.filter(el => {
          return el.name
            .toLowerCase()
            .includes(searchedWord.toLowerCase());
        }),
      );
    }
  }, [searchedWord, data]);

  return (
    <>
      <Container>
        <Stack px={20}>
          <UserHeader isLoading={loading} title={t('labels.marinas')}>
            <SearchBox mt={20} onSearchChange={handleSearchedWord} />
          </UserHeader>
          {filteredList && (
            <Stack id="marinas" mt={16}>
              {filteredList.map(item => {
                return (
                  <Button
                    key={item.code}
                    component={Link}
                    to={`../devices?marinCode=${item.code}`}
                    fullWidth
                    color="secondary"
                    radius="xl"
                    size="md"
                    className={classes.button}>
                    <Text size={14} weight={500} transform="uppercase">
                      {item.name}
                    </Text>
                  </Button>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Container>
      <BottomNavigation>
        <BottomNavIcon
          onClick={handleRefetch}
          iconName="IconRefresh"
          label={t('buttons.refresh')}
        />
      </BottomNavigation>
    </>
  );
};
