import axios from 'axios';
import { showNotification } from '@mantine/notifications';
import i18n from 'i18next';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.data.data &&
      Array.isArray(error.response.data.data)
    ) {
      error.response.data.data.forEach(err => {
        showNotification({
          title: `${i18n.t('notifications.error')}`,
          message: i18n.exists(`errors.${err.property}`)
            ? String(i18n.t(`errors.${err.property}`))
            : String(i18n.t(`errors.${err.field}`)),
          color: 'accentRed',
        });
      });
    } else {
      showNotification({
        title: `${i18n.t('notifications.error')}`,
        message:
          typeof error.response.data.message === 'string'
            ? error.response.data.message
            : `${i18n.t('notifications.errorMessage')}`,
        color: 'accentRed',
      });
    }
    return Promise.reject(error);
  },
);
