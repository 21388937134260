import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationsProvider } from '@mantine/notifications';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './styles/global.scss';
import { AuthProvider } from './store/auth-store';
import { PRIMARY_THEME } from './constants/themes';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    {/* // TODO: maybe some loader as fallback? */}
    <Suspense fallback={<div />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <MantineProvider theme={PRIMARY_THEME}>
            <NotificationsProvider zIndex={99999}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </NotificationsProvider>
          </MantineProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
