import { api } from '.';
import { ENDPOINTS } from './endpoints';

export interface IErrorArguments<T> {
  arg0: T;
  arg1: T;
  arg2: T;
}

export interface IDeviceError {
  id: number;
  code: number;
  count: number;
  arguments: IErrorArguments<number>;
  resolvedBy: string;
  resolvedAt: string;
  createdAt: string;
}

interface IDeviceErrors {
  errors: IDeviceError[];
}

export interface IResolveErrorData {
  marinCode: string;
  id: number;
}

export const getErrors = (mar: string, devEui: string) => {
  return api.get<IDeviceErrors>(
    `${ENDPOINTS.ERRORS}/${devEui}/list?marinCode=${mar}`,
  );
};

export const resolveError = (devEui: string, data: IResolveErrorData) => {
  return api.put<IResolveErrorData>(
    `${ENDPOINTS.ERRORS}/${devEui}/resolve`,
    data,
  );
};
