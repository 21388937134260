import { CHANNELS_MAX_LENGTH } from '../constants/channels-data.constants';
import { DeviceTypes } from '../types/device';

export const checkIsMaxAmountReached = (
  deviceType: DeviceTypes,
  channelsLength: number,
  activeChannelsLength: number,
) => {
  switch (deviceType) {
    case DeviceTypes.MM_E:
      return channelsLength >= CHANNELS_MAX_LENGTH.MM_E;
      break;
    case DeviceTypes.MM_E_8:
    case DeviceTypes.MM_E_PM:
      return activeChannelsLength >= CHANNELS_MAX_LENGTH.MM_E_8;
      break;
    default: {
      const n: never = deviceType;
      return n;
    }
  }
};
