import React from 'react';
import {
  Container,
  Title,
  Button,
  Text,
  Box,
  PasswordInput,
  createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { IconCheck } from '@tabler/icons';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import { BottomNavigation } from '../../components/bottom-navigation';
import { UserHeader } from '../../components/user-header';
import { IUserPasswords, changePassword } from '../../services/api/users';

const useStyles = createStyles(theme => ({
  error: {
    fontSize: '12px',
    color: theme.colors.accentRed[0],
  },

  label: {
    fontSize: '14px',
  },

  visibilityToggle: {
    color: theme.colors.primary[0],
  },

  required: {
    color: theme.colors.accentRed[0],
  },
}));

export const Profile: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const validatePassword = (value: string) => {
    if (!isLength(value, { min: 8 })) {
      return t('validations.passwordLength');
    }
    if (!isStrongPassword(value)) {
      return t('validations.strongPassword');
    }
    return null;
  };

  const form = useForm<IUserPasswords>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: {
      oldPassword: value =>
        value.length ? null : t('validations.required'),
      newPassword: value => validatePassword(value),
      confirmNewPassword: value =>
        value !== form.values.newPassword
          ? t('validations.samePasswords')
          : validatePassword(value),
    },
  });

  const { mutate, isLoading } = useMutation(
    (item: IUserPasswords) => {
      return changePassword(item);
    },
    {
      onSuccess: () => {
        navigate(-1);
        showNotification({
          title: t('notifications.passwordChanged'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      },
    },
  );

  const handlePasswordChange = form.onSubmit((values: IUserPasswords) =>
    mutate(values),
  );

  return (
    <>
      <Container>
        <UserHeader
          isLoading={isLoading}
          backTarget="prevLocation"
          title={t('labels.profile')}
        />
        <Box p={20}>
          <Title my="md" order={4}>
            {t('labels.changePassword')}
          </Title>
          <form onSubmit={handlePasswordChange} noValidate>
            <PasswordInput
              size="md"
              mt="sm"
              required
              classNames={{
                error: classes.error,
                label: classes.label,
                visibilityToggle: classes.visibilityToggle,
                required: classes.required,
              }}
              label={t('labels.oldPassword')}
              placeholder={t('placeholders.oldPassword')}
              {...form.getInputProps('oldPassword')}
            />
            <PasswordInput
              size="md"
              mt="sm"
              required
              classNames={{
                error: classes.error,
                label: classes.label,
                visibilityToggle: classes.visibilityToggle,
                required: classes.required,
              }}
              label={t('labels.newPassword')}
              placeholder={t('placeholders.newPassword')}
              {...form.getInputProps('newPassword')}
            />
            <PasswordInput
              size="md"
              required
              mt="sm"
              classNames={{
                error: classes.error,
                label: classes.label,
                visibilityToggle: classes.visibilityToggle,
                required: classes.required,
              }}
              label={t('labels.confirmNewPassword')}
              placeholder={t('placeholders.confirmNewPassword')}
              {...form.getInputProps('confirmNewPassword')}
            />
            <Button
              type="submit"
              radius="xl"
              fullWidth
              size="md"
              mt={40}
              variant="filled"
              color="accentCabaret">
              <Text size={14} weight={500}>
                {t('buttons.save')}
              </Text>
            </Button>
          </form>
        </Box>
      </Container>
      <BottomNavigation />
    </>
  );
};
