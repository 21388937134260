export interface IDeviceType {
  eui: string;
  type: string;
}

export interface IDeviceOutputs {
  channel: string;
  output: boolean;
  unplugDetection: boolean;
}

export interface IDeviceTypeData {
  euiType: DeviceTypes;
  fw: string;
}

export enum ConsumptionReportType {
  CONNECTING_THE_CUSTOMER = 'connecting-the-customer',
  REPAIR_OR_TEST = 'repair-or-test',
  TECHNICAL_OR_SERVICE = 'technical-or-service',
}

export interface IOutputsReport {
  reason: ConsumptionReportType;
  boatName: string;
  berth: string;
  transient: string;
  comment: string;
}

export enum Outputs {
  OUTPUT = 'output',
  UNPLUG = 'unplugDetection',
}

export enum DeviceTypes {
  MM_E = 'MM-E',
  MM_E_8 = 'MM-E-8',
  MM_E_PM = 'MM-E-PM',
}
