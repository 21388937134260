import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Text,
  Button,
  Container,
  Group,
  Stack,
} from '@mantine/core';
import { Link } from 'react-router-dom';

export function NotFoundTitle() {
  const { t } = useTranslation();

  return (
    <Container size="xs">
      <Stack p={10} mt={40} spacing={40}>
        <Title color="primary" align="center">
          {t('notFound.title')}
        </Title>
        <Text color="primary" size={16} align="center">
          {t('notFound.description')}
        </Text>
        <Group position="center">
          <Button
            color="accentCabaret"
            size="md"
            radius="xl"
            component={Link}
            to="/marinas">
            {t('notFound.button')}
          </Button>
        </Group>
      </Stack>
    </Container>
  );
}
