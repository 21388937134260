import React, { useCallback } from 'react';
import { IconInfoCircle, IconCheck } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';
import {
  createStyles,
  useMantineTheme,
  ActionIcon,
  Text,
  Card,
  Badge,
  Group,
  Button,
} from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import {
  resolveError,
  IResolveErrorData,
  IDeviceError,
} from '../../../../services/api/errors';
import { DATE_FORMAT_WITH_HOUR } from '../../../../constants/date-format.constants';
import { getErrorDescription } from '../../../../utils';

const useStyles = createStyles(theme => ({
  card: {
    width: '100%',
    border: '2px solid',
  },

  resolved: {
    borderColor: theme.colors.blue[3],
  },

  unresolved: {
    borderColor: theme.colors.red[3],
  },

  instructionButton: {
    '&:disabled': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
}));

interface IErrorCardProps {
  error: IDeviceError;
  setCurrentError: React.Dispatch<React.SetStateAction<IDeviceError>>;
}

export const ErrorCard: React.FC<IErrorCardProps> = ({
  error,
  setCurrentError,
}) => {
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const { cx, classes } = useStyles();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const queryClient = useQueryClient();

  const refetch = useCallback(async () => {
    await queryClient.refetchQueries({ queryKey: ['getErrors'] });
  }, [queryClient]);

  const handleInstructionOpened = useCallback(() => {
    setCurrentError(error);
  }, [setCurrentError, error]);

  const mutation = useMutation(
    (item: IResolveErrorData) => {
      return resolveError(devEui, item);
    },
    {
      onSuccess: () => {
        showNotification({
          title: t('notifications.errorResolved'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
        refetch();
      },
    },
  );

  const submitResolve = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!(event.target instanceof HTMLButtonElement)) {
        return;
      }
      const item = {
        marinCode,
        id: Number(event.target.dataset.id),
      };
      mutation.mutate(item);
    },
    [marinCode, mutation],
  );

  return (
    <Card
      key={error.id}
      my="sm"
      radius="md"
      shadow="sm"
      withBorder
      className={cx(
        classes.card,
        error.resolvedAt ? classes.resolved : classes.unresolved,
      )}>
      <Group position="apart" noWrap>
        <Text size={12} color="black">
          {t('labels.createdAt')}:
          <br />
          {dayjs(error.createdAt).format(DATE_FORMAT_WITH_HOUR)}
        </Text>
        <ActionIcon
          size="sm"
          onClick={handleInstructionOpened}
          disabled={!!error.resolvedAt}
          className={classes.instructionButton}>
          <IconInfoCircle
            color={
              error.resolvedAt
                ? theme.colors.dark[0]
                : theme.colors.primary[0]
            }
            className="none-pointer"
          />
        </ActionIcon>
      </Group>
      <Group position="apart" mt="sm">
        <Text size={12} color="black">
          {t('labels.code')}: {error.code}
        </Text>
        <Badge>
          <Text size={12} color={theme.colors.accentBlue[0]}>
            {error.count}
          </Text>
        </Badge>
      </Group>
      {i18n.exists(`deviceErrors.${error.code}.description`) && (
        <Group position="apart" mt="sm">
          <Text size={12} color="black">
            {t('labels.description')}:{' '}
            {getErrorDescription(error.code, error.arguments)}
          </Text>
        </Group>
      )}
      {error.resolvedAt ? (
        <>
          <Text size={12} color="black" mt="sm">
            {t('labels.resolvedAt')}:{' '}
            {dayjs(error.resolvedAt).format(DATE_FORMAT_WITH_HOUR)}
          </Text>{' '}
          <Text size={12} color="black">
            {t('labels.resolvedBy')}:{' '}
            {error.resolvedBy
              ? error.resolvedBy
              : t('labels.autoResolved')}
          </Text>
        </>
      ) : (
        <Button
          variant="light"
          size="xs"
          mt="sm"
          radius="xl"
          uppercase
          color="accentCabaret"
          onClick={submitResolve}
          data-id={error.id}
          classNames={{
            label: 'none-pointer',
            inner: 'none-pointer',
          }}>
          <Text
            color="white"
            size={12}
            weight={500}
            className="none-pointer">
            {t('buttons.resolve')}
          </Text>
        </Button>
      )}
    </Card>
  );
};
