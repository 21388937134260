import { api } from '.';
import { ENDPOINTS } from './endpoints';

export interface ISupportRequest {
  marinCode: string;
  errorId: number;
}

export const requestSupport = (data: ISupportRequest) => {
  return api.post<ISupportRequest>(`${ENDPOINTS.SUPPORT}/request`, data);
};
