export const ENDPOINTS = {
  AUTH: {
    USER: {
      LOGIN: '/auth/user/login',
    },
  },
  SETTINGS: {
    LIST: '/settings/list',
  },
  MARINAS: {
    LIST: '/marinas/list',
  },
  DEVICES: '/devices',
  ERRORS: '/errors',
  SUPPORT: '/technical-support',
  USERS: '/users',
};
