import { IChannel } from '../services/api/devices';
import { Outputs, IDeviceOutputs } from '../types/device';

export const getChannelsOutputs = (
  channels: IChannel[],
): IDeviceOutputs[] => {
  return channels
    .reduce(
      (acc, val) => [
        ...acc,
        {
          [Outputs.OUTPUT]: !!val.output,
          [Outputs.UNPLUG]: !!val.disconnectMonitoringActive,
          channel: val.name,
        },
      ],
      [],
    )
    .sort((a, b) => Number(a.channel) - Number(b.channel));
};
