import {
  useMantineTheme,
  NativeSelect,
  NumberInput,
  SelectItem,
  Checkbox,
  Loader,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { IconCheck } from '@tabler/icons';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UseFormReturnType } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  MAXIMUM_CURRENT_VALUES,
  VOLTAGE_VALUES,
  CHANNEL_TYPE,
  CURRENT_VOLTAGE_THRESHOLD,
} from '../../../../constants/channels-data.constants';
import { MODBUS_MAX_VALUE } from '../../../../constants/devices-data.constants';
import { getSocketInputData } from '../../../../utils';
import { ActionModal } from '../../../../components/action-modal';
import { changePhysicalName } from '../../../../services/api/devices';
import { DeviceTypes } from '../../../../types/device';

interface IChannelInputsProps {
  form: UseFormReturnType<{
    name: string;
    type: string;
    isActive: boolean;
    pulse: number;
    currentMax: number;
    currentVoltage: number;
    meterStatus: number;
    physicalName: string;
    modBus: number;
    scanModBus: boolean;
  }>;
  isEditModeActive?: boolean;
  isEditModal?: boolean;
  isAddingChannelInModal?: boolean;
  channelType: string;
  deviceType: DeviceTypes;
  occupiedSockets?: string[];
  electricityIndexes: SelectItem[];
}

export const ChannelInputs: React.FC<IChannelInputsProps> = ({
  form,
  isEditModeActive,
  isAddingChannelInModal,
  isEditModal,
  channelType,
  deviceType,
  occupiedSockets,
  electricityIndexes,
}) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const meterStatusInputRef = useRef<HTMLInputElement>(null);
  const isElectricityChannel = channelType === CHANNEL_TYPE.ELECTRICITY;
  const [newPhysicalName, setNewPhysicalName] = useState<string | null>(
    null,
  );
  const [searchParams] = useSearchParams();
  const devEui = searchParams.get('devEui');
  const marinCode = searchParams.get('marinCode');
  const isPMDevice = deviceType === DeviceTypes.MM_E_PM;
  const isScanningModbus = isPMDevice && form.values.modBus === null;

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(
        event.currentTarget.id,
        Number(event.currentTarget.value),
      );
    },
    [form],
  );

  const handleModbusChange = useCallback(
    (value: number) => {
      form.setFieldValue(
        'modBus',
        Math.floor(Math.min(MODBUS_MAX_VALUE, value)),
      );
    },
    [form],
  );

  const handlePhysicalNameEdit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newNameIsOccupied = occupiedSockets.find(
        el => String(el) === String(event.currentTarget.value),
      );
      if (newNameIsOccupied) {
        return showNotification({
          title: t('notifications.socketNumbersUnique'),
          message: t('notifications.error'),
          color: 'accentRed',
        });
      }
      return setNewPhysicalName(event.currentTarget.value);
    },
    [occupiedSockets, t],
  );

  const resetNewPhysicalName = useCallback(() => {
    setNewPhysicalName(null);
  }, []);

  const handlePhysicalNameChange = useMutation(
    (item: object) => {
      return changePhysicalName(devEui, form.values.name, item);
    },
    {
      onSuccess: () => {
        showNotification({
          title: t('notifications.changedChannelState'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
        form.setFieldValue('physicalName', newPhysicalName);
        setNewPhysicalName(null);
      },
    },
  );

  const confirmNewPhysicalName = useCallback(() => {
    const item = {
      marinCode,
      physicalName: newPhysicalName,
    };
    handlePhysicalNameChange.mutate(item);
  }, [newPhysicalName, handlePhysicalNameChange, marinCode]);

  const handleCurrentMaxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = Number(event.currentTarget.value);
    form.setFieldValue('currentMax', value);

    if (value >= CURRENT_VOLTAGE_THRESHOLD) {
      form.setFieldValue('currentVoltage', 400);
    }
  };

  useEffect(() => {
    if (meterStatusInputRef.current) {
      meterStatusInputRef.current.inputMode = 'decimal';
    }
  }, [form]);

  return (
    <>
      {/* Physical name input is not visible in edit modal, because it's editable directly on channels list */}
      {!isEditModal && (
        <NativeSelect
          label={
            isElectricityChannel
              ? t('labels.socketNumber')
              : t('labels.tapNumber')
          }
          data={getSocketInputData(deviceType, channelType)}
          {...form.getInputProps('physicalName')}
          id="physicalName"
          onChange={
            isEditModeActive ? handlePhysicalNameEdit : handleSelectChange
          }
        />
      )}
      {(isPMDevice &&
        channelType === CHANNEL_TYPE.ELECTRICITY &&
        !isEditModal) ||
      (isPMDevice &&
        channelType === CHANNEL_TYPE.WATER &&
        isEditModeActive) ? (
        <>
          <NumberInput
            label={t('labels.modbusAddress')}
            {...form.getInputProps('modBus')}
            id="modBus"
            disabled={isEditModeActive || form.values.scanModBus}
            min={0}
            max={MODBUS_MAX_VALUE}
            step={1}
            noClampOnBlur
            maxLength={String(MODBUS_MAX_VALUE).length}
            hideControls
            formatter={value => (isScanningModbus ? '-' : value)}
            rightSection={
              isScanningModbus && (
                <Loader size="sm" color={theme.colors.accentBlue[0]} />
              )
            }
            styles={{ rightSection: { marginRight: '4px' } }}
            onChange={handleModbusChange}
          />
          {/* show checkbox only if adding new device */}
          {!isEditModeActive &&
            !isEditModal &&
            !isAddingChannelInModal && (
              <Checkbox
                mt="sm"
                id="scanModBus"
                label={t('descriptions.scanModBus')}
                color="accentYellow"
                {...form.getInputProps('scanModBus')}
                checked={form.values.scanModBus}
              />
            )}
        </>
      ) : null}
      {isPMDevice &&
        channelType === CHANNEL_TYPE.WATER &&
        !isEditModeActive &&
        !isEditModal && (
          <NativeSelect
            label={t('labels.linkedChannel')}
            data={electricityIndexes ?? []}
            {...form.getInputProps('modBus')}
            id="modBus"
            onChange={handleSelectChange}
          />
        )}
      {!isPMDevice && (
        <NumberInput
          label={
            isElectricityChannel
              ? t('labels.pulse')
              : t('labels.pulseWater')
          }
          {...form.getInputProps('pulse')}
          hideControls
          disabled={isEditModeActive}
        />
      )}
      {isElectricityChannel && (
        <NativeSelect
          label={t('labels.maximumCurrent')}
          data={MAXIMUM_CURRENT_VALUES}
          {...form.getInputProps('currentMax')}
          id="currentMax"
          onChange={handleCurrentMaxChange}
          disabled={isEditModeActive}
        />
      )}
      {isElectricityChannel && (
        <NativeSelect
          label={t('labels.voltage')}
          data={VOLTAGE_VALUES}
          {...form.getInputProps('currentVoltage')}
          id="currentVoltage"
          onChange={handleSelectChange}
          disabled={
            isEditModeActive ||
            form.values.currentMax >= CURRENT_VOLTAGE_THRESHOLD
          }
        />
      )}
      {(!isPMDevice || isEditModeActive) && (
        <NumberInput
          decimalSeparator=","
          label={
            isElectricityChannel
              ? t('labels.meterStatus')
              : t('labels.meterStatusWater')
          }
          disabled={isEditModeActive}
          precision={2}
          hideControls
          ref={meterStatusInputRef}
          {...form.getInputProps('meterStatus')}
        />
      )}
      <ActionModal
        opened={!!newPhysicalName}
        onClose={resetNewPhysicalName}
        title={t('labels.attention')}
        label={t('descriptions.changeSocketNumber')}
        withCloseButton
        leftButton={{
          variant: 'light',
          color: 'accentBlue',
          label: t('buttons.cancel'),
          action: resetNewPhysicalName,
        }}
        rightButton={{
          variant: 'light',
          color: 'accentCabaret',
          label: t('buttons.confirm'),
          action: confirmNewPhysicalName,
        }}
      />
    </>
  );
};
