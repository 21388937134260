export const gr = {
  code: 'gr_gr',
  labels: {
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    password: 'Κωδικός πρόσβασης',
    channels: 'Κανάλια',
    device: 'Συσκευή EUI',
    pedestalQr: 'Πίλαρ QR',
    pedestalName: 'Όνομα Πιλαρ',
    scanCode: 'Σάρωση Κωδικόυ',
    addChannel: 'Προσθήκη Κανάλιού',
    editChannel: 'Επεξεργασία καναλιού',
    startReplacingCounter: 'Start replacing the counter',
    finishReplacingCounter: 'Finish replacing the counter',
    removeChannel: 'Κατάργηση Καναλιού',
    removeDevice: 'Κατάργηση Συσκευής',
    channelName: 'Όνομά κανάλιού:',
    pulse: 'Παλμός ανά kWh',
    pulseWater: 'Παλμός ανά [m3]',
    maximumCurrent: 'Μέγιστο Ρέυμα',
    voltage: 'Τάση (Volt)',
    meterStatus: 'Κατάσταση Μετρητή (kWh)',
    meterStatusWater: 'Καταστασή Μετρητή [m3]',
    addDevice: 'Προσθήκη Συσκευής',
    editDevice: 'Επεξεργασία Συσκευής',
    remove: 'Αφαιρώ',
    lastSeen: 'Τελευταία Προβολή:',
    never: 'Ποτέ',
    appVersion: 'Έκδοση εφαρμογής',
    attention: 'Προσοχή',
    testMode: 'Λειτουργία δοκιμής',
    socketNumber: 'Αριθμός πρίζας',
    tapNumber: 'Αριθμός βρύσης',
    channelNumber: 'Αριθμός (κανάλι)',
    output: 'Εξοδός',
    unplug: 'Αφαιρέστε την πρίζα',
    editOutputs: 'Επεξεργασία Εξόδων',
    findDevice: 'Σαρώστε τον κωδικό για να βρείτε τη συσκευή',
    modbusAddress: 'Διεύθυνση MODBUS',
    linkedChannel: 'Συνδεδεμένος ηλεκτρικός αγωγός',
    scanModBus: 'Σάρωσει MODBUS',
    updatingOutputs: 'Ενημέρωση ισχύος εξόδου',
    updatingPMTags: 'Αναβάθμιση ηλεκτρονικού κλειδιού.',
    scanRequest: 'Αίτημα σάρωσης',
    deviceErrors: 'Σφάλματα συσκευής',
    createdAt: 'Δημιουργήθηκε στο',
    code: 'Κωδικός',
    description: 'Περιγγραφή',
    resolvedAt: 'Επιλύθηκε στις',
    resolvedBy: 'Επιλύθηκε από',
    autoResolved: 'Αυτόματη επιδιόρθωση',
    fixError: 'Πώς να διορθώσετε το σφάλμα;',
    solution: 'Λύση',
    pmTags: 'Ηλεκτρονικό κλειδί',
    linkedTap: 'Αντιστοιχισμένη βρύση',
    linkedSocket: 'Αντιστοιχισμένη πρίζα',
    addingChannel: 'Πρόσθεση νέου καναλιού',
    updatingCounter: 'Ενημέρωση μετρητή.',
    profile: 'Προφίλ',
    marinas: 'Μαρίνες',
    changePassword: 'Αλλαγή κωδικού πρόσβασης',
    oldPassword: 'Παλιός κωδικός πρόσβασης',
    newPassword: 'Νέος κωδικός πρόσβασης',
    confirmNewPassword: 'Επιβεβαίωσε νέο κωδικό πρόσβασης',
    socket: 'Socket',
    tap: 'Tap',
    switchingReason: 'Reason for switching on the tap/socket',
    customerReason: 'Connecting the customer',
    repairReason: 'Repair / test',
    technicalReason: 'Technical / service boat',
    boatName: 'Boat name',
    berth: 'Berth',
    transient: 'Transient',
    yes: 'Yes',
    no: 'No',
    comment: 'Comment',
  },
  descriptions: {
    removeChannel: 'Ειστε σίγουρος ότι θέλετε να αφαιρέσετε το κανάλι;',
    removeDevice: 'Ειστε σίγουρος ότι θέλετε να αφαιρέσετε την συσκευή;',
    changesNotSaved: 'Οι αλλαγές δεν αποθηκεύτηκαν.',
    leaveConfirmation: 'Είστε σίγουροι ότι θέλετε να φύγετε;',
    enableLocation:
      'Η τοποθεσία σας είναι απαραίτητη για τον προσδιορισμό της θέσης της συσκευής. Ενεργοποιήστε την άδεια στις ρυθμίσεις του προγράμματος περιήγησής σας',
    typeChangeModal:
      'Είστε βέβαιοι ότι θα αλλάξετε τον τύπο της συσκευής; Όλο τα δεδομένα του καναλιού θα αφαιρεθουν τα δεδομένα θα αφαιρεθούν',
    changeSocketNumber:
      'Είστε βέβαιοι ότι θα αλλάξετε τον αριθμό της πρίζας;',
    confirmChannelEnable:
      'Είστε βέβαιοι ότι θα κλειδώσετε αυτό το κανάλι;',
    confirmChannelDisable:
      'Είστε βέβαιοι ότι θα ξεκλειδώσετε αυτό το κανάλι;',
    confirmPMTagsEnable: 'Are you sure to enable PM Tags?',
    confirmPMTagsDisable: 'Are you sure to disable PM Tags?',
    updating: 'Αυτή η διαδικασία μπορεί να διαρκέσει έως και 3 λεπτά',
    scanModBus: 'Θέλω να σαρώσω τη διεύθυνση MODBUS για αυτό το κανάλι',
    wrongFirmwareForTestMode:
      'Please update the devices firmware before enabling test mode.',
    skippedChannel:
      'Refresh data or check the physical connection of the socket in the pedestal.',
    startReplaceCounter:
      'Are you sure you want to start replacing the counter?',
  },
  placeholders: {
    email: 'email@example.com',
    password: '********',
    search: 'Σάρωση',
    pier: 'Προβλήτας',
    pedestal: 'Πίλαρ',
    chooseCamera: 'Επέλεξε κάμερα',
    oldPassword: 'Παλιός κωδικός πρόσβασης',
    newPassword: 'Νέος κωδικός πρόσβασης',
    confirmNewPassword: 'Επιβεβαίωσε νέο κωδικό πρόσβασης',
    enterComment: 'Enter a comment',
  },
  buttons: {
    login: 'Είσοδος',
    logout: 'Εξοδός',
    back: 'Πίσω',
    refresh: 'Ανανέωση',
    save: 'Αποθήκευση',
    remove: 'Αφαιρώ',
    addChannel: 'Προσθήκη Καναλιού',
    enable: 'Ενεργοποιώ',
    lock: 'Κλειδωμένο',
    electricity: 'Ρευμα',
    water: 'Νερό',
    add: 'Προσθήκη',
    replace_device: 'Αλλαγή ΜΜ-Ε',
    replace_counter: 'Αλλαγή Μετρητή',
    replace_pedestal_code: 'Αντικατέστησε το QR',
    savePosition: 'Αποθήκευση σε νεα θέση',
    cancel: 'Ακύρωση',
    hide: 'Κρυφό',
    confirm: 'Επιβεβαιώνω',
    getPosition: 'Λάβεται Θέση',
    resolve: 'Επιλύω',
    startScan: 'Έναρξη σάρωσης',
    stopScan: 'Περαίωση σάρωσης',
    scanImage: 'Σάρωση εικόνας',
    supportRequest: 'Απέστειλε αίτημα υποστήριξης,',
    profile: 'Προφίλ',
    marinas: 'Μαρίνες',
    start: 'Start',
    finish: 'Finish',
  },
  legend: {
    title: 'Θρύλος',
    neverSeen: 'Συσκεύή Δεν Φαίνεται',
    notSynced: 'Συσκεύή Δεν Φαίνεται Τα Τελευταία 20 Λεπτά',
    hasErrors: 'Η συσκευή έχει ανεπίλυτα σφάλματα (απαιτείται παρέμβαση)',
  },
  notifications: {
    addedDevice: 'Συσκευή Προστέθηκε 👍',
    updatedGeo: 'Η Θέση Ενημερώθηκε  👍',
    updatedEui: 'Η Συσκευή EUI Ενημερώθηκε 👍',
    updatedPedestalQr: 'Πίλαρ QR ενημερώθηκε 👍',
    replacedCounter: 'Ο Μετρητής Αλλαξε 👍',
    startedReplaceCounter: 'Started replacing counter 👍',
    replacedCounterNewValue:
      'New meter status: <strong>{{meter_status}} {{unit}}</strong>',
    addedChannel: 'Κανάλι Προστέθηκε👍',
    removedChannel: 'Κανάλι Αφαιρέθηκε 👍',
    changedChannelState: 'Η κατάστση του καναλιού άλλαξε 👍',
    removedDevice: 'Συσκευή Αφαιρέθηκε 👍',
    invalidValues: 'Μη εγκυρή τιμή ⚡',
    updatedOutputs: 'Η ισχύς εξόδου της συσκευής έχει ρυθμιστεί σωστά',
    error: 'Λάθος 🤥',
    success: 'Επιτυχής',
    errorMessage: 'Κάτι πήγε λάθος',
    tryAgain: 'Προσπαθήστε ξανά',
    notSeenOnSubmit:
      'Η συσκευή δεν είναι ορατή. Επιβεβαιώστε ότι οι πληροφορίες έχουν αποθηκευτεί σωστά.',
    invalidDeviceName:
      'Τα πεδία ονόματος συσκευής δεν είναι έγκυρα. Συμπληρώστε τα πεδία',
    invalidPedestalQrLength:
      'Το QR του πίλαρ πρέπει να έχει μήκος τουλάχιστον 6 χαρακτήρες',
    enableLocation: 'Πρέπει να ενεργοποιήσετε την τοποθεσία σας»',
    testModeEnabled: 'Η δοκιμαστική λειτουργία είναι πλέον ενεργοποιημένη',
    testModeDisabled:
      'Η δοκιμαστική λειτουργία είναι πλέον απενεργοποιημένη',
    pmTagsEnabled: 'Το ηλεκτρονικό κλειδί είναι ενεργοποιημένο',
    pmTagsDisabled: 'Το ηλεκτρονικό κλειδί είναι απενεργοποιημένο',
    deviceNotFound: 'Αυτή η συσκευή δεν βρέθηκε',
    typeNotSupported: 'Αυτός ο τύπος δεν υποστυρίζεται',
    preventTypeChange: `Δεν μπορείς να αλλάξεις τον τύπο της συσκευής`,
    socketNumbersUnique:
      'Οι τιμές του αριθμού πρίζας πρέπει να είναι μοναδικές',
    addDeviceNow: 'Μπορείτε να το προσθέσετε τώρα!',
    uniqueLinks:
      'Η σύνδεση μεταξύ των ηλεκτρικών αγωγών και του νερού πρέπει να είναι μοναδική.',
    scanRequested:
      'Έχει σταλεί αίτημα σάρωσης. Scanning may take up to 10 minutes.',
    errorResolved: 'Σφάλμα αποκαταστάθηκε 👍',
    qrNotFound: 'Δεν εντοπίστηκε κωδικός QR',
    supportRequest: 'Το αίτημάς σας έχει σταλθεί στην τεχνική υποστήριξη.',
    passwordChanged: 'Η αλλαγή κωδικού είναι επιτυχής',
  },
  errors: {
    devEui: 'Το EUI δεν πρέπει να είναι κενό',
    channels: 'Τα κανάλια πρεπεί να περιέχουν τουλάχιστον 1 στοιχείο',
    newDevEui: 'Οι καινούργιες συσκευές EUI δεν πρέπει να είναι κενές',
    pulse: 'Ο παλμός δεν πρέπει να είναι απροσδιόριστος',
    meterStatus:
      'Η κατάσταση του μετρητή δεν πρέπει να είναι απροσδιόριστη',
    userNotFound: 'Ο χρήστης δεν βρέθηκε',
    wrongPassword: 'Ο κωδικός έιναι μη έγκυρος',
    physicalName: 'Η τιμή του φυσικού ονόματος δεν είναι έγκυρη',
    pedestalQr: 'Η τιμή QR του πίλαρ δεν είναι έγκυρη',
    modBus: 'Η τιμή MODBUS δεν είναι έγκυρη',
  },
  validations: {
    invalid: 'Η τιμή είναι μη έγκυρη',
    required: 'Το πεδίο είναι υποχρεωτικό',
    email: 'Το πεδίο είναι υποχρεωτικό',
    wrongMarina: 'Ο σαρωμένος κωδικός QR ανήκει σε άλλη μαρίνα',
    wrongEui: 'Αυτό το EUI δεν είναι σωστό',
    wrongPedestalQr: 'Αυτός ο κωδικός δεν είναι σωστός',
    wrongPedestalName: 'Το όνομα του σαρωμένου πιλαρ δεν είναι σωστό',
    passwordLength:
      'Ο κωδικός πρόσβασης πρέπει να έχει τουλάχιστον 8 χαρακτήρς',
    strongPassword:
      'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον ένα ΚΕΦΑΛΑΙΟ γράμμα, μικρό γράμμα, νούμερο και σημείο σημείο στίξης.',
    samePasswords: 'Οι κωδικοί πρόσβασης πρέπει να είναι ίδιοι.',
  },
  notFound: {
    title: 'Βρήκες το μυστικό μέρος',
    description:
      'Δυστυχώς, αυτή είναι μόνο μια σελίδα 404. Μπορεί να έχετε πληκτρολογήσει λάθος τη διεύθυνση ή η σελίδα να έχει μετακινηθεί σε άλλη διεύθυνση URL',
    button: 'Γυρίστε με πίσω στην αρχική σελίδα',
  },
  deviceErrors: {
    1: {
      description: 'To σταλθέν λογισμικό δεν είναι έγκυρο',
      instruction: 'Αντικατάστασε την συσκευή',
    },
    2: {
      description: 'Το ληφθέν λογισμικό δεν είναι έγκυρο',
      instruction:
        'Διαμόρφωσε το FUOTA με την σωστή έκδοση υλικού για την συσκευή',
    },
    3: {
      description: 'MODBUS communication error for channel no. {{arg0}}',
      arg0: 'για το κανάλι Νο {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    4: {
      description: 'Απέτυχε η σάρωση της MOBDUS {{arg0}} {{arg1}}',
      arg0: 'για το κανάλι Νο {{arg}}',
      instruction:
        'Check the socket electronics, wiring harness and all physical connections.',
    },
    5: {
      description:
        'Η διαδικασία επανελειτοργίας πραγματοποιήθηκε από WATCHDOG {{arg0}}',
      arg0_0: '(IWDG watchdog)',
      arg0_1: '(WWDG watchdog)',
    },
    6: {
      description: 'User credits overwritten {{arg0}} {{arg1}}',
      arg0: '(number of credits: {{arg}}).',
      arg1: 'No. of the channel for which the credits have been overwritten: {{arg}}',
      instruction:
        'Verify if overwritten credits should be returned to the customer. Report the situation to the office for verification.',
    },
    7: {
      description: 'The device has lost communication',
    },
    8: {
      description: 'Replace or update the Plus {{arg0}}}',
      arg0: 'socket no. {{arg}}',
      instruction:
        'Replace the socket with one with firmware 1.29 or update the socket firmware to 1.29.',
    },
    defaultSolution:
      'Αν έχετε πρόβλημα κατά την επίλυση του σφάλματος, επικοινωνήστε με την τεχνική υποστήριξη.',
  },
};
