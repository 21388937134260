import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { changeChannelState } from '../../../../services/api/devices';
import { ActionModal } from '../../../../components/action-modal';

interface IChangeChannelStateModalProps {
  isActive: boolean;
  isSwitchClicked: boolean;
  channelName: string;
  handleChangeSwitchState: (changeSwitchState?: boolean) => void;
}

export const ChangeChannelStateModal: React.FC<
  IChangeChannelStateModalProps
> = ({
  isActive,
  isSwitchClicked,
  channelName,
  handleChangeSwitchState,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const devEui = searchParams.get('devEui');
  const marinCode = searchParams.get('marinCode');

  const onCancel = useCallback(() => {
    handleChangeSwitchState();
  }, [handleChangeSwitchState]);

  const mutation = useMutation(
    (item: object) => {
      return changeChannelState(devEui, channelName, item);
    },
    {
      onSuccess: () => {
        showNotification({
          title: t('notifications.changedChannelState'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
        handleChangeSwitchState(false);
      },
    },
  );

  const handleChannelStateChange = useCallback(() => {
    const item = {
      marinCode,
      status: isActive,
    };
    mutation.mutate(item);
  }, [isActive, mutation, marinCode]);

  return (
    <ActionModal
      opened={isSwitchClicked}
      onClose={handleChangeSwitchState}
      title={t('labels.attention')}
      label={
        isActive
          ? t('descriptions.confirmChannelEnable')
          : t('descriptions.confirmChannelDisable')
      }
      leftButton={{
        variant: 'light',
        color: 'accentBlue',
        label: t('buttons.cancel'),
        action: onCancel,
      }}
      rightButton={{
        variant: 'light',
        color: 'accentCabaret',
        label: t('buttons.confirm'),
        action: handleChannelStateChange,
      }}
    />
  );
};
