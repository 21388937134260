import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconAlertCircle } from '@tabler/icons';
import { useSearchParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { createStyles, Button, Group, Text } from '@mantine/core';
import EventEmitter from '../../../utils/EventEmitter';
import { updateGeo } from '../../../services/api/devices';
import { useDeviceRefetch } from '../../../hooks/useDeviceRefetch';

const useStyles = createStyles(() => ({
  height: {
    height: 200,
    width: '100%',
  },

  mapContainer: {
    zIndex: 5,
  },

  label: {
    whiteSpace: 'pre-line',
  },

  button: {
    height: 'auto',
    minHeight: '32px',
  },
}));

function DraggableMarker({
  position,
  handlePositionChange,
  isTestModeActive,
}) {
  useEffect(() => {
    EventEmitter.emit('NewPosition', position);
  }, [position]);

  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          handlePositionChange(marker.getLatLng());
        }
      },
    }),
    [handlePositionChange],
  );

  return (
    <Marker
      draggable={!isTestModeActive}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    />
  );
}

interface ICoords {
  latitude: number;
  longitude: number;
  accuracy?: number;
  altitude?: number;
  altitudeAccuracy?: number;
  heading?: number;
  speed?: number;
}
interface IMapSectionProps {
  coords: ICoords;
  isEditModeActive?: boolean;
  handleMarkerMove?: (latitude: number, longitude: number) => void;
  zoom?: number;
  isTestModeActive?: boolean;
}

export const MapSection: React.FC<IMapSectionProps> = ({
  coords,
  isEditModeActive,
  handleMarkerMove,
  zoom = 16,
  isTestModeActive,
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const initPos = {
    lat: coords.latitude,
    lng: coords.longitude,
  };
  const [position, setPosition] = useState(initPos);
  const [showButtons, setShowButtons] = useState(false);
  const devEui = searchParams.get('devEui');
  const marinCode = searchParams.get('marinCode');

  const deviceRefetch = useDeviceRefetch();

  const handlePositionChange = useCallback(pos => {
    setPosition(pos);
    setShowButtons(true);
  }, []);

  const handleCancelChange = () => {
    setPosition(initPos);
    setShowButtons(false);
  };

  const handleUpdateGeo = useMutation(
    (item: object) => {
      return updateGeo(devEui, item);
    },
    {
      onSuccess: () => {
        deviceRefetch();
        showNotification({
          title: t('notifications.updatedGeo'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconCheck size={18} />,
        });
      },
      onError: () => {
        showNotification({
          title: t('notifications.error'),
          message: t('notifications.errorMessage'),
          color: 'accentRed',
          icon: <IconAlertCircle size={18} />,
        });
      },
    },
  );

  const handleSavePosition = () => {
    const item = {
      marinCode,
      lat: position.lat,
      lng: position.lng,
    };
    handleUpdateGeo.mutate(item);
    setShowButtons(false);
  };

  useEffect(() => {
    if (!isEditModeActive) handleMarkerMove(position.lat, position.lng);
  }, [position.lat, position.lng, isEditModeActive, handleMarkerMove]);

  return (
    <>
      <MapContainer
        center={initPos}
        zoom={zoom}
        scrollWheelZoom={false}
        className={cx(classes.height, classes.mapContainer)}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker
          position={position}
          handlePositionChange={handlePositionChange}
          isTestModeActive={isTestModeActive}
        />
      </MapContainer>
      {isEditModeActive && showButtons && (
        <Group position="apart" my="md" noWrap align="stretch">
          <Button
            variant="filled"
            size="xs"
            radius="xl"
            uppercase
            color="accentBlue"
            onClick={handleSavePosition}
            classNames={{ label: classes.label, root: classes.button }}>
            <Text color="white">{t('buttons.savePosition')}</Text>
          </Button>
          <Button
            variant="light"
            size="xs"
            radius="xl"
            uppercase
            color="accentBlue"
            onClick={handleCancelChange}
            classNames={{ label: classes.label, root: classes.button }}>
            <Text color="white">{t('buttons.cancel')}</Text>
          </Button>
        </Group>
      )}
    </>
  );
};
