export const MAXIMUM_CURRENT_VALUES = Object.freeze([
  { value: 16, label: '16' },
  { value: 32, label: '32' },
  { value: 63, label: '63' },
  { value: 125, label: '125' },
  { value: 250, label: '250' },
  { value: 400, label: '400' },
  { value: 500, label: '500' },
  { value: 600, label: '600' },
]);

export const VOLTAGE_VALUES = Object.freeze([
  { value: 230, label: '230' },
  { value: 400, label: '400' },
]);

export const CURRENT_VOLTAGE_THRESHOLD = 125;

export const CHANNEL_TYPE = Object.freeze({
  ELECTRICITY: 'current',
  WATER: 'water',
});

// MM-E maximum channels length is 6 for any channel types
// MM-E-8 maximum channels length is 8 (4 for each channel type)
export const CHANNELS_MAX_LENGTH = Object.freeze({
  MM_E: 6,
  MM_E_8: 4,
});
