import { useCallback, useState } from 'react';
import { useTimeout } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { getDevice, IDevice } from '../services/api/devices';
import {
  SUBMITTING_TIMEOUT,
  SUBMITTING_INTERVAL,
  TESTMODE_INTERVAL,
} from '../constants/devices-data.constants';

type TDeviceQueryState = 'submitting' | 'testMode' | 'pending';

export const useDeviceQuery = (onSuccess: (res: IDevice) => void) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const [deviceQueryState, setDeviceQueryState] =
    useState<TDeviceQueryState>('pending');
  const { start, clear } = useTimeout(() => {
    setDeviceQueryState('pending');
    showNotification({
      title: t('notifications.error'),
      message: `${t('notifications.errorMessage')}. ${t(
        'notifications.tryAgain',
      )}`,
      color: 'accentRed',
      icon: <IconAlertCircle size={18} />,
    });
  }, SUBMITTING_TIMEOUT);

  const startSubmitting = useCallback(() => {
    start();
    setDeviceQueryState('submitting');
  }, [start]);

  const clearSubmitting = useCallback(() => {
    clear();
    setDeviceQueryState('pending');
  }, [clear]);

  const handleQueryState = useCallback((state: TDeviceQueryState) => {
    setDeviceQueryState(state);
  }, []);

  const fetchDevice = useCallback(async () => {
    const res = await getDevice(`?marinCode=${marinCode}`);
    const singleDevice = res?.data?.devices?.find(
      d => d.devEui === devEui,
    );
    return singleDevice;
  }, [devEui, marinCode]);

  const setRefetchInterval = useCallback(() => {
    switch (deviceQueryState) {
      case 'submitting':
        return SUBMITTING_INTERVAL;
      case 'testMode':
        return TESTMODE_INTERVAL;
      case 'pending':
        return false;
      default: {
        const n: never = deviceQueryState;
        return n;
      }
    }
  }, [deviceQueryState]);

  return {
    query: useQuery(
      ['getDevice', marinCode, devEui],
      () => fetchDevice(),
      {
        refetchOnWindowFocus: false,
        refetchInterval: setRefetchInterval,
        onSuccess: res => onSuccess(res),
      },
    ),
    deviceQueryState,
    startSubmitting,
    clearSubmitting,
    handleQueryState,
  };
};
