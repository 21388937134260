import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  useMantineTheme,
  Container,
  Text,
  Code,
  Card,
  Group,
  Title,
  ActionIcon,
  Menu,
} from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  useNavigate,
  useSearchParams,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { compare } from 'compare-versions';
import dayjs from 'dayjs';
import {
  IconTrash,
  IconCheck,
  IconTrashX,
  IconDotsVertical,
} from '@tabler/icons';
import { showNotification } from '@mantine/notifications';
import { OverlayLoader } from '../../components/atoms/overlay-loader';
import {
  removeDevice,
  switchTestMode,
  switchPMTags,
  getRegistry,
} from '../../services/api/devices';
import { UserHeader } from '../../components/user-header';
import { Channels } from './components/Channels/channels';
import { MapSection } from './components/map-section';
import {
  ScanEui,
  PedestalNameInputs,
  ScanPedestalQr,
} from './components/DeviceInputs';
import { DeviceSwitch } from './components/device-switch';
import { DATE_FORMAT_WITH_HOUR } from '../../constants/date-format.constants';
import { ActionModal } from '../../components/action-modal';
import { BottomNavigation } from '../../components/bottom-navigation';
import { BottomNavIcon } from '../../components/atoms/bottom-nav-icon';
import { DeviceTypes } from '../../types/device';
import { ScanModbusModal } from './components/scan-modbus-modal';
import { ErrorsList } from './components/DeviceErrors/errors-list';
import { checkDeviceSync } from '../../utils/checkDeviceSync';
import { UpdateModal } from '../../components/update-modal';
import { useDeviceQuery } from '../../hooks/useDeviceQuery';
import { useMarinaName } from '../../hooks/useMarinaName';

const useStyles = createStyles(() => ({
  card: {
    minHeight: 200,
  },

  code: {
    background: 'transparent',
  },
}));

export const EditDevice: React.FC = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const marinCode = searchParams.get('marinCode');
  const devEui = searchParams.get('devEui');
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [scanModalOpened, setScanModalOpened] = useState(false);
  const [pmTagsModalOpened, setPmTagsModalOpened] = useState(false);

  const {
    query: { refetch, data, isLoading },
    startSubmitting,
    clearSubmitting,
    deviceQueryState,
    handleQueryState,
  } = useDeviceQuery(res => {
    if (
      deviceQueryState === 'submitting' &&
      res.lastSyncAt !== data.lastSyncAt &&
      res.pmTags !== data.pmTags
    ) {
      clearSubmitting();
      showNotification({
        title: res.pmTags
          ? t('notifications.pmTagsEnabled')
          : t('notifications.pmTagsDisabled'),
        message: t('notifications.success'),
        color: 'teal.8',
        icon: <IconCheck size={18} />,
      });
    }
    if (!res.testMode && deviceQueryState === 'testMode') {
      showNotification({
        title: t('notifications.testModeDisabled'),
        message: t('notifications.success'),
        color: 'teal.8',
        icon: <IconCheck size={18} />,
      });
      handleQueryState('pending');
    }
  });
  const { data: marinaName } = useMarinaName(marinCode);
  const { data: deviceFw } = useQuery(
    ['getFw', data?.devEui],
    () => (data?.devEui ? getRegistry(data?.devEui) : null),
    {
      refetchOnWindowFocus: false,
      select: res => res.data.deviceRegistry.fw,
    },
  );

  const loading = isLoading || deviceQueryState === 'submitting';
  const isDeviceSynced =
    data?.lastSyncAt && checkDeviceSync(data?.lastSyncAt);
  const isPMTagsEnabled =
    data?.type === DeviceTypes.MM_E_PM && data?.pmTags;
  const firmwareVersion = data?.fw ?? deviceFw;
  const isValidFirmwareForTestMode =
    firmwareVersion && !compare(firmwareVersion, '3.7', '<=');

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleRemoveDevice = useMutation(
    () => {
      return removeDevice(marinCode, devEui);
    },
    {
      onSuccess: () => {
        refetch();
        showNotification({
          title: t('notifications.removedDevice'),
          message: t('notifications.success'),
          color: 'teal.8',
          icon: <IconTrash size={18} />,
        });
        navigate(`/devices/?marinCode=${marinCode}`);
      },
    },
  );

  const handleSwitchTestMode = useMutation(
    () => {
      return switchTestMode(devEui, {
        marinCode,
        status: !data.testMode,
      });
    },
    {
      onSuccess: () => {
        refetch();
        if (!data.testMode) {
          showNotification({
            title: t('notifications.testModeEnabled'),
            message: t('notifications.success'),
            color: 'teal.8',
            icon: <IconCheck size={18} />,
          });
          handleQueryState('testMode');
        }
      },
    },
  );

  const handleSwitchPMTags = useMutation(
    () => {
      return switchPMTags(devEui, {
        marinCode,
        status: !data.pmTags,
      });
    },
    {
      onSuccess: () => {
        startSubmitting();
      },
    },
  );

  const handleSetRemoveModalOpened = useCallback(() => {
    setRemoveModalOpened(prev => !prev);
  }, []);

  const handleRemoveMutation = useCallback(() => {
    handleRemoveDevice.mutate();
  }, [handleRemoveDevice]);

  const handleTestMode = useCallback(() => {
    handleSwitchTestMode.mutate();
  }, [handleSwitchTestMode]);

  const handlePMTagsModal = useCallback(() => {
    setPmTagsModalOpened(prev => !prev);
  }, []);

  const handlePMTags = useCallback(() => {
    handlePMTagsModal();
    handleSwitchPMTags.mutate();
  }, [handleSwitchPMTags, handlePMTagsModal]);

  const handleScanModalOpened = useCallback(() => {
    setScanModalOpened(prev => !prev);
  }, []);

  if (!data) {
    if (location.state !== 'newDevice' && !isLoading)
      return <Navigate to="/not-found" />;
    return <OverlayLoader />;
  }

  return (
    <>
      <Container>
        <UserHeader
          backTarget={`/devices/?marinCode=${marinCode}`}
          isLoading={loading}
          title={marinaName}
        />
        <Card m="xs">
          <Group position="apart" pb="lg" noWrap>
            <Title order={4}>{t('labels.editDevice')}</Title>
            <ErrorsList hasErrors={data.hasErrors} />
            <Menu shadow="md" zIndex={999}>
              <Menu.Target>
                <ActionIcon>
                  <IconDotsVertical color={theme.colors.primary[0]} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  color={theme.colors.accentRed[0]}
                  icon={<IconTrashX />}
                  onClick={handleSetRemoveModalOpened}>
                  <Text> {t('labels.remove')}</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
          <ActionModal
            opened={removeModalOpened}
            onClose={handleSetRemoveModalOpened}
            title={`${t('labels.removeDevice')} ${data.name}`}
            label={t('descriptions.removeDevice')}
            withCloseButton
            leftButton={{
              variant: 'filled',
              color: 'accentRed',
              label: t('buttons.remove'),
              action: handleRemoveMutation,
            }}
            rightButton={{
              variant: 'light',
              color: 'accentBlue',
              label: t('buttons.back'),
              action: handleSetRemoveModalOpened,
            }}
          />
          <Card.Section className={classes.card}>
            <MapSection
              coords={{
                latitude: data.lat,
                longitude: data.lng,
              }}
              isEditModeActive
              isTestModeActive={data.testMode}
            />
          </Card.Section>
          <PedestalNameInputs
            marinCode={marinCode}
            value={data.name}
            isEditModeActive
          />
          <ScanPedestalQr
            pedestalQr={data.pedestalQr ?? ''}
            isEditModeActive
            isTestModeActive={data.testMode}
          />
          <ScanEui
            devEui={data.devEui}
            isEditModeActive
            isTestModeActive={data.testMode}
            currentDeviceType={data.type}
          />
          <Group position="apart" mt="md" spacing={0}>
            <Text size="xs">{t('labels.lastSeen')}</Text>
            <Code className={classes.code}>
              {data.lastSyncAt
                ? dayjs(data.lastSyncAt).format(DATE_FORMAT_WITH_HOUR)
                : t('labels.never')}
            </Code>
          </Group>
        </Card>
        <Group spacing={4}>
          {data.type !== DeviceTypes.MM_E_PM && (
            <DeviceSwitch
              handleSwitch={handleTestMode}
              isActive={!!data.testMode}
              label={t('labels.testMode')}
              disabled={
                (!isDeviceSynced && !data.testMode) ||
                !isValidFirmwareForTestMode
              }
              errorMessage={
                !isValidFirmwareForTestMode &&
                t('descriptions.wrongFirmwareForTestMode')
              }
            />
          )}
          {data.type === DeviceTypes.MM_E_PM && (
            <DeviceSwitch
              handleSwitch={handlePMTagsModal}
              isActive={!!data.pmTags}
              label={t('labels.pmTags')}
              disabled={!isDeviceSynced || data.testMode}
            />
          )}
        </Group>
        {data?.channels && (
          <Channels
            value={data?.channels}
            isEditModeActive
            isTestModeActive={data.testMode}
            deviceType={{
              euiType: data.type,
              fw: firmwareVersion,
            }}
            isDeviceSynced={isDeviceSynced}
            isPMTagsEnabled={isPMTagsEnabled}
            allowBigSocketControl={data.allowBigSocketControl}
          />
        )}
      </Container>
      <ScanModbusModal
        opened={scanModalOpened}
        onClose={handleScanModalOpened}
        channels={data?.channels}
      />
      <BottomNavigation>
        <BottomNavIcon
          onClick={handleRefetch}
          iconName="IconRefresh"
          label={t('buttons.refresh')}
        />
        {data.type === DeviceTypes.MM_E_PM && (
          <BottomNavIcon
            onClick={handleScanModalOpened}
            iconName="IconScanEye"
            label="Scan"
          />
        )}
      </BottomNavigation>
      <UpdateModal
        opened={deviceQueryState === 'submitting'}
        reset={clearSubmitting}
        title={t('labels.updatingPMTags')}
      />
      <ActionModal
        opened={pmTagsModalOpened}
        onClose={handlePMTagsModal}
        title={t('labels.attention')}
        label={
          data.pmTags
            ? t('descriptions.confirmPMTagsDisable')
            : t('descriptions.confirmPMTagsEnable')
        }
        leftButton={{
          variant: 'light',
          color: 'accentBlue',
          label: t('buttons.cancel'),
          action: handlePMTagsModal,
        }}
        rightButton={{
          variant: 'light',
          color: 'accentCabaret',
          label: t('buttons.confirm'),
          action: handlePMTags,
        }}
      />
    </>
  );
};
