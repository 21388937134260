import React, { useCallback, useState } from 'react';
import {
  createStyles,
  ActionIcon,
  Transition,
  Text,
  Group,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconArrowBackUp } from '@tabler/icons';
import { OverlayLoader } from './atoms/overlay-loader';
import { ActionModal } from './action-modal';

const useStyles = createStyles(() => ({
  header: {
    padding: 8,
  },

  title: {
    textTransform: 'capitalize',
    flexGrow: 1,
  },
}));

interface IUserHeaderProps {
  isLoading: boolean;
  title: string;
  children?: React.ReactNode;
  backTarget?: string;
  backModal?: boolean;
}

export const UserHeader: React.FC<IUserHeaderProps> = ({
  backTarget,
  isLoading,
  title,
  backModal,
  children,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);

  const handleBackModal = useCallback(() => {
    setOpened(prev => !prev);
  }, []);

  const handleBack = useCallback(
    () =>
      backTarget === 'prevLocation' ? navigate(-1) : navigate(backTarget),
    [navigate, backTarget],
  );

  return (
    <>
      <header className={classes.header}>
        <Group spacing={0}>
          {backTarget && (
            <ActionIcon
              onClick={backModal ? handleBackModal : handleBack}
              variant="transparent"
              color="primary">
              <IconArrowBackUp />
            </ActionIcon>
          )}
          <Transition
            mounted={isLoading}
            transition="fade"
            duration={400}
            timingFunction="ease">
            {() => <OverlayLoader />}
          </Transition>
          <Text
            className={classes.title}
            size={18}
            color="primary"
            weight={500}
            align="center">
            {title}
          </Text>
        </Group>
        {children}
      </header>
      <ActionModal
        opened={opened}
        onClose={handleBackModal}
        title={t('labels.attention')}
        label={`${t('descriptions.changesNotSaved')}\n${t(
          'descriptions.leaveConfirmation',
        )}`}
        centered
        leftButton={{
          variant: 'light',
          color: 'accentBlue',
          label: t('buttons.cancel'),
          action: handleBackModal,
        }}
        rightButton={{
          variant: 'light',
          color: 'accentCabaret',
          label: t('buttons.confirm'),
          action: handleBack,
        }}
      />
    </>
  );
};
