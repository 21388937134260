import React, { useState, useCallback } from 'react';
import {
  createStyles,
  useMantineTheme,
  ActionIcon,
  Stack,
} from '@mantine/core';
import { IconMenu2 } from '@tabler/icons';
import { SidebarMenu } from './sidebar-menu';

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'sticky',
    bottom: 0,
    left: 0,
    width: 'calc(100% - 64px)',
    maxWidth: '960px',
    minHeight: '60px',
    padding: '4px 32px 6px',
    margin: '0 auto',
    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#fff',
    zIndex: 2,
    gap: '16px',
  },

  iconsStack: {
    flex: 1,
    flexDirection: 'row',
  },
}));

interface IBottomNavigationProps {
  children?: React.ReactNode;
}

export const BottomNavigation: React.FC<IBottomNavigationProps> = ({
  children,
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  const handleOpenMenu = useCallback(() => {
    setOpened(prev => !prev);
  }, []);

  return (
    <nav className={classes.container}>
      <SidebarMenu opened={opened} onClose={handleOpenMenu} />
      <ActionIcon onClick={handleOpenMenu}>
        <IconMenu2 color={theme.colors.primary[0]} />
      </ActionIcon>
      <Stack
        align="center"
        justify="space-around"
        className={classes.iconsStack}>
        {children}
      </Stack>
    </nav>
  );
};
