import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconUrgent,
  IconEyeOff,
  IconTooltip,
  IconAlertTriangle,
} from '@tabler/icons';
import {
  createStyles,
  useMantineTheme,
  ActionIcon,
  Popover,
  Text,
  Group,
} from '@mantine/core';

const useStyles = createStyles(() => ({
  icon: {
    color: '#ff3333',
    flexShrink: 0,
  },

  legend: {
    // Needed to use '!important' to prevent Mantine component recalculate position on resize
    left: '0 !important',
    minWidth: 'calc(100% - 32px)',
    padding: '12px 16px',
    marginTop: '2px',
    border: 'none',
    borderRadius: '20px 20px 0px 0px',
    boxShadow: 'inset 0 0 5px 0 #d3d3d3',
    background: 'white',
  },

  legendWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },

  legendItem: {
    display: 'flex',
    gap: '4px',
  },

  iconLabel: {
    whiteSpace: 'nowrap',
  },
}));

export const DevicesLegend: React.FC = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Popover position="top">
      <Popover.Target>
        <ActionIcon size="xl" className="bottom-icon">
          <IconTooltip size={24} color={theme.colors.primary[0]} />
          <Text
            size={10}
            weight={500}
            color={theme.colors.primary[0]}
            className={classes.iconLabel}>
            {t('legend.title')}
          </Text>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown className={classes.legend}>
        <Group position="apart" m="xs" className={classes.legendWrapper}>
          <Text
            size={16}
            weight={400}
            color="black"
            className={classes.legendItem}>
            <IconEyeOff size={24} className={classes.icon} />
            <span> - {t('legend.neverSeen')}</span>
          </Text>
          <Text
            size={16}
            weight={400}
            color="black"
            className={classes.legendItem}>
            <IconAlertTriangle size={24} className={classes.icon} />
            <span> - {t('legend.notSynced')}</span>
          </Text>
          <Text
            size={16}
            weight={400}
            color="black"
            className={classes.legendItem}>
            <IconUrgent size={24} className={classes.icon} />
            <span> - {t('legend.hasErrors')}</span>
          </Text>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};
