import React from 'react';
import { useMantineTheme, ActionIcon, Text } from '@mantine/core';
import * as icons from '@tabler/icons';

interface IBottomNavIconProps {
  onClick: () => void;
  iconName: string;
  label: string;
}

export const BottomNavIcon: React.FC<IBottomNavIconProps> = ({
  onClick,
  iconName,
  label,
}) => {
  const theme = useMantineTheme();
  const Icon = icons[iconName];

  return (
    <ActionIcon size="xl" className="bottom-icon" onClick={onClick}>
      <Icon size={24} color={theme.colors.primary[0]} />
      <Text size={10} weight={500} color={theme.colors.primary[0]}>
        {label}
      </Text>
    </ActionIcon>
  );
};
