import { Text, SegmentedControl, Center, Box } from '@mantine/core';
import { IconDroplet, IconBolt } from '@tabler/icons';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CHANNEL_TYPE } from '../../../../constants/channels-data.constants';

interface IChannelsListSwitchProps {
  isTestModeActive: boolean;
  channelsType: string;
  setChannelsType: Dispatch<SetStateAction<string>>;
}

export const ChannelsListSwitch: React.FC<IChannelsListSwitchProps> = ({
  isTestModeActive,
  channelsType,
  setChannelsType,
}) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return [
      {
        label: (
          <Center>
            <IconBolt size={14} />
            <Box ml={10}>
              <Text size={14} weight={500}>
                {t('buttons.electricity')}
              </Text>
            </Box>
          </Center>
        ),
        value: CHANNEL_TYPE.ELECTRICITY,
      },
      {
        label: (
          <Center>
            <IconDroplet size={14} />
            <Box ml={10}>
              <Text size={14} weight={500}>
                {t('buttons.water')}
              </Text>
            </Box>
          </Center>
        ),
        value: CHANNEL_TYPE.WATER,
      },
    ];
  }, [t]);

  return (
    <SegmentedControl
      disabled={isTestModeActive}
      value={channelsType}
      onChange={setChannelsType}
      color={
        channelsType === CHANNEL_TYPE.WATER ? 'accentBlue' : 'accentYellow'
      }
      mb={10}
      mx="sm"
      fullWidth
      radius="lg"
      size="sm"
      data={data}
    />
  );
};
