import React, { useCallback, useState, useEffect } from 'react';
import {
  createStyles,
  Text,
  TextInput,
  Group,
  ActionIcon,
  Button,
} from '@mantine/core';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import isNumeric from 'validator/lib/isNumeric';
import { IconScan } from '@tabler/icons';

const useStyles = createStyles(theme => ({
  textInput: {
    flexGrow: 1,
  },

  error: {
    borderColor: theme.colors.accentRed[0],
    color: theme.colors.accentRed[0],
  },
}));

type ScanInputType = 'numeric' | 'alphanumeric';

interface IScanInputProp {
  onClick: () => void;
  value: string;
  label: string;
  replaceButtonLabel: string;
  maxLength: number;
  type: ScanInputType;
  error?: boolean;
  onChange?: (code: string) => void;
  isEditModeActive?: boolean;
  isTestModeActive?: boolean;
}

export const ScanInput: React.FC<IScanInputProp> = ({
  onClick,
  value,
  label,
  error,
  replaceButtonLabel,
  maxLength,
  type,
  onChange,
  isEditModeActive,
  isTestModeActive,
}) => {
  const [code, setCode] = useState<string>(value);
  const { classes } = useStyles();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const validateInput = (inputValue: string) => {
        switch (type) {
          case 'alphanumeric':
            return isAlphanumeric(inputValue);
          case 'numeric':
            return isNumeric(inputValue);
          default: {
            const n: never = type;
            return n;
          }
        }
      };

      if (
        !!event.target.value.length &&
        (event.target.value.length > event.target.maxLength ||
          !validateInput(event.target.value))
      ) {
        return;
      }
      setCode(event.target.value);
    },
    [type],
  );

  const handleCodeChange = useCallback(() => {
    onChange(code);
  }, [onChange, code]);

  useEffect(() => {
    setCode(value);
  }, [value]);

  return (
    <>
      <Group position="apart" mt="md" noWrap>
        <Text size={14}>{label}</Text>
        {isEditModeActive ? (
          <Button
            disabled={isTestModeActive}
            variant="outline"
            size="xs"
            uppercase
            onClick={onClick}
            color="accentCabaret">
            {replaceButtonLabel}
          </Button>
        ) : (
          <ActionIcon
            onClick={onClick}
            variant="filled"
            color="accentBlue">
            <IconScan />
          </ActionIcon>
        )}
      </Group>
      <Group position="apart" mt="xs">
        <TextInput
          className={classes.textInput}
          classNames={{ input: error && classes.error }}
          value={code ?? ''}
          onChange={handleInputChange}
          onKeyUp={handleCodeChange}
          readOnly={isEditModeActive}
          maxLength={maxLength}
        />
      </Group>
    </>
  );
};
